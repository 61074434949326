import React from 'react';
import styled from 'styled-components/macro';
import Table from './Table';
import DateText from './DateText';
import FormBox from './FormBox';

export interface OverShortByEmployeeEntry {
  id: number;
  storeId: number;
  shiftDate: Date;
  shiftNumber: number;
  employeeNumber: number;
  firstName: string;
  lastName: string;
  overShort: number;
  comments: string;
}

export interface OverShortByEmployeeFormProps {
  employees: OverShortByEmployeeEntry[];
}

const OverShortByEmployeeForm: React.FC<
  OverShortByEmployeeFormProps
> = props => {
  const { employees, ...rest } = props;

  return (
    <FormBox {...rest} title="Over_ShortByEmployee : Select Query">
      <EmployeeTable
        headers={[
          '',
          'Store id',
          'shift date',
          'shift',
          'Employee',
          'FirstName',
          'LastName',
          'Over_Short',
          'Othercomments',
        ]}
      >
        {employees.map((employee, index) => (
          <tr key={employee.id}>
            <td />
            <td>{employee.storeId}</td>
            <td>
              <DateText date={employee.shiftDate} />
            </td>
            <td>{employee.shiftNumber}</td>
            <td>{employee.employeeNumber}</td>
            <td>{employee.firstName}</td>
            <td>{employee.lastName}</td>
            <td>{employee.overShort}</td>
            <td>{employee.comments}</td>
          </tr>
        ))}
        <EmptyRow>
          <td>*</td>
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
          <td />
        </EmptyRow>
      </EmployeeTable>
    </FormBox>
  );
};

export default styled(OverShortByEmployeeForm)`
  width: 1000px;
  margin: auto;
  padding: 30px 0;
  background: #aaa;
`;

const EmployeeTable = styled(Table)`
  padding: 0;
  margin: 0;
  width: 850px;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    margin: 0;
    overflow: hidden;
    font-weight: normal;
  }

  td {
    border: 1px solid #aaa;
    background-color: white;
    text-align: right;
    padding: 2px;
    min-width: 100px;

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: left;
    }
  }

  td:first-child,
  th {
    background: #ddd;
    min-width: 10px;
    border: 1px solid black;
  }
`;

const EmptyRow = styled('tr')`
  line-height: 0.9;
  font-size: 18px;
  font-weight: bold;
  td:first-child {
    padding: 5px 5px 0;
  }
`;
