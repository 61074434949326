import React from 'react';
import styled from 'styled-components/macro';
import Input from './Input';
import Omit from '../utils/Omit.d';

/**
 * Input props plus `onValueChange` for handling new dates directly.
 * Also allows `value` to be passed as a `Date` or `string`.
 */
export interface DateInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value?: React.InputHTMLAttributes<HTMLInputElement>['value'] | Date;
  onValueChange?: (value: Date) => any;
}

const DateInput: React.FC<DateInputProps> = props => {
  const { onValueChange, onChange, value, ...rest } = props;
  const inputValue =
    value instanceof Date ? value.toISOString().slice(0, 10) : value;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(new Date(event.currentTarget.value + 'T00:00:00.000Z'));
    }
  }

  return (
    <Input {...rest} type="date" onChange={handleChange} value={inputValue} />
  );
};

// The psuedo element styles below help keep the browser-provided datepicker controls out of the way so the user has a comparable experience in FF and Chrome.
// Safari support will probably require a custom DateInput implementation, none of these fixes seemed to improve or affect Safari.
export default styled(DateInput)`
  position: relative;
  padding-left: 5px;

  ::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator,
  ::-webkit-outer-spin-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  ::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    right: 2px;
  }

  ::-webkit-calendar-picker-indicator {
    right: 10px;
  }
`;
