import React, { FC, FormEvent, FormEventHandler } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';

interface OverlayProps {
  onClick?: FormEventHandler<HTMLDivElement> | null;
  noScroll?: boolean;
}

const Overlay: FC<OverlayProps> = props => {
  const { onClick, ...rest } = props;

  // Only listen to onClick events from the overlay not its children
  function handleClick(event: FormEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;
    if (onClick && target.dataset.overlay) {
      onClick(event);
    }
  }

  return <Column {...rest} onClick={handleClick} data-overlay />;
};

export default styled(Overlay)<OverlayProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  overflow-y: ${props => (props.noScroll ? 'hidden' : 'auto')};
  opacity: 0.9;
  background: linear-gradient(225deg, #0b1c4a 0%, #0e4a93 100%);
  z-index: 2;
  justify-content: center;
  align-items: center;
`;
