import { useEffect } from 'react';

const defaultMs = 1000 * 60 * 30; // 30 minutes

/**
 * A react hook for running a function if the user doesn't interact with the app for `ms` milliseconds.
 * Defaults to 30 minutes.
 */
export default function useIdleTimeout(
  handleTimeout: () => any,
  ms = defaultMs,
): void {
  useEffect(() => {
    let timeout: number = setTimeout(handleTimeout, ms);

    function resetTimer() {
      clearTimeout(timeout);
      timeout = setTimeout(handleTimeout, ms);
    }

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
    // eslint-disable-next-line
  }, [ms]);
}
