import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import OverShortForm from '../components/OverShortForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import useAsyncEffect from '../utils/useAsyncEffect';
import isValidDate from '../utils/isValidDate';
import ShiftMaster from '../models/ShiftMaster';
import PaginationRow from '../components/PaginationRow';
import { navigate } from '@reach/router';
import SpinnerOverlay from '../components/SpinnerOverlay';

const OverShortLog: PageComponent = props => {
  const [shifts, setShifts] = useState<ShiftMaster[]>([]);
  const [shiftIndex, setShiftIndex] = useState(0);
  const startDateString =
    useMemo(() => prompt('enter beginning date:'), []) || '';
  const endDateString = useMemo(() => prompt('enter ending date:'), []) || '';
  const shift = shifts[shiftIndex];

  useAsyncEffect(async () => {
    const startDate = isValidDate(startDateString)
      ? new Date(startDateString)
      : null;
    const endDate = isValidDate(endDateString) ? new Date(endDateString) : null;

    if (!startDate || !endDate) {
      window.alert('Invalid date format.');
      navigate('/manager');
      return;
    }

    const shiftsToAudit =
      startDate && endDate
        ? await Api.getOverShortShiftsBetween(
            startDate.toISOString(),
            endDate.toISOString(),
          )
        : [];

    if (shiftsToAudit.length === 0) {
      window.alert('There are no over/short shifts between those dates.');
      navigate('/manager');
      return;
    }

    setShifts(shiftsToAudit);
  }, [startDateString, endDateString]);

  async function handleShiftChange(newShift: ShiftMaster) {
    const newShifts = [...shifts];
    newShifts[shiftIndex] = newShift;
    setShifts(newShifts);

    await Api.addOrUpdateShiftMaster(newShift);
  }

  if (!shift) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <OverShortForm shift={shift} onChange={handleShiftChange}>
          <PaginationRow
            page={shiftIndex}
            pageCount={shifts.length}
            onChange={setShiftIndex}
          />
        </OverShortForm>
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(OverShortLog))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)``;
