/**
 * Convert a date object to a string that can be used as a date input value.
 */
export default function dateToInputValue(date: Date): string {
  if (!date || Number.isNaN(date.getTime())) {
    return '';
  }
  return date.toISOString().slice(0, 10);
}

/**
 * Convert a date object into a string with MM/DD/YYYY format to emulate the display of the value in
 * <input type="date" />
 * This is only intended for disabled inputs and for presentation purposes only.
 * @param date
 */
export function dateToStringInputValue(date: Date | string | number): string {
  date = new Date(date);
  if (!date || Number.isNaN(date.getTime())) {
    return '';
  }
  return `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}/${
    date.getDate() < 10 ? '0' : ''
  }${date.getDate()}/${date.getFullYear()}`;
}
