import { AxiosInstance } from 'axios';
import Employees from '../models/Employees';
import Omit from '../utils/Omit.d';

export async function findEmployee(
  axios: AxiosInstance,
  employeeId: string,
  name: string,
): Promise<Employees | null> {
  const response = await axios.post('/employee/find', {
    employeeId,
    name,
  });
  return response.data.employee;
}

export async function createEmployee(
  axios: AxiosInstance,
  employee: Omit<Employees, 'storeId'>,
): Promise<Employees> {
  const response = await axios.post('/employee', employee);
  return response.data.employee;
}

export async function updateEmployee(
  axios: AxiosInstance,
  employeeId: string | number,
  employee: Omit<Employees, 'storeId'>,
): Promise<Employees> {
  const response = await axios.put(`/employee/${employeeId}`, employee);
  return response.data.employee;
}
