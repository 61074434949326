import React from 'react';
import styled from 'styled-components/macro';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (checked: boolean) => any;
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const { onChange, onValueChange, ...rest } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(event.currentTarget.checked);
    }
  }

  return <input {...rest} onChange={handleChange} type="checkbox" />;
};

export default styled(Checkbox)``;
