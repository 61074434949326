import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import OverShortByEmployeeForm from '../components/OverShortByEmployeeForm';
import withAuth from '../components/withAuth';

const OverShortByEmployeeLog: PageComponent = props => {
  return (
    <Page {...props}>
      <OverShortByEmployeeForm
        employees={[
          {
            id: 9999,
            storeId: 9999,
            shiftDate: new Date(),
            shiftNumber: 9999,
            employeeNumber: 9999,
            firstName: 'first',
            lastName: 'last',
            overShort: 9999,
            comments: '',
          },
          {
            id: 9999,
            storeId: 9999,
            shiftDate: new Date(),
            shiftNumber: 9999,
            employeeNumber: 9999,
            firstName: 'first',
            lastName: 'last',
            overShort: 9999,
            comments: '',
          },
          {
            id: 9999,
            storeId: 9999,
            shiftDate: new Date(),
            shiftNumber: 9999,
            employeeNumber: 9999,
            firstName: 'first',
            lastName: 'last',
            overShort: 9999,
            comments: '',
          },
        ]}
      />
    </Page>
  );
};

export default styled(withAuth(OverShortByEmployeeLog))``;
