import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import FormBox from './FormBox';
import Column from './Column';
import Row from './Row';
import NumberInput from './NumberInput';
import Table from './Table';
import Select from './Select';
import BlockButton from './BlockButton';
import {
  BrandInventoryRowDraft,
  ActivePromo,
} from '../api/BrandInventoryEndpoints';
import noop from '../utils/noop';
import Input from './Input';
import formatISODate from '../utils/formatISODate';

interface BrandInventoryFormProps {
  eopDate: string;
  storeId: number;
  grandTotal: number;
  value: BrandInventoryRowDraft[];
  activePromos: ActivePromo[];
  closingTotal: number;
  closingBluTotal: number;
  onProductChange?: (
    rowId: number,
    brandId: number,
    oldBrandId: number,
    packCount: number | string,
    cartonCount: number | string,
  ) => any;
  onOtherProductChange?: (
    rowId: number,
    brandId: number,
    oldBrandId: number,
    brandCount: number | string,
    brandCartonCount: number | string,
  ) => any;
  onSubmit?: (value: BrandInventoryRowDraft[]) => any;
}

const BrandInventoryForm: React.FC<BrandInventoryFormProps> = props => {
  const {
    eopDate,
    storeId,
    value,
    grandTotal,
    closingTotal,
    closingBluTotal,
    activePromos,
    onProductChange = noop,
    onOtherProductChange = noop,
    onSubmit = noop,
    ...rest
  } = props;
  const closingDate = eopDate.slice(0, 10);

  const products = [
    { brandId: 11, name: 'Camel Other' },
    { brandId: 239, name: 'Camel Turkish' },
    { brandId: 14, name: 'Camel NF' },
    { brandId: 82, name: 'Winston' },
    { brandId: 51, name: 'Marlboro' },
    { brandId: 53, name: 'Marb Men' },
    { brandId: 193, name: 'Camel Crush' },
    { brandId: 208, name: 'Camel Core/Capsule' },
    { brandId: 68, name: 'Pall Mall / Chesterfield' },
    { brandId: 63, name: 'Newport' },
    { brandId: 170, name: 'Newport Non-Menthol' },
    { brandId: 3, name: 'American Spirit / Nat Sherman' },
    { brandId: 37, name: 'L&M' },
    { brandId: 52, name: 'Marlboro 72' },
    { brandId: 156, name: 'Marlb Special Select' },
    { brandId: 183, name: 'Marb SB/Edge/Black' },
    { brandId: 105, name: 'Other Misc Cigs' },
    { brandId: 35, name: 'Kool' },
    { brandId: 372, name: 'Lucky Strikes' },
    { brandId: 54, name: 'Marb Men 72s' },
    { brandId: 256, name: 'Marb Men SB/Black/NXT' },
    { brandId: 106, name: 'Expired Product' },
  ].map(product => ({
    ...product,
    data: getProductByBrandId(product.brandId),
  }));

  const vusePodsProduct = getProductByBrandId(168);
  const grizzlyProduct = getProductByBrandId(74);
  const vusePowerKitsProduct = getProductByBrandId(203);
  const veloProduct = getProductByBrandId(382);

  const promos = useMemo<Array<BrandInventoryRowDraft | undefined>>(
    () => value.filter(product => product.isPromo),
    [value],
  );

  while (promos.length < 6) {
    promos.push(undefined);
  }

  function handleSubmit() {
    onSubmit(value);
  }

  function getProductByBrandId(brandId: number) {
    return value.find(product => product.brandId === brandId);
  }

  return (
    <FormBox
      {...rest}
      title="End of Period Inventory"
      onCloseClick={handleSubmit}
    >
      <Form onSubmit={handleSubmit} data-testid="form">
        <HeaderRow>
          <label>Store #:</label>
          <NumberInput disabled value={storeId} />
          <label>EOPDate</label>
          <Input readOnly value={formatISODate(eopDate)} />
        </HeaderRow>
        <ProductTable
          headers={['Product', 'Pack Count', 'Carton Count', 'Total']}
        >
          {products.map(product => (
            <ProductTableRow
              key={product.name}
              rowId={product.data ? product.data.id : 0}
              brandId={product.brandId}
              productName={product.name}
              packCount={(product.data && product.data.packCount) || ''}
              cartonCount={(product.data && product.data.cartonCount) || ''}
              onChange={onProductChange}
            />
          ))}
        </ProductTable>
        <PromoTable headers={['Promos']}>
          {promos.map((promo, i) => (
            <PromoTableRow
              key={i}
              rowId={promo ? promo.id : 0}
              brandId={(promo && promo.brandId) || null}
              packCount={(promo && promo.packCount) || ''}
              cartonCount={(promo && promo.cartonCount) || ''}
              brandName={promo ? promo.brandName : ''}
              activePromos={activePromos}
              onChange={onProductChange}
            />
          ))}
        </PromoTable>
        <Table>
          <ProductTableRow
            rowId={vusePodsProduct ? vusePodsProduct.id : 0}
            brandId={168}
            productName="VUSE Pods"
            packCount={vusePodsProduct ? vusePodsProduct.packCount : ''}
            cartonCount={vusePodsProduct ? vusePodsProduct.cartonCount : ''}
            onChange={onOtherProductChange}
          />
          <ProductTableRow
            rowId={grizzlyProduct ? grizzlyProduct.id : 0}
            brandId={74}
            productName="Grizzly"
            packCount={grizzlyProduct ? grizzlyProduct.packCount : ''}
            cartonCount={grizzlyProduct ? grizzlyProduct.cartonCount : ''}
            onChange={onOtherProductChange}
          />
          <ProductTableRow
            rowId={veloProduct ? veloProduct.id : 0}
            brandId={382}
            productName="VELO"
            packCount={veloProduct ? veloProduct.packCount : ''}
            cartonCount={veloProduct ? veloProduct.cartonCount : ''}
            onChange={onOtherProductChange}
          />
          <tr>
            <TdAlignRight>
              <ProductName>VUSE Power Kits/Units:</ProductName>
            </TdAlignRight>
            <td>
              <NumberInput
                value={
                  vusePowerKitsProduct ? vusePowerKitsProduct.packCount : ''
                }
                onChange={event =>
                  onProductChange(
                    vusePowerKitsProduct ? vusePowerKitsProduct.id : 0,
                    203,
                    203,
                    event.target.value,
                    0,
                  )
                }
              />
            </td>
            <td colSpan={2}>(not included in grand total)</td>
          </tr>
        </Table>
        <FooterRow>
          <DoneButton>Done</DoneButton>
          <CenterRow>
            <CenterRow>
              <label>
                <b>Grand Total</b>
              </label>
              <NumberInput readOnly value={grandTotal.toFixed(2)} />
            </CenterRow>
            <ClosingColumn>
              <CenterRow>
                <label>Closing Date:</label>
                <Input
                  style={{ width: 100 }}
                  disabled
                  value={formatISODate(closingDate)}
                />
              </CenterRow>

              <CenterRow>
                <label>Closing Total:</label>
                <NumberInput disabled value={closingTotal} />
              </CenterRow>

              <CenterRow>
                <label>VUSE Kit Closing Total:</label>
                <NumberInput disabled value={closingBluTotal} />
              </CenterRow>
            </ClosingColumn>
          </CenterRow>
        </FooterRow>
      </Form>
    </FormBox>
  );
};

export default styled(BrandInventoryForm)`
  width: 750px;

  ${NumberInput} {
    max-width: 100px;
    height: 20px;
    padding-left: 4px;
    padding-right: 0;
  }
  ${Input} {
    max-width: 100px;
    height: 20px;
    padding-left: 4px;
    padding-right: 0;
  }
  ${Select} {
    width: 275px;
    height: 20px;
  }

  ${Table} td:first-child {
    width: 370px;
  }

  ${Table} td:not(:first-child) {
    width: 80px;
  }

  ${Table} th:first-child {
    text-align: right;
    padding-right: 20px;
  }
`;

const HeaderRow = styled(Row)`
  align-items: center;
  label {
    margin-right: 70px;
  }
  label:last-of-type {
    margin-left: 70px;
  }
`;

const ProductTable = styled(Table)`
  margin-top: 20px;
`;

const TdAlignRight = styled('td')`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const PromoTable = styled(Table)``;

function ProductTableRow(props: {
  rowId: number;
  brandId: number;
  productName: string;
  packCount: number | string;
  cartonCount: number | string;
  onChange?: (
    rowId: number,
    brandId: number,
    oldBrandId: number,
    packCount: number | string,
    cartonCount: number | string,
  ) => any;
}) {
  const {
    rowId,
    brandId,
    productName,
    packCount,
    cartonCount,
    onChange = noop,
    ...rest
  } = props;

  return (
    <tr {...rest}>
      <TdAlignRight>
        <ProductName>{productName}:</ProductName>
      </TdAlignRight>
      <td>
        <CenterRow>
          <NumberInput
            value={packCount}
            onChange={event =>
              onChange(rowId, brandId, brandId, event.target.value, cartonCount)
            }
          />
          +
        </CenterRow>
      </td>
      <td>
        <CenterRow>
          <NumberInput
            value={cartonCount}
            onChange={event =>
              onChange(rowId, brandId, brandId, packCount, event.target.value)
            }
          />
          =
        </CenterRow>
      </td>
      <td>
        <NumberInput disabled value={+packCount + +cartonCount} />
      </td>
    </tr>
  );
}

function PromoTableRow(props: {
  rowId: number;
  packCount: number | string;
  cartonCount: number | string;
  brandId: number | null;
  brandName: string;
  activePromos: ActivePromo[];
  onChange?: (
    rowId: number,
    promoId: number,
    oldBrandId: number,
    packCount: string | number,
    cartonCount: string | number,
  ) => any;
}) {
  const {
    rowId,
    packCount,
    brandId,
    cartonCount,
    activePromos,
    brandName,
    onChange = noop,
    ...rest
  } = props;

  function handleChange(
    newBrandId: number,
    newPackCount: number | string,
    newCartonCount: number | string,
  ) {
    onChange(rowId, newBrandId, brandId || 0, newPackCount, newCartonCount);
  }

  return (
    <tr {...rest}>
      <TdAlignRight>
        <Select
          onChange={event =>
            handleChange(+event.target.value, packCount, cartonCount)
          }
          value={brandId || 'BLANK'}
        >
          <option value="BLANK">{brandName}</option>
          {activePromos.map(p => (
            <option key={p.brandId} value={p.brandId}>
              {p.promoName}
            </option>
          ))}
        </Select>
      </TdAlignRight>
      <td>
        <CenterRow>
          <Input
            value={packCount}
            onChange={event =>
              handleChange(brandId || 0, event.target.value, cartonCount)
            }
          />
          +
        </CenterRow>
      </td>
      <td>
        <CenterRow>
          <Input
            value={cartonCount}
            onChange={event =>
              handleChange(brandId || 0, packCount, +event.target.value)
            }
          />
          =
        </CenterRow>
      </td>
      <td>
        <Input disabled value={+packCount + +cartonCount} readOnly />
      </td>
    </tr>
  );
}

const CenterRow = styled(Row)`
  align-items: center;
`;

const ProductName = styled('div')`
  white-space: nowrap;
  text-align: right;
  width: 275px;
`;

const FooterRow = styled(Row)`
  justify-content: space-between;

  label {
    margin-right: 10px;
    width: 120px;
    text-align: right;
  }
`;

const DoneButton = styled(BlockButton)`
  height: 40px;
  margin-top: 30px;
`;

const ClosingColumn = styled(Column)`
  margin-left: 20px;
`;
