import styled from 'styled-components/macro';

/**
 * A button with all the button styles removed.
 * Useful when an element should be a 'button' tag despite not looking like a button.
 */
const BlankButton = styled('button')`
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: none;
  padding: none;
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  color: inherit;
  height: auto;
  width: auto;
  cursor: pointer;
`;

export default BlankButton;
