export function assertPackIdIsNumber(
  id: unknown,
  cartonOrPackName: string | null,
): number {
  const asNumber = Number(id);
  if (Number.isFinite(asNumber)) {
    return asNumber;
  }
  throw new Error(`Invalid ID for ${cartonOrPackName || 'a pack or carton'}`);
}
