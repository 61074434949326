import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import BluOverShortForm from '../components/BluOverShortForm';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import isValidDate from '../utils/isValidDate';
import { BluOverShortEntry } from '../api/BluOverShortEndpoints';
import SpinnerOverlay from '../components/SpinnerOverlay';

const BluOverShort: PageComponent = props => {
  const { user } = props;
  const [bluOverShortData, setBluOverShortData] = useState<
    BluOverShortEntry[] | null
  >(null);

  useAsyncEffect(async () => {
    const beginningDate = window.prompt('Beginning date');

    if (!beginningDate) {
      window.history.back();
      return;
    }

    if (!isValidDate(beginningDate)) {
      window.alert('Invalid date format');
      window.history.back();
      return;
    }

    const endingDate = window.prompt('Ending date');

    if (!endingDate) {
      window.history.back();
      return;
    }

    if (!isValidDate(endingDate)) {
      window.alert('Invalid date format');
      window.history.back();
      return;
    }

    const data = await Api.getBluOverShortData(beginningDate, endingDate);

    setBluOverShortData(data);
  }, []);

  if (!bluOverShortData || !user) {
    // loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <BluOverShortForm storeId={user.storeId} data={bluOverShortData} />
    </Page>
  );
};

export default styled(withAuth(BluOverShort))``;
