import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import BlockButton from './BlockButton';
import InputRow from './InputRow';
import FormBox from './FormBox';
import noop from '../utils/noop';
import Input from './Input';
import { itsReportFromDraft } from '../models/ItsReport';
import ItsReport, { ItsReportDraft } from '../models/ItsReport';
import ShiftMaster, {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from '../models/ShiftMaster';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';

export interface LotteryReportsFormProps {
  shiftDate: string;
  shiftNumber: number;
  storeId: number;
  shiftMaster: ShiftMasterDraft;
  readOnly: boolean;
  onSubmit?: (itsReport: ItsReport, shiftMaster: ShiftMaster) => any;
  onCancel?: () => any;
  isElmers: boolean;
}

const LotteryReportsForm: React.FC<LotteryReportsFormProps> = props => {
  const [itsReport, setItsReport] = useState<ItsReportDraft | null>(null);
  const [shiftMaster, setShiftMaster] = useState<ShiftMasterDraft>(
    props.shiftMaster,
  );
  const {
    shiftDate,
    shiftNumber,
    storeId,
    readOnly,
    onSubmit = noop,
    onCancel = noop,
    isElmers,
    ...rest
  } = props;

  useAsyncEffect(async () => {
    const lottoReportData = await Api.getLotteryReportData(
      shiftDate,
      shiftNumber,
    );

    setItsReport(
      lottoReportData || {
        shiftDate,
        storeId,
        shift: shiftNumber,
        activated: null,
        activatedDetails: null,
        onLinePrizes: null,
        onLineSales: null,
        soffValidation: null,
        vpValidation: null,
      },
    );
  }, [shiftDate, shiftNumber]);

  if (!itsReport) {
    // Loading
    return null;
  }

  const vpSales = shiftMaster.vpSales !== null ? shiftMaster.vpSales : 0;
  const vpValidation =
    itsReport && itsReport.vpValidation !== null ? itsReport.vpValidation : 0;
  const onLinePrizes =
    itsReport && itsReport.onLinePrizes !== null ? itsReport.onLinePrizes : 0;
  const onLineSales =
    itsReport && itsReport.onLineSales !== null ? itsReport.onLineSales : 0;
  const activated =
    itsReport && itsReport.activated !== null ? itsReport.activated : 0;
  const soffValidation =
    itsReport && itsReport.soffValidation !== null
      ? itsReport.soffValidation
      : 0;

  function handleItsReportChange<K extends keyof ItsReportDraft>(
    key: K,
    keyValue: ItsReportDraft[K],
  ) {
    setItsReport({
      ...itsReport,
      storeId,
      shiftDate,
      vpValidation,
      onLineSales,
      onLinePrizes,
      activated,
      soffValidation,
      shift: shiftNumber,
      activatedDetails: null,
      [key]: keyValue,
    });
  }

  function handleVpValidationChange(newVpValidation: string) {
    handleItsReportChange('vpValidation', newVpValidation);
  }

  function handleVpSalesChange(newVpSales: string) {
    setShiftMaster({
      ...shiftMaster,
      vpSales: newVpSales,
    });
  }

  function handleOnLineSalesChange(newOnLineSales: string) {
    handleItsReportChange('onLineSales', newOnLineSales);
  }

  function handleOnLinePrizesChange(newOnLinePrizes: string) {
    handleItsReportChange('onLinePrizes', newOnLinePrizes);
  }

  function handleActivatedChange(newActivated: string) {
    handleItsReportChange('activated', newActivated);
  }

  function handleSoffValidationChange(newSoffValidation: string) {
    handleItsReportChange('soffValidation', newSoffValidation);
  }

  async function handleSubmit() {
    if (!itsReport) {
      return;
    }
    const dateShifts = await Api.getDateShifts(shiftDate);
    const priorShifts = dateShifts.filter(other => other.shift < shiftNumber);

    const priorVpValidation = priorShifts.reduce(
      (total, row) => total + (row.vpValidation || 0),
      0,
    );
    const priorSOffValidation = priorShifts.reduce(
      (total, row) => total + (row.scratchOffValidation || 0),
      0,
    );
    const priorOnlineSales = priorShifts.reduce(
      (total, row) => total + (row.onLineSales || 0),
      0,
    );
    const priorOnlinePrizes = priorShifts.reduce(
      (total, row) => total + (row.onLinePrizes || 0),
      0,
    );

    const newItsReport = itsReportFromDraft(itsReport);
    const newShiftMaster = shiftMasterFromDraft(shiftMaster);

    newShiftMaster.vpValidation =
      (newItsReport.vpValidation || 0) - priorVpValidation;
    newShiftMaster.onLineSales =
      (newItsReport.onLineSales || 0) - priorOnlineSales;
    newShiftMaster.onLinePrizes =
      (newItsReport.onLinePrizes || 0) - priorOnlinePrizes;
    newShiftMaster.scratchOffValidation =
      (newItsReport.soffValidation || 0) - priorSOffValidation;

    onSubmit(newItsReport, newShiftMaster);
  }

  return (
    <FormBox
      {...rest}
      title="lottery reports: Form"
      onCloseClick={handleSubmit}
    >
      <NewVTLFormColumn>
        <h1>VLT</h1>
        <h2>(Current Day Cash Flow)</h2>
        <table>
          <thead>
            <tr>
              <th>
                VLT
                <br />
                POS
              </th>
              <th>$&nbsp;IN</th>
              <th>$&nbsp;OUT</th>
              <th>$&nbsp;NET</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>2</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>3</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>4</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>5</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>6</td>
              <td>0</td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>Totals</td>
              <td>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={vpSales}
                  onValueChange={handleVpSalesChange}
                  data-testid="vpSalesInput"
                />
              </td>
              <td>0</td>
              <td>0</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>
                  VLT
                  <br />
                  POS
                </b>
              </td>
              <td colSpan={2}>
                <b>Validated</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>1</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>2</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>3</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>4</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>5</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>6</td>
              <td colSpan={4}>0</td>
            </tr>
            <tr>
              <td colSpan={2}>Total Validated</td>
              <td colSpan={4}>
                <Input
                  isMoney
                  readOnly={readOnly}
                  value={vpValidation}
                  onValueChange={handleVpValidationChange}
                  data-testid="vpValidationInput"
                />
              </td>
            </tr>
          </tbody>
          <tfoot />
        </table>
      </NewVTLFormColumn>

      <CombinedSalesReportColumn>
        <table>
          <thead>
            <tr>
              <th colSpan={2} style={{ borderBottom: '1px solid black' }}>
                Combined Sales Report
              </th>
            </tr>
            <tr>
              <th>ON-LINE</th>
              <th>SCRATCH-ITS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <InputRow>
                  <label>TOTAL O/L SALES:</label>
                  <Input
                    isMoney
                    readOnly={readOnly}
                    value={onLineSales}
                    onValueChange={handleOnLineSalesChange}
                    tabIndex={3}
                    data-testid="totalonLineSalesInput"
                  />
                </InputRow>
              </td>
              <td>
                {isElmers && (
                  <InputRow>
                    <label>
                      VALIDATED:
                      <br />
                      (S-Off)
                    </label>
                    <Input
                      isMoney
                      readOnly={readOnly}
                      value={soffValidation}
                      onValueChange={handleSoffValidationChange}
                      tabIndex={6}
                      data-testid="soffValidationInput"
                    />
                  </InputRow>
                )}
                {!isElmers && (
                  <InputRow>
                    <label>ACTIVATED:</label>
                    <Input
                      isMoney
                      readOnly={readOnly}
                      value={activated}
                      onValueChange={handleActivatedChange}
                      tabIndex={5}
                      data-testid="activatedInput"
                    />
                  </InputRow>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <InputRow>
                  <label>O/L VALIDATED:</label>
                  <Input
                    isMoney
                    readOnly={readOnly}
                    value={onLinePrizes}
                    onValueChange={handleOnLinePrizesChange}
                    tabIndex={4}
                    data-testid="olValidatedInput"
                  />
                </InputRow>
              </td>
              <td>
                {!isElmers && (
                  <InputRow>
                    <label>
                      VALIDATED:
                      <br />
                      (S-Off)
                    </label>
                    <Input
                      isMoney
                      readOnly={readOnly}
                      value={soffValidation}
                      onValueChange={handleSoffValidationChange}
                      tabIndex={6}
                      data-testid="soffValidationInput"
                    />
                  </InputRow>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <BlockButton onClick={onCancel} data-testid="cancelButton">
                  Cancel
                </BlockButton>
              </td>
              <td>
                <BlockButton onClick={handleSubmit} data-testid="enterButton">
                  Enter
                </BlockButton>
              </td>
            </tr>
          </tbody>
          <tfoot />
        </table>
      </CombinedSalesReportColumn>
    </FormBox>
  );
};

export default styled(LotteryReportsForm)`
  width: 1000px;
  height: 680px;
  margin: auto;
  flex-direction: row;

  ${Input} {
    width: 120px;
  }
`;

const CombinedSalesReportColumn = styled(Column)`
  width: 450px;
  height: 557px;
  padding-left: 10px;

  th {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  ${InputRow} {
    margin-bottom: 30px;
  }

  > table {
    width: 100%;
  }
`;

const NewVTLFormColumn = styled(Column)`
  flex-shrink: 0;
  width: 500px;
  height: 557px;
  border-right: 1px solid black;

  > h1,
  h2 {
    text-align: center;
    font-size: 16px;
  }

  > table {
    width: 100%;
    margin-top: 20px;
    text-align: center;
  }

  [data-left] {
    text-align: left;
  }
`;
