import { AxiosInstance } from 'axios';
import Brandsales from '../models/Brandsales';

export async function getShiftBrandSales(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number,
): Promise<Brandsales[]> {
  const response = await axios.get(`/brandSales/${shiftDate}/${shiftNumber}`);
  return response.data.brandSales;
}

export async function updateShiftBrandSales(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number,
  brandSales: Brandsales[],
) {
  await axios.put(`/brandSales/${shiftDate}/${shiftNumber}`, {
    brandSales,
  });
}
