import { AxiosInstance } from 'axios';

export async function getCigOverShortData(
  axios: AxiosInstance,
  startDate: string,
  endDate: string,
) {
  const response = await axios.get(
    `/cig-over-short/${new Date(startDate).toISOString()}/${new Date(
      endDate,
    ).toISOString()}`,
  );
  return response.data.cigOverShortData;
}
