import Axios, { AxiosInstance, AxiosError } from 'axios';
import * as userEndpoints from './UserEndpoints';
import * as shiftMasterEndpoints from './ShiftMasterEndpoints';
import * as videoDepositLogEndpoints from './VideoDepositLogEndpoints';
import * as storeEndpoints from './StoreEndpoints';
import * as over21TestEndpoints from './Over21TestEndpoints';
import * as cigPricesEndpoints from './CigPricesEndpoints';
import * as shiftEndpoints from './ShiftEndpoints';
import * as cigOverShortEndpoints from './CigOverShortEndpoints';
import * as BrandsalesEndpoints from './BrandsalesEndpoints';
import * as ScratchTicketEndpoints from './ScratchTicketEndpoints';
import * as LotteryReportEndpoints from './LotteryReportEndpoints';
import * as MachineAuditEndpoints from './MachineAuditEndpoints';
import * as BluOverShortEndpoints from './BluOverShortEndpoints';
import * as ErrorLogEndpoints from './ErrorLogEndpoints';
import * as GuestPlayEndpoints from './GuestPlayEndpoints';
import * as BrandInventoryEndpoints from './BrandInventoryEndpoints';
import * as AuditSafeReportEndpoints from './AuditSafeReportEndpoints';
import * as EmployeeEndpoints from './EmployeeEndpoints';
import * as W2GEndpoints from './W2GEndpoints';
import * as OldAuditEndpoints from './OldAuditEndpoints';
import * as TotalDollarsPlayedEndpoints from './TotalDollarsPlayedEndpoints';
import * as InventoryExceptionReportEndpoints from './InventoryExceptionReportEndpoints';

const axios: AxiosInstance = Axios.create({
  baseURL: `/api`,
});

const api = {
  ...userEndpoints,
  ...shiftMasterEndpoints,
  ...videoDepositLogEndpoints,
  ...storeEndpoints,
  ...over21TestEndpoints,
  ...cigPricesEndpoints,
  ...shiftEndpoints,
  ...cigOverShortEndpoints,
  ...BrandsalesEndpoints,
  ...ScratchTicketEndpoints,
  ...LotteryReportEndpoints,
  ...MachineAuditEndpoints,
  ...BluOverShortEndpoints,
  ...ErrorLogEndpoints,
  ...GuestPlayEndpoints,
  ...BrandInventoryEndpoints,
  ...AuditSafeReportEndpoints,
  ...EmployeeEndpoints,
  ...W2GEndpoints,
  ...OldAuditEndpoints,
  ...TotalDollarsPlayedEndpoints,
  ...InventoryExceptionReportEndpoints,
};

export default bindAxiosToApi(api, axios);

export interface ServerError {
  error?: { message?: string };
  status?: number;
}
/**
 * Parse the axios rejection to get the real error instead of the generic error
 * @param error
 */
export function getAxiosError(error: AxiosError): ServerError {
  if (error.response) {
    const { data } = error.response;
    const serverError: ServerError = {};
    if (data.error) {
      serverError.error = {};
      if (data.error.message) {
        serverError.error.message = data.error.message;
      }
    }
    if (data.status) {
      serverError.status = data.status;
    }

    return serverError;
  }
  return { error: { message: 'A Server Error Occurred' } };
}

export function throwAxiosError(error: AxiosError) {
  throw getAxiosError(error);
}

/**
 * Binds an axios instance to every function in the unbound api object.
 * Returns a bound api object.
 */
function bindAxiosToApi(
  unboundApi: UnboundApi,
  axiosInstance: AxiosInstance,
): BoundApi {
  const boundApi: Record<string, any> = {};
  for (const [key, value] of Object.entries(unboundApi)) {
    const fn = value as (axios: AxiosInstance, ...rest: any[]) => any;
    boundApi[key] = fn.bind(null, axiosInstance);
  }
  return boundApi as BoundApi;
}

type UnboundApi = typeof api;
type TailArgs<F> = F extends (first: any, ...rest: infer G) => any
  ? G
  : never[];
type BoundApi = {
  [K in keyof UnboundApi]: (
    ...args: TailArgs<UnboundApi[K]>
  ) => ReturnType<UnboundApi[K]>
};
