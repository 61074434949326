import styled from 'styled-components/macro';
import Row from './Row';

/**
 * ul element without bullet points or default padding.
 */
const List = styled('ul')`
  list-style: none;
  padding: 0;
`;

export const ListItem = styled(Row).attrs({ as: 'li' })``;

export default List;
