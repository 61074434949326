import React from 'react';
import styled from 'styled-components/macro';
import DateInput from './DateInput';
import BlockButton from './BlockButton';
import Form from './Form';
import FormBox from './FormBox';

export interface ScratchTicketInvDateFormProps {
  date: Date;
  onDateChange?: (date: Date) => any;
  onSubmit?: () => any;
}

const ScratchTicketInvDateForm: React.FC<
  ScratchTicketInvDateFormProps
> = props => {
  const { date, onDateChange, onSubmit, ...rest } = props;

  return (
    <FormBox {...rest} title="ScratchTicketInvDate" onCloseClick={onSubmit}>
      <Form onSubmit={onSubmit} data-testid="form">
        <h3>Scratch Ticket Inv Date</h3>
        <DateInput
          value={date}
          onValueChange={onDateChange}
          data-testid="dateInput"
        />
        <BlockButton data-testid="enterInventoryButton">
          Enter Inventory
        </BlockButton>
      </Form>
    </FormBox>
  );
};

export default styled(ScratchTicketInvDateForm)`
  width: 290px;
  height: 250px;
  form {
    width: 100%;
  }

  h3,
  ${DateInput}, ${BlockButton} {
    margin-left: 5px;
  }

  ${DateInput} {
    margin: 20px 5px;
    height: 25px;
    width: 160px;
    text-align: right;
    padding-right: 30px;
  }

  ${BlockButton} {
    min-height: 30px;
    height: 30px;
    padding-top: 2px;
    width: 160px;
    min-width: 160px;
  }
`;
