import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import ScratchTicketInvDateForm from '../components/ScratchTicketInvDateForm';

const ScratchTicketInventory: PageComponent = props => {
  const [inventoryDate, setInventoryDate] = useState(new Date());
  const [showInventoryDateForm, setShowInventoryDateForm] = useState(true);

  function onDateSelected(date: Date) {
    setInventoryDate(date);
  }

  return (
    <Page {...props}>
      {showInventoryDateForm ? (
        <ScratchTicketInvDateForm
          date={inventoryDate}
          onDateChange={onDateSelected}
          onSubmit={() => {
            setShowInventoryDateForm(false);
          }}
        />
      ) : (
        <div>
          This will be the ScratchTicketInventory for{' '}
          {inventoryDate.toDateString()}
          {/* Placeholder */}
        </div>
      )}
    </Page>
  );
};

export default withAuth(styled(ScratchTicketInventory)`
  justify-content: center;
  align-items: center;
`);
