import styled from 'styled-components/macro';
import BlankButton from './BlankButton';
import { hideable, HideableProps } from '../utils/hideable';

/**
 * Generic block button with centered text.
 */
const BlockButton = styled(BlankButton)<HideableProps>`
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #eeeeee;
  top: 0;
  min-height: 40px;
  font-size: 18px;
  min-width: 200px;
  color: black;
  transition: top 0.2s;
  padding: 5px 20px 5px 20px;

  border-bottom: 2px solid #777;
  border-right: 2px solid #777;
  border-top: 2px solid white;
  border-left: 2px solid white;

  &:disabled {
    color: #ccc;
    cursor: default;
  }

  &:not(:disabled):active {
    border-top: 2px solid #777;
    border-left: 2px solid #777;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
  }
  ${hideable}
`;

export default BlockButton;
