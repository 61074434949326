import React from 'react';
import styled from 'styled-components/macro';
import Row from './Row';
import { hideable, HideableProps } from '../utils/hideable';

const InputRow: React.FC<{ style?: any }> = props => {
  return <Row {...props} />;
};

export default styled(InputRow)<HideableProps>`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  ${hideable}
`;
