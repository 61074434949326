/**
 * Get the number of years since the passed date rounded down.
 *
 * @param date - Birthday date
 * @returns - Age number
 */
export default function getAge(date: Date): number {
  const now = new Date();
  const monthDiff = now.getMonth() - date.getMonth();
  let age = now.getFullYear() - date.getFullYear();

  if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < date.getDate())) {
    age = age - 1;
  }

  if (age < 0) {
    return 0;
  }

  return age;
}
