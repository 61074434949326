import React from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import Input from './Input';
import InputRow from './InputRow';
import Row from './Row';
import BlockButton from './BlockButton';
import NumberInput from './NumberInput';
import FormBox from './FormBox';
import isValidDate from '../utils/isValidDate';
import { hideByInvisible } from '../utils/hideable';

export const createShiftFormDefaultValue: CreateShiftFormValue = {
  shiftDate: '',
  shift: '',
  beginningTill: '',
  beginningChange: '',
  beginningPokerBank: '0',
};

export interface CreateShiftFormValue {
  shiftDate: string;
  shift: string;
  beginningTill: string;
  beginningChange: string;
  beginningPokerBank: string;
}

export interface CreateShiftFormProps {
  employeeId: string;
  disabled?: boolean;
  value: CreateShiftFormValue;
  isElmers: boolean;
  onChange(value: CreateShiftFormValue): unknown;
  onSubmit?(formResult: CreateShiftFormValue): any;
  onCancelClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): any;
}

const CreateShiftForm: React.FC<CreateShiftFormProps> = props => {
  const {
    employeeId,
    onChange,
    onSubmit,
    onCancelClick,
    disabled,
    value,
    isElmers,
    ...rest
  } = props;
  const {
    shiftDate,
    shift,
    beginningTill,
    beginningChange,
    beginningPokerBank,
  } = value;

  const setShiftDate = (v: string) => onChange({ ...value, shiftDate: v });
  const setShift = (v: string) => onChange({ ...value, shift: v });
  const setBeginningTill = (v: string) =>
    onChange({ ...value, beginningTill: v });
  const setBeginningChange = (v: string) =>
    onChange({ ...value, beginningChange: v });
  const setBeginningPokerBank = (v: string) =>
    onChange({ ...value, beginningPokerBank: v });

  function handleCancelClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) {
    event.preventDefault(); // stop form from submitting
    if (onCancelClick) {
      onCancelClick(event);
    }
  }

  function handleSubmit() {
    if (!isValidDate(shiftDate)) {
      window.alert('Invalid shift date.');
      return;
    }
    const parsedShift = +shift;
    if (Number.isNaN(parsedShift) || parsedShift <= 0 || parsedShift > 6) {
      window.alert('Invalid shift number. Must be between 1 and 6.');
      return;
    }
    if (onSubmit) {
      onSubmit({
        shiftDate: `${shiftDate}T00:00:00.000Z`,
        shift,
        beginningTill,
        beginningChange,
        beginningPokerBank,
      });
    }
  }

  return (
    <FormBox {...rest} title="create shift : Form">
      <Form onSubmit={handleSubmit} data-testid="form">
        <InputRow>
          <label>Date:</label>
          <Input
            type="date"
            value={shiftDate}
            onChange={event => setShiftDate(event.currentTarget.value)}
            disabled={disabled}
            data-testid="dateInput"
          />

          <label>Shift:</label>
          <Input
            value={shift}
            onValueChange={setShift}
            disabled={disabled}
            data-testid="shiftInput"
          />

          <label>Employee ID:</label>
          <Input disabled value={employeeId} />
        </InputRow>

        <InputRow>
          <label>Beginning Till:</label>
          <Input
            isMoney
            value={beginningTill}
            onValueChange={setBeginningTill}
            disabled={disabled}
            data-testid="beginningTillInput"
          />

          <HidableLabel hide={isElmers}>Beginning Change:</HidableLabel>
          <HidableInput
            isMoney
            value={beginningChange}
            onValueChange={setBeginningChange}
            disabled={disabled || isElmers}
            data-testid="beginningChangeInput"
            hide={isElmers}
          />

          <label>Cash Dispensed:</label>
          <Input
            isMoney
            value={beginningPokerBank}
            onValueChange={setBeginningPokerBank}
            disabled={disabled}
            data-testid="beginningPokerBankInput"
          />
        </InputRow>

        <ButtonRow>
          <BlockButton disabled={disabled} data-testid="createShiftButton">
            Create Shift
          </BlockButton>
          <BlockButton
            onClick={handleCancelClick}
            disabled={disabled}
            data-testid="cancelButton"
          >
            Cancel
          </BlockButton>
        </ButtonRow>
      </Form>
    </FormBox>
  );
};

export default styled(CreateShiftForm)`
  width: 1100px;
  label {
    width: 130px;
  }

  input,
  ${NumberInput} {
    width: 160px;
  }

  * + label {
    margin-left: 20px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: space-evenly;
`;

const HidableLabel = styled.label<{ hide: boolean }>`
  ${hideByInvisible}
`;
const HidableInput = styled(Input)<{ hide: boolean }>`
  ${hideByInvisible}
`;
