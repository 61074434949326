/**
 * Get the last value of an array. Returns `undefined` if the array is empty.
 */
export default function last<T>(array: T[]): T | undefined {
  const { length } = array;
  if (length > 0) {
    return array[length - 1];
  }
  return undefined;
}
