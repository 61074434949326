import {
  MAX_ELMERS_STORE_ID,
  MIN_ELMERS_STORE_ID,
} from '../constants/store/ElmersConstants';
import Store from '../models/Store';

export function isElmers(storeId: number | Pick<Store, 'storeId'>): boolean {
  if (typeof storeId !== 'number') {
    return isElmers(storeId.storeId);
  }
  return storeId >= MIN_ELMERS_STORE_ID && storeId <= MAX_ELMERS_STORE_ID;
}
