import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import useAsyncEffect from '../utils/useAsyncEffect';
import Page, { PageComponent } from '../components/Page';
import CigPriceSetupForm, { Value } from '../components/CigPriceSetupForm';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import SpinnerOverlay from '../components/SpinnerOverlay';
import getErrorMessage from '../utils/getErrorMessage';
import { assertPackIdIsNumber } from '../utils/assertPackIdIsNumber';

const ChangeCigPrices: PageComponent = props => {
  const [cigPrices, setCigPrices] = useState<Value | null>(null);

  useAsyncEffect(async () => {
    const currentCigPrices = await Api.getCigPrices();
    setCigPrices(currentCigPrices);
  }, []);

  async function handleSubmit(value: Value) {
    try {
      await Api.patchCigPrices({
        ...value,
        carton10Price:
          value.carton10Price !== null ? +value.carton10Price : null,
        carton11Price:
          value.carton11Price !== null ? +value.carton11Price : null,
        carton12Price:
          value.carton12Price !== null ? +value.carton12Price : null,
        carton13Price:
          value.carton13Price !== null ? +value.carton13Price : null,
        carton14Price:
          value.carton14Price !== null ? +value.carton14Price : null,
        carton15Price:
          value.carton15Price !== null ? +value.carton15Price : null,
        carton16Price:
          value.carton16Price !== null ? +value.carton16Price : null,
        carton17Price:
          value.carton17Price !== null ? +value.carton17Price : null,
        carton18Price:
          value.carton18Price !== null ? +value.carton18Price : null,
        carton19Price:
          value.carton19Price !== null ? +value.carton19Price : null,
        carton1Price: value.carton1Price !== null ? +value.carton1Price : null,
        carton20Price:
          value.carton20Price !== null ? +value.carton20Price : null,
        carton21Price:
          value.carton21Price !== null ? +value.carton21Price : null,
        carton22Price:
          value.carton22Price !== null ? +value.carton22Price : null,
        carton23Price:
          value.carton23Price !== null ? +value.carton23Price : null,
        carton24Price:
          value.carton24Price !== null ? +value.carton24Price : null,
        carton2Price: value.carton2Price !== null ? +value.carton2Price : null,
        carton3Price: value.carton3Price !== null ? +value.carton3Price : null,
        carton4Price: value.carton4Price !== null ? +value.carton4Price : null,
        carton5Price: value.carton5Price !== null ? +value.carton5Price : null,
        carton6Price: value.carton6Price !== null ? +value.carton6Price : null,
        carton7Price: value.carton7Price !== null ? +value.carton7Price : null,
        carton8Price: value.carton8Price !== null ? +value.carton8Price : null,
        carton9Price: value.carton9Price !== null ? +value.carton9Price : null,
        pack10Price: value.pack10Price !== null ? +value.pack10Price : null,
        pack11Price: value.pack11Price !== null ? +value.pack11Price : null,
        pack12Price: value.pack12Price !== null ? +value.pack12Price : null,
        pack13Price: value.pack13Price !== null ? +value.pack13Price : null,
        pack14Price: value.pack14Price !== null ? +value.pack14Price : null,
        pack15Price: value.pack15Price !== null ? +value.pack15Price : null,
        pack16Price: value.pack16Price !== null ? +value.pack16Price : null,
        pack17Price: value.pack17Price !== null ? +value.pack17Price : null,
        pack18Price: value.pack18Price !== null ? +value.pack18Price : null,
        pack19Price: value.pack19Price !== null ? +value.pack19Price : null,
        pack1Price: value.pack1Price !== null ? +value.pack1Price : null,
        pack20Price: value.pack20Price !== null ? +value.pack20Price : null,
        pack21Price: value.pack21Price !== null ? +value.pack21Price : null,
        pack22Price: value.pack22Price !== null ? +value.pack22Price : null,
        pack23Price: value.pack23Price !== null ? +value.pack23Price : null,
        pack24Price: value.pack24Price !== null ? +value.pack24Price : null,
        pack25Price: value.pack25Price !== null ? +value.pack25Price : null,
        pack2Price: value.pack2Price !== null ? +value.pack2Price : null,
        pack3Price: value.pack3Price !== null ? +value.pack3Price : null,
        pack4Price: value.pack4Price !== null ? +value.pack4Price : null,
        pack5Price: value.pack5Price !== null ? +value.pack5Price : null,
        pack6Price: value.pack6Price !== null ? +value.pack6Price : null,
        pack7Price: value.pack7Price !== null ? +value.pack7Price : null,
        pack8Price: value.pack8Price !== null ? +value.pack8Price : null,
        pack9Price: value.pack9Price !== null ? +value.pack9Price : null,
        pack1Id: assertPackIdIsNumber(value.pack1Id, value.patck1Name),
        pack2Id: assertPackIdIsNumber(value.pack2Id, value.pack2Name),
        pack3Id: assertPackIdIsNumber(value.pack3Id, value.pack3Name),
        pack4Id: assertPackIdIsNumber(value.pack4Id, value.pack4Name),
        pack5Id: assertPackIdIsNumber(value.pack5Id, value.pack5Name),
        pAck6Id: assertPackIdIsNumber(value.pAck6Id, value.pack6Name),
        pAck7Id: assertPackIdIsNumber(value.pAck7Id, value.pack7Name),
        pack8Id: assertPackIdIsNumber(value.pack8Id, value.pack8Name),
        pack9Id: assertPackIdIsNumber(value.pack9Id, value.pAck9Name),
        pack10Id: assertPackIdIsNumber(value.pack10Id, value.pAck10Name),
        pack11Id: assertPackIdIsNumber(value.pack11Id, value.pack11Name),
        pack12Id: assertPackIdIsNumber(value.pack12Id, value.pack12Name),
        pack13Id: assertPackIdIsNumber(value.pack13Id, value.pAck13Name),
        pack14Id: assertPackIdIsNumber(value.pack14Id, value.pAck14Name),
        pack15Id: assertPackIdIsNumber(value.pack15Id, value.pack15Name),
        pack16Id: assertPackIdIsNumber(value.pack16Id, value.pack16Name),
        pack17Id: assertPackIdIsNumber(value.pack17Id, value.pack17Name),
        pack18Id: assertPackIdIsNumber(value.pack18Id, value.pack18Name),
        pack19Id: assertPackIdIsNumber(value.pack19Id, value.pack19Name),
        pack20Id: assertPackIdIsNumber(value.pack20Id, value.pack20Name),
        pack21Id: assertPackIdIsNumber(value.pack21Id, value.pack21Name),
        pack22Id: assertPackIdIsNumber(value.pack22Id, value.pack22Name),
        pack23Id: assertPackIdIsNumber(value.pack23Id, value.pack23Name),
        pack24Id: assertPackIdIsNumber(value.pack24Id, value.pack24Name),
        pack25Id: assertPackIdIsNumber(value.pack25Id, value.pack25Name),
        carton1Id: assertPackIdIsNumber(value.carton1Id, value.carton1Name),
        carton2Id: assertPackIdIsNumber(value.carton2Id, value.carton2Name),
        carton3Id: assertPackIdIsNumber(value.carton3Id, value.carton3Name),
        carton4Id: assertPackIdIsNumber(value.carton4Id, value.carton4Name),
        carton5Id: assertPackIdIsNumber(value.carton5Id, value.carton5Name),
        carton6Id: assertPackIdIsNumber(value.carton6Id, value.carton6Name),
        carton7Id: assertPackIdIsNumber(value.carton7Id, value.carton7Name),
        carton8Id: assertPackIdIsNumber(value.carton8Id, value.carton8Name),
        carton9Id: assertPackIdIsNumber(value.carton9Id, value.carton9Name),
        carton10Id: assertPackIdIsNumber(value.carton10Id, value.carton10Name),
        carton11Id: assertPackIdIsNumber(value.carton11Id, value.carton11Name),
        carton12Id: assertPackIdIsNumber(value.carton12Id, value.carton12Name),
        carton13Id: assertPackIdIsNumber(value.carton13Id, value.carton13Name),
        carton14Id: assertPackIdIsNumber(value.carton14Id, value.carton14Name),
        carton15Id: assertPackIdIsNumber(value.carton15Id, value.carton15Name),
        carton16Id: assertPackIdIsNumber(value.carton16Id, value.carton16Name),
        carton17Id: assertPackIdIsNumber(value.carton17Id, value.carton17Name),
        carton18Id: assertPackIdIsNumber(value.carton18Id, value.carton18Name),
        carton19Id: assertPackIdIsNumber(value.carton19Id, value.carton19Name),
        carton20Id: assertPackIdIsNumber(value.carton20Id, value.carton20Name),
        carton21Id: assertPackIdIsNumber(value.carton21Id, value.carton21Name),
        carton22Id: assertPackIdIsNumber(value.carton22Id, value.carton22Name),
        carton23Id: assertPackIdIsNumber(value.carton23Id, value.carton23Name),
        carton24Id: assertPackIdIsNumber(value.carton24Id, value.carton24Name),
      });
    } catch (e) {
      alert(getErrorMessage(e));
      return;
    }
    navigate('/admin');
  }

  if (!cigPrices) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <CigPriceSetupForm
        value={cigPrices}
        onChange={setCigPrices}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default withAuth(styled(ChangeCigPrices)``);
