const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export default function getMonthName(date: Date | string) {
  date = date instanceof Date ? date : new Date(date.replace('Z', ''));
  return monthNames[date.getMonth()];
}
