/**
 * Takes an array, a predicate function, and a replace function.
 * Returns a copy of the input array with all values that pass the predicate passed through the replace function.
 *
 * Example:
 *
 * ```ts
 * const result = replaceWhere(
 *   [1, 2, 3, 4, 5],
 *   n => n >= 3,
 *   n => n * 10,
 * );
 * ```
 *
 * `result` in the example above is `[1, 2, 30, 40, 50]`
 */
export default function replaceWhere<T>(
  array: T[],
  predicate: (value: T) => boolean,
  replace: (value: T) => T,
): T[] {
  return array.map(value => (predicate(value) ? replace(value) : value));
}
