import { AxiosInstance } from 'axios';
import GuestPlay from '../models/GuestPlay';

export async function createGuestPlay(
  axios: AxiosInstance,
): Promise<GuestPlay> {
  const response = await axios.post('/guestPlay');
  return response.data.guestPlay;
}

export async function updateGuestPlay(
  axios: AxiosInstance,
  guestPlay: GuestPlay,
): Promise<void> {
  await axios.put('/guestPlay', { guestPlay });
}
