import React from 'react';
import styled from 'styled-components';
import Api from '../api/Api';
import Page, { PageComponent } from '../components/Page';
import ShiftForm from '../components/ShiftForm';
import withAuth from '../components/withAuth';

const AuditShift: PageComponent<{
  shiftDate: string;
  shiftNumber: string;
}> = props => {
  const { shiftDate, user } = props;
  const shiftNumber = parseInt(props.shiftNumber, 10);

  return (
    <Page {...props}>
      <ShiftForm
        shiftDate={shiftDate}
        shiftNumber={shiftNumber}
        onDelete={async () => {
          await Api.deleteShift(shiftDate, shiftNumber);
        }}
        user={user}
      />
    </Page>
  );
};

export default withAuth(styled(AuditShift)``);
