import { AxiosInstance } from 'axios';

export interface BluOverShortEntry {
  storeId: number;
  shiftDate: string;
  sumOfShift: number;
  sumOfBeginningCigInventory: number;
  sumOfTransfercigs: number;
  sumOfInnerCompanyTransfers: number;
  sumOfPpack: number;
  sumOfMpack: number;
  sumOfGpack: number;
  sumOfPcarton: number;
  sumOfMcarton: number;
  sumOfGarton: number;
  sumOfCigaretteOverrings: number;
  sumOfEndingCigInventory: number;
  sumOfCigaretteOR: number;
  expr1: number;
  expr2: number;
  sumOfElitePack: number;
  sumOfEliteCarton: number;
  sumOfSnusPack: number;
  sumOfBluPack: number;
  sumOfBeginningBluInventory: number;
  sumOfEndingBluInventory: number;
  sumOfBluPurchases: number;
  expr3: number;
  expr4: number;
  sumOfBluReturns: number;
}

export async function getBluOverShortData(
  axios: AxiosInstance,
  beginningDate: string,
  endingDate: string,
): Promise<BluOverShortEntry[]> {
  beginningDate =
    new Date(beginningDate).toISOString().slice(0, 10) + 'T00:00:00.000Z';
  endingDate =
    new Date(endingDate).toISOString().slice(0, 10) + 'T00:00:00.000Z';
  const response = await axios.get(
    `/bluOverShort/${beginningDate}/${endingDate}`,
  );

  return response.data.bluOverShortData;
}
