import { AxiosInstance } from 'axios';
import VideoDepositLog from '../models/VideoDepositLog';

/**
 * Create and return a new video deposit log.
 */
export async function createVideoDepositLog(
  axios: AxiosInstance,
  videoDepositLog: Partial<VideoDepositLog>,
): Promise<VideoDepositLog> {
  const { storeId } = videoDepositLog;
  const response = await axios.post(
    `/store/${storeId}/video-deposit-log`,
    videoDepositLog,
  );
  return response.data.videoDepositLog;
}

/**
 * Create and return a new video deposit log RM.
 */
export async function createVideoDepositLogRm(
  axios: AxiosInstance,
  videoDepositLog: Partial<VideoDepositLog>,
): Promise<VideoDepositLog> {
  const response = await axios.post(`/video-deposit-log-rm`, videoDepositLog);
  return response.data.videoDepositLog;
}

/**
 * Get a video deposit log by id.
 */
export async function getVideoDepositLog(
  axios: AxiosInstance,
  props: { videoDepositLogId: number; storeId: number },
): Promise<VideoDepositLog | null> {
  const response = await axios.get(
    `/store/${props.storeId}/video-deposit-log/${props.videoDepositLogId}`,
  );
  return response.data.videoDepositLog || null;
}

export async function patchVideoDepositLog(
  axios: AxiosInstance,
  videoDepositLogId: number,
  fields: Partial<VideoDepositLog>,
) {
  const { storeId } = fields;
  await axios.put(
    `/store/${storeId}/video-deposit-log/${videoDepositLogId}`,
    fields,
  );
}

export async function getPriorVideoDepositLog(
  axios: AxiosInstance,
  props: { storeId: number; date: string; videoDepositLogId?: number },
): Promise<VideoDepositLog | null> {
  const { storeId, date, videoDepositLogId } = props;
  const response = await axios.post(
    `/store/${storeId}/video-deposit-logs/prior/${videoDepositLogId || ''}`,
    { date },
  );
  return response.data.videoDepositLog;
}

export async function getReviewableVideoDepositLogs(
  axios: AxiosInstance,
  storeId: number,
): Promise<VideoDepositLog[]> {
  const response = await axios.get(
    `/store/${storeId}/video-deposit-logs/reviewable`,
  );
  return response.data.videoDepositLogs;
}

export async function getVideoDepositLogRms(
  axios: AxiosInstance,
): Promise<VideoDepositLog[]> {
  const response = await axios.get('/video-deposit-logs/rm');
  return response.data.videoDepositLogs;
}

export async function updateVideoDepositLogRm(
  axios: AxiosInstance,
  videoDepositLog: VideoDepositLog,
): Promise<VideoDepositLog> {
  const response = await axios.put(
    `/video-deposit-log-rm/${videoDepositLog.id}`,
    videoDepositLog,
  );
  return response.data.videoDepositLog;
}

export async function countVideoDepositLogs(
  axios: AxiosInstance,
): Promise<number> {
  const response = await axios.get('/video-deposit-log-count');
  return response.data.count;
}

export async function getNextVideoDepositLog(
  axios: AxiosInstance,
  vdlId: number,
): Promise<number | null> {
  const response = await axios.get(`/video-deposit-log/${vdlId}/next`);
  return response.data.vdlId;
}

export async function getPrevVideoDepositLog(
  axios: AxiosInstance,
  vdlId: number,
): Promise<number | null> {
  const response = await axios.get(`/video-deposit-log/${vdlId}/previous`);
  return response.data.vdlId;
}
