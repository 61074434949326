import React, { useState, useMemo } from 'react';
import { FaPrint } from 'react-icons/fa';
import styled, { css } from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import MoneyInput from './MoneyInput';
import NumberInput from './NumberInput';
import Input from './Input';
import InputRow from './InputRow';
import BlockButton from './BlockButton';
import TextArea from './TextArea';
import Select from './Select';
import Checkbox from './Checkbox';
import FormBox from './FormBox';
import {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from './../models/ShiftMaster';
import Modal from './Modal';
import GeneralShiftEntriesForm from './GeneralShiftEntriesForm';
import CigSalesForm from './CigSalesForm';
import CashRegisterReportForm from './CashRegisterReportForm';
import LotteryReportsForm from './LotteryReportsForm';
import ScratchTicket2Form from './ScratchTicket2Form';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import useSearchParams from '../utils/useSearchParams';
import { navigate } from '@reach/router';
import Store from '../models/Store';
import Employees from '../models/Employees';
import formatISODate from '../utils/formatISODate';
import MoneyText from './MoneyText';
import usePrintLayout from '../utils/usePrintLayout';
import isOrStore from '../utils/isOrStore';
import canEditShift from '../utils/canEditShift';
import singleDecimal from '../utils/singleDecimal';
import useIdleTimeout from '../utils/useIdleTimeout';
import isSuperAdmin from '../utils/isSuperAdmin';
import { isElmers } from '../utils/isElmers';
import { hideable, HideableProps, hideByDisplayNone } from '../utils/hideable';

enum ModalType {
  GeneralShiftEntriesModal,
  CigSalesModal,
  CashRegisterReportModal,
  LotteryReportModal,
  ScratchTicketModal,
}

export interface Props {
  user?: Employees;
  shiftDate: string;
  shiftNumber: number;
  autoShowAuditShift?: boolean;
  onDelete?(): unknown;
}

const ShiftForm: React.FC<Props> = (props: Props) => {
  useIdleTimeout(() => close(false));
  usePrintLayout('portrait');
  const [openModals, setOpenModals] = useState<ModalType[]>([]);
  const [user, setUser] = useState<Employees | null>(null);
  const { shiftDate, shiftNumber, onDelete, ...rest } = props;
  const [value, setValue] = useState<ShiftMasterDraft | null>(null);
  const [stores, setStores] = useState<Store[]>([]);
  const [readOnly, setReadOnly] = useState(true);
  const { backRoute } = useSearchParams('backRoute');
  const elmers = !!user && isElmers(user.storeId);

  const storeOptions = useMemo(
    () =>
      stores.map(store => (
        <option key={store.storeId} value={store.storeId}>
          {store.storeName}
        </option>
      )),
    [stores],
  );

  useAsyncEffect(load, [shiftDate, shiftNumber]);

  if (!value) {
    // Loading...
    return null;
  }

  async function load() {
    const [fetchedUser, fetchedStores, fetchedShiftMaster] = await Promise.all([
      Api.getUser(),
      Api.getStores(),
      Api.getShift(shiftDate, shiftNumber),
    ]);

    if (!fetchedShiftMaster) {
      window.alert('Failed to find shift master.');
      window.history.back();
      return;
    }

    setUser(fetchedUser);
    setValue(fetchedShiftMaster);
    setStores((fetchedStores || []).filter(isOrStore));

    if (fetchedUser) {
      setReadOnly(
        !(await canEditShift(fetchedUser, {
          shiftDate: fetchedShiftMaster.shiftDate,
          shiftNumber: fetchedShiftMaster.shift,
          storeId: fetchedShiftMaster.storeId,
        })),
      );
    }
  }

  async function save(shiftMasterDraft: ShiftMasterDraft) {
    if (readOnly) {
      return;
    }
    const shiftMaster = shiftMasterFromDraft(shiftMasterDraft);

    const beginningCash =
      (shiftMaster.beginningTill || 0) +
      (shiftMaster.beginningChange || 0) +
      (shiftMaster.beginningPokerBank || 0);

    const endingCash =
      (shiftMaster.endingTill || 0) +
      (shiftMaster.endingChange || 0) +
      (shiftMaster.endingPokerBank || 0);

    const totalIn =
      (shiftMaster.food || 0) +
      (shiftMaster.nonAlcohol || 0) +
      (shiftMaster.alcoholBeverages || 0) +
      (shiftMaster.tobaccoPack || 0) +
      (shiftMaster.tobaccoCarton || 0) +
      (shiftMaster.tobaccoBlu || 0) +
      (shiftMaster.totalVpIn || 0) +
      (shiftMaster.scratchTickets || 0) +
      (shiftMaster.onLineSales || 0) +
      (shiftMaster.winnerChecks || 0) +
      (shiftMaster.other || 0) +
      (shiftMaster.cigarillo || 0) +
      (shiftMaster.changeIn || 0) +
      (shiftMaster.cigaretteDiscountOverring || 0) +
      (shiftMaster.promotion || 0) +
      (shiftMaster.vlmCashIn || 0);

    const increaseBanks = endingCash - beginningCash;

    const totalOut =
      (shiftMaster.employeeMeals || 0) +
      (shiftMaster.vpValidation || 0) +
      (shiftMaster.onLinePrizes || 0) +
      (shiftMaster.scratchOffValidation || 0) +
      (shiftMaster.cigaretteDiscount || 0) +
      (shiftMaster.espressoDiscount || 0) +
      (shiftMaster.foodDiscount || 0) +
      (shiftMaster.lotteryCoupons || 0) +
      (shiftMaster.payouts || 0) +
      (shiftMaster.overrings || 0) +
      (increaseBanks || 0) +
      (shiftMaster.nonPrizeChecks || 0) +
      (shiftMaster.guestRewardPayout || 0) +
      (shiftMaster.changeOut || 0) +
      (shiftMaster.cashDrop || 0) +
      (shiftMaster.jamBillsOut || 0) +
      (shiftMaster.cigaretteOverrings || 0);

    const fundsNeeded = totalIn - totalOut;
    const totalDeposit =
      (shiftMaster.cashDeposit || 0) +
      (shiftMaster.creditCardSlips || 0) +
      (shiftMaster.cashDepositB || 0) +
      (shiftMaster.cashDepositC || 0) +
      (shiftMaster.cashDepositD || 0) +
      (shiftMaster.atmRefill || 0);

    const discrepancyOverShort =
      shiftMaster.discrepencyOver - shiftMaster.discrepencyShort;
    const overShort = totalDeposit - fundsNeeded + discrepancyOverShort;

    const innercompanytransfers =
      (shiftMaster.innercompanyxfer1 || 0) +
      (shiftMaster.innercompanyxfer2 || 0) +
      (shiftMaster.innercompanyxfer3 || 0);

    const transfercigs =
      (shiftMaster.cigPurchases || 0) + (shiftMaster.cigReturns || 0);

    await Api.addOrUpdateShiftMaster({
      ...shiftMaster,
      endingCash,
      totalIn,
      increaseBanks,
      totalOut,
      fundsNeeded,
      totalDeposit,
      overShort,
      innercompanytransfers,
      transfercigs,
    });
    await load();
  }

  function updateValue<K extends keyof ShiftMasterDraft>(
    key: K,
    newValue: ShiftMasterDraft[K],
  ) {
    if (!value) {
      return;
    }
    setValue({
      ...value,
      [key]: newValue,
    });
  }

  function isModalOpen(modalType: ModalType): boolean {
    return openModals.includes(modalType);
  }

  function closeModal(modalType: ModalType): void {
    setOpenModals(openModals.filter(otherType => otherType !== modalType));
  }

  function openModal(modalType: ModalType): void {
    if (!isModalOpen(modalType)) {
      setOpenModals([...openModals, modalType]);
    }
  }

  function handleScratchOffClicked() {
    openModal(ModalType.ScratchTicketModal);
  }

  function handleGeneralEntriesClicked() {
    openModal(ModalType.GeneralShiftEntriesModal);
  }

  function handleTobaccoSalesClicked() {
    openModal(ModalType.CigSalesModal);
  }

  function handleEnterLotteryReportsClicked() {
    openModal(ModalType.LotteryReportModal);
  }

  function handleCashRegisterReportClicked() {
    openModal(ModalType.CashRegisterReportModal);
  }

  function handleCalculateDepositClick() {
    if (value) {
      save(value);
    }
  }

  function close(shouldSave: boolean) {
    if (shouldSave && value) {
      save(value);
    }
    navigate(backRoute || '/');
  }

  return (
    <>
      {isModalOpen(ModalType.GeneralShiftEntriesModal) && (
        <Modal>
          <GeneralShiftEntriesForm
            readOnly={readOnly}
            value={value}
            onSubmit={newShiftMaster => {
              save(newShiftMaster);
              closeModal(ModalType.GeneralShiftEntriesModal);
            }}
            onCancel={() => closeModal(ModalType.GeneralShiftEntriesModal)}
            isElmers={elmers}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.CigSalesModal) && (
        <Modal noScroll>
          <CigSalesForm
            readOnly={readOnly}
            storeId={+value.storeId}
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            onSubmit={async result => {
              save({
                ...value,
                epack: result.sumOfPacks,
                ecarton: result.sumOfCartons,
                blupack: result.blupack,
                tobaccoPack: result.tobaccoPack,
                tobaccoCarton: result.tobaccoCarton,
                tobaccoBlu: result.tobaccoBlu,
              });
              closeModal(ModalType.CigSalesModal);
            }}
            onCancel={() => closeModal(ModalType.CigSalesModal)}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.CashRegisterReportModal) && (
        <Modal noScroll>
          <CashRegisterReportForm
            readOnly={readOnly}
            value={value}
            onSubmit={shiftMaster => {
              save(shiftMaster);
              closeModal(ModalType.CashRegisterReportModal);
            }}
            onCancel={() => closeModal(ModalType.CashRegisterReportModal)}
            isElmers={elmers}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.LotteryReportModal) && (
        <Modal noScroll>
          <LotteryReportsForm
            readOnly={readOnly}
            storeId={+value.storeId}
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            shiftMaster={value}
            onSubmit={async (itsReport, shiftMaster) => {
              if (!readOnly) {
                await Api.addOrUpdateLotteryReportData(itsReport);
                await save(shiftMaster);
              }
              closeModal(ModalType.LotteryReportModal);
            }}
            onCancel={() => closeModal(ModalType.LotteryReportModal)}
            isElmers={elmers}
          />
        </Modal>
      )}
      {isModalOpen(ModalType.ScratchTicketModal) && (
        <Modal noScroll>
          <ScratchTicket2Form
            readOnly={readOnly}
            shiftDate={shiftDate}
            shiftNumber={shiftNumber}
            onSubmit={async shiftScratchTickets => {
              if (!readOnly) {
                await Api.updateShiftScratchTicketDetails(
                  shiftDate,
                  shiftNumber,
                  shiftScratchTickets,
                );
                await save({
                  ...value,
                  scratchTickets: shiftScratchTickets.reduce(
                    (total, ticket) => {
                      return (
                        total + (ticket.value || 0) * (ticket.amountSold || 0)
                      );
                    },
                    0,
                  ),
                });
              }
              closeModal(ModalType.ScratchTicketModal);
            }}
            onCancel={() => closeModal(ModalType.ScratchTicketModal)}
          />
        </Modal>
      )}
      <FormBox {...rest} title="Shift" onCloseClick={() => close(true)}>
        <ShiftInfoHeader>
          <Column>
            <div>Date</div>
            <ShiftInfoData>{formatISODate(value.shiftDate)}</ShiftInfoData>
          </Column>
          <Column>
            <div>Shift</div>
            <ShiftInfoData>{value.shift}</ShiftInfoData>
          </Column>
          <Column>
            <div>Store ID</div>
            <ShiftInfoData>{value.storeId}</ShiftInfoData>
          </Column>
          <Column>
            <div>First Name</div>
            <ShiftInfoData>{value.firstName}</ShiftInfoData>
          </Column>
          <Column>
            <div>Last Name</div>
            <ShiftInfoData>{value.lastName}</ShiftInfoData>
          </Column>
        </ShiftInfoHeader>
        <TopColumns>
          <TopColumn>
            <AccountHeader>
              <b>ACCOUNT</b>
            </AccountHeader>
            <InputRow hide={elmers}>
              <EntryLabel>Food</EntryLabel>
              <MoneyText amount={value.food || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Alcohol Bev.</EntryLabel>
              <MoneyText amount={value.alcoholBeverages || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Tobacco - pks</EntryLabel>
              <MoneyText amount={value.tobaccoPack || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Tobacco - carton</EntryLabel>
              <MoneyText amount={value.tobaccoCarton || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Tobacco - Blu</EntryLabel>
              <MoneyText amount={value.tobaccoBlu || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Chew</EntryLabel>
              <MoneyText amount={value.nonAlcohol || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Cig. Discount Overring:</EntryLabel>
              <MoneyText amount={value.cigaretteDiscountOverring || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Other Cash In</EntryLabel>
              <MoneyText amount={value.other || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>VLM Cash In</EntryLabel>
              <MoneyText amount={value.vlmCashIn || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Cigarillo</EntryLabel>
              <MoneyText amount={value.cigarillo || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Scratch-Off Tickets</EntryLabel>
              <MoneyText amount={value.scratchTickets || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>On-Line Sales</EntryLabel>
              <MoneyText amount={value.onLineSales || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Debit Fee</EntryLabel>
              <MoneyText amount={value.promotion || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Change In</EntryLabel>
              <MoneyText amount={value.changeIn || 0} />
            </InputRow>
            <TotalRow>
              <label>
                <b>Total In</b>
              </label>
              <MoneyText amount={value.totalIn || 0} />
            </TotalRow>
          </TopColumn>
          <CenterColumn>
            <VpIn>
              <b>VP In</b>
              <VPInput readOnly value={value.vpSales || 0} />
            </VpIn>
            <TopColumnButton
              onClick={handleCashRegisterReportClicked}
              hide={elmers}
            >
              Enter Cash Register Report
            </TopColumnButton>
            <TopColumnButton onClick={handleTobaccoSalesClicked} hide={elmers}>
              Enter Tobacco Sales
            </TopColumnButton>
            <GeneralEntriesButton onClick={handleGeneralEntriesClicked}>
              Enter General Entries
            </GeneralEntriesButton>
            <TopColumnButton onClick={handleScratchOffClicked} hide={elmers}>
              Enter Scratch Off
            </TopColumnButton>
            <EnterLotteryReportsButton
              onClick={handleEnterLotteryReportsClicked}
            >
              Enter Lottery Reports
            </EnterLotteryReportsButton>
            <CalcDepositButton onClick={handleCalculateDepositClick}>
              Calculate Deposit and Over/Short
            </CalcDepositButton>
            <TotalRow>
              <label>
                <b>Over/Short</b>
              </label>
              <MoneyText amount={value.overShort || 0} />
            </TotalRow>
          </CenterColumn>
          <TopColumn>
            <AccountHeader>
              <b>ACCOUNT</b>
            </AccountHeader>
            <InputRow hide={elmers}>
              <EntryLabel>Cig Disc/Coupon</EntryLabel>
              <MoneyText
                amount={
                  value.cigaretteDiscount === null
                    ? 0
                    : +value.cigaretteDiscount
                }
              />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Mobile Coupon</EntryLabel>
              <MoneyText
                amount={value.foodDiscount === null ? 0 : +value.foodDiscount}
              />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Employee Meals</EntryLabel>
              <MoneyText amount={value.employeeMeals || 0} />
            </InputRow>
            <GuestRewards hide={elmers}>
              <EntryLabel>Guest Rewards Payout</EntryLabel>
              <MoneyText amount={value.guestRewardPayout} />
            </GuestRewards>
            <InputRow hide={elmers}>
              <EntryLabel>Lottery Coupons</EntryLabel>
              <MoneyText amount={value.lotteryCoupons || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Cig Overring</EntryLabel>
              <MoneyText amount={value.cigaretteOverrings || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Overrings</EntryLabel>
              <MoneyText amount={value.overrings || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Cash Drop</EntryLabel>
              <MoneyText amount={value.cashDrop || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>{elmers ? 'Other Cash Out' : 'Payouts'}</EntryLabel>
              <MoneyText amount={value.payouts || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Jam Bills Out</EntryLabel>
              <MoneyText amount={value.jamBillsOut || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Non-Prize Checks</EntryLabel>
              <MoneyText amount={value.nonPrizeChecks || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>S-Off Validation</EntryLabel>
              <MoneyText amount={value.scratchOffValidation || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>Cash (on-line)</EntryLabel>
              <MoneyText amount={value.onLinePrizes || 0} />
            </InputRow>
            <InputRow>
              <EntryLabel>VP Validation</EntryLabel>
              <MoneyText amount={value.vpValidation || 0} />
            </InputRow>
            <InputRow hide={elmers}>
              <EntryLabel>Change Out</EntryLabel>
              <MoneyText amount={value.changeOut || 0} />
            </InputRow>
            <TotalRow>
              <label>
                <b>Total Out</b>
              </label>
              <MoneyText amount={value.totalOut || 0} />
            </TotalRow>
          </TopColumn>
          <TopColumn>
            <TextAreaColumn hide={elmers}>
              <label>Guest Rewards Comments</label>
              <TextArea
                readOnly
                value={value.guestRewardPayoutComments || ''}
              />
            </TextAreaColumn>
            <TextAreaColumn hide={elmers}>
              <label>Jam Bill Comments</label>
              <TextArea readOnly value={value.jamBillComments || ''} />
            </TextAreaColumn>
            <TextAreaColumn hide={elmers}>
              <label>Payout Comments</label>
              <TextArea readOnly value={value.payoutsComments || ''} />
            </TextAreaColumn>
          </TopColumn>
        </TopColumns>
        <SectionDivider />
        <SlipSection>
          <ChecksAndDeposit>
            <WinnersChecks hide={elmers}>
              <label>Winners Checks</label>
              <MoneyText amount={value.winnerChecks || 0} />
            </WinnersChecks>

            <PullTabDeposit hide={elmers}>
              <label>Pull Tab Deposit</label>
              <MoneyText amount={value.cashDepositC || 0} />
            </PullTabDeposit>
          </ChecksAndDeposit>
          <SlipRow>
            <InputRow hide={elmers}>
              <label>ATM Slips</label>
              <MoneyText amount={value.atmRefill || 0} />
            </InputRow>

            <InputRow hide={elmers}>
              <label>Count of ATM Slips</label>
              <NumberInput readOnly value={value.atmCount || 0} />
            </InputRow>

            <InputRow hide={elmers}>
              <label>Credit Card Slips</label>
              <MoneyText amount={value.creditCardSlips || 0} />
            </InputRow>

            <InputRow hide={elmers}>
              <label>Count of Credit Card Slips</label>
              <NumberInput readOnly value={value.creditCardSlipCount || 0} />
            </InputRow>
          </SlipRow>
        </SlipSection>
        <SectionDivider />
        <TillSection>
          <TillRow elmers={elmers}>
            <label>Beginning Till:</label>
            <MoneyText amount={value.beginningTill || 0} />
            {!elmers && (
              <>
                <label>Beginning Change:</label>
                <MoneyText amount={value.beginningChange || 0} />
              </>
            )}
          </TillRow>
          <TillRow elmers={elmers}>
            <label>Ending Till:</label>
            <MoneyText amount={value.endingTill || 0} />
            {!elmers && (
              <>
                <label>Ending Change:</label>
                <MoneyText amount={value.endingChange || 0} />
              </>
            )}
          </TillRow>
        </TillSection>
        <SafeSection>
          <h2>Safe</h2>
          <SafeRow>
            <InputRow>
              <label>Cash Dispensed:</label>
              <MoneyText amount={value.beginningPokerBank || 0} />
            </InputRow>
            <InputRow>
              <label>Column Total:</label>
              <MoneyText amount={value.columnTotal || 0} />
            </InputRow>
            <InputRow>
              <label>Cassette Deposit:</label>
              <MoneyText amount={value.cassetteDeposit || 0} />
            </InputRow>
          </SafeRow>
          <SafeRow style={{ marginTop: 8, justifyContent: 'flex-start' }}>
            <InputRow style={{ maxWidth: 300 }}>
              <label>Tube Discrepency (Over)</label>
              <MoneyInput
                readOnly
                disabled
                value={value.discrepencyOver || 0}
                backgroundColor={
                  value.discrepencyOver && +value.discrepencyOver !== 0
                    ? 'rgb(255, 229, 0)'
                    : undefined
                }
                style={{ maxWidth: 100 }}
              />
            </InputRow>
            <InputRow style={{ maxWidth: 600, justifyContent: 'flex-start' }}>
              <label style={{ flex: '0 0 auto', marginRight: 8 }}>
                Tube Discrepency Comments:
              </label>
              <Input readOnly disabled value={value.discrepencyComments} />
            </InputRow>
          </SafeRow>
          <SafeRow style={{ marginTop: 8, justifyContent: 'flex-start' }}>
            <InputRow style={{ maxWidth: 300 }}>
              <label>Tube Discrepency (Short)</label>
              <MoneyInput
                readOnly
                disabled
                value={value.discrepencyShort || 0}
                backgroundColor={
                  value.discrepencyShort && +value.discrepencyShort !== 0
                    ? 'rgb(255, 229, 0)'
                    : undefined
                }
                style={{ maxWidth: 100 }}
              />
            </InputRow>
          </SafeRow>
        </SafeSection>
        <AuditSection>
          <CommentRow>
            <label>Comments:</label>
            <Input
              readOnly={readOnly}
              value={value.comments || ''}
              onValueChange={comments => updateValue('comments', comments)}
              data-testid="commentsInput"
            />
          </CommentRow>
          <AuditColumns>
            <AuditColumn hide={elmers} takeSpaceWhenHidden>
              <InputRow>
                <label>Beginning Cig Inventory (Shift 1 only)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.beginningciginventory || ''}
                  onValueChange={beginningciginventory =>
                    updateValue('beginningciginventory', beginningciginventory)
                  }
                  data-testid="beginningCigInventoryInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Purchases (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.cigPurchases || ''}
                  onValueChange={cigPurchases =>
                    updateValue('cigPurchases', cigPurchases)
                  }
                  data-testid="purchasesInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Returns (all Shifts)</label>
                <Input
                  value={value.cigReturns || ''}
                  readOnly={readOnly}
                  onValueChange={cigReturns =>
                    updateValue('cigReturns', cigReturns)
                  }
                  data-testid="cigReturnsInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Inner Company Transfers (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.innercompanyxfer1 || ''}
                  onValueChange={innercompanyxfer1 =>
                    updateValue('innercompanyxfer1', innercompanyxfer1)
                  }
                  data-testid="innercompanyxfer1Input"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Inner Company Transfers (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.innercompanyxfer2 || ''}
                  onValueChange={innercompanyxfer2 =>
                    updateValue('innercompanyxfer2', innercompanyxfer2)
                  }
                  data-testid="innercompanyxfer2Input"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Inner Company Transfers (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.innercompanyxfer3 || ''}
                  onValueChange={innercompanyxfer3 =>
                    updateValue('innercompanyxfer3', innercompanyxfer3)
                  }
                  data-testid="innercompanyxfer3Input"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Ending Cig Inventory (Closing Shift only)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.endingciginventory || ''}
                  onValueChange={endingciginventory =>
                    updateValue('endingciginventory', endingciginventory)
                  }
                  data-testid="endingCigInventoryInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow>
                <label>Cigarette O/R (units):</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.cigaretteOr || ''}
                  onValueChange={cigaretteOr =>
                    updateValue('cigaretteOr', singleDecimal(cigaretteOr))
                  }
                  disabled={elmers}
                />
              </InputRow>
            </AuditColumn>
            <AuditColumn hide={elmers} takeSpaceWhenHidden>
              <Column>
                <label>Return Auth #</label>
                <Input
                  readOnly={readOnly}
                  value={value.innerCompanyTransferNotes}
                  onValueChange={innerCompanyTransferNotes =>
                    updateValue(
                      'innerCompanyTransferNotes',
                      innerCompanyTransferNotes,
                    )
                  }
                  data-testid="returnAuthNumberInput"
                  disabled={elmers}
                />
              </Column>

              <TransfersColumn>
                <label>Transferred From/ Transferred To</label>
                <Select
                  readOnly={readOnly}
                  value={value.innercompanyxferto1 || ''}
                  onValueChange={innercompanyxferto1 =>
                    updateValue('innercompanyxferto1', innercompanyxferto1)
                  }
                  data-testid="innercompanyxferto1Input"
                  disabled={elmers}
                >
                  <option value={0} />
                  {storeOptions}
                </Select>
                <Select
                  readOnly={readOnly}
                  value={value.innercompanyxferto2 || ''}
                  onValueChange={innercompanyxferto2 =>
                    updateValue('innercompanyxferto2', innercompanyxferto2)
                  }
                  data-testid="innercompanyxferto2Input"
                  disabled={elmers}
                >
                  <option value={0} />
                  {storeOptions}
                </Select>
                <Select
                  readOnly={readOnly}
                  value={value.innercompanyxferto3 || ''}
                  onValueChange={innercompanyxferto3 =>
                    updateValue('innercompanyxferto3', innercompanyxferto3)
                  }
                  data-testid="innercompanyxferto3Input"
                  disabled={elmers}
                >
                  <option value={0} />
                  {storeOptions}
                </Select>
              </TransfersColumn>

              <label>Transfer Notes</label>
              <TextArea
                readOnly={readOnly}
                value={value.innerCompanyTransferNotes || ''}
                onValueChange={innerCompanyTransferNotes =>
                  updateValue(
                    'innerCompanyTransferNotes',
                    innerCompanyTransferNotes,
                  )
                }
                data-testid="innerCompanyTransferNotesInput"
                disabled={elmers}
              />
            </AuditColumn>
            <AuditColumn>
              <InputRow hide={elmers} takeSpaceWhenHidden>
                <label>Beginning Blu Inventory (Shift 1 only)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.beginningBluInventory || ''}
                  onValueChange={beginningBluInventory =>
                    updateValue('beginningBluInventory', beginningBluInventory)
                  }
                  data-testid="beginningBluInventoryInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow hide={elmers} takeSpaceWhenHidden>
                <label>Ending Blu inventory (Closing Shift only)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.endingBluInventory || ''}
                  onValueChange={endingBluInventory =>
                    updateValue('endingBluInventory', endingBluInventory)
                  }
                  data-testid="endingBluInventoryInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow hide={elmers} takeSpaceWhenHidden>
                <label>Blu Purchases (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.bluPurchases || ''}
                  onValueChange={bluPurchases =>
                    updateValue('bluPurchases', bluPurchases)
                  }
                  data-testid="bluPurchasesInput"
                  disabled={elmers}
                />
              </InputRow>
              <InputRow hide={elmers} takeSpaceWhenHidden>
                <label>Blu Returns (all Shifts)</label>
                <Input
                  type="number"
                  readOnly={readOnly}
                  value={value.bluReturns || ''}
                  onValueChange={bluReturns =>
                    updateValue('bluReturns', bluReturns)
                  }
                  data-testid="bluReturnsInput"
                  disabled={elmers}
                />
              </InputRow>
              <PrintRow>
                <label>Print Shift</label>
                <PrintButton
                  onClick={() => {
                    window.scrollTo({ top: 0 });
                    window.print();
                  }}
                >
                  <FaPrint />
                </PrintButton>
              </PrintRow>
              <CheckboxRow>
                <Column>
                  <SignoffLabel>Employee Signoff</SignoffLabel>
                  <Checkbox
                    readOnly={readOnly}
                    checked={!!value.employeeSignoff}
                    onValueChange={async checked => {
                      if (checked) {
                        const confirmed = window.confirm(
                          `${(user && user.firstName) || ''} ${(user &&
                            user.lastName) ||
                            ''}, are you sure you want to sign off? Did you remember to PRINT your Shift?`,
                        );
                        if (confirmed) {
                          await save({
                            ...value,
                            employeeSignoff: 1,
                          });
                          await Api.logout();
                          navigate(`/login?store_id=${value.storeId}`);
                        }
                      }
                    }}
                    data-testid="employeeSignoffInput"
                  />
                </Column>
                <Column>
                  <SignoffLabel>Auditor Signoff</SignoffLabel>
                  <Checkbox
                    readOnly={readOnly}
                    checked={!!value.auditSignoff}
                    onValueChange={checked =>
                      updateValue('auditSignoff', checked ? 1 : 0)
                    }
                    data-testid="auditSignoffInput"
                  />
                </Column>
              </CheckboxRow>
            </AuditColumn>
          </AuditColumns>
        </AuditSection>
        <CancelRow>
          <BlockButton onClick={() => close(true)}>Save and Close</BlockButton>
          <BlockButton onClick={() => navigate(backRoute || '/')}>
            Cancel
          </BlockButton>
          {user && onDelete && isSuperAdmin(user) && (
            <BlockButton
              onClick={async () => {
                if (
                  window.confirm('Are you sure you want to delete this shift?')
                ) {
                  await onDelete();
                  await close(false);
                }
              }}
            >
              Delete
            </BlockButton>
          )}
        </CancelRow>
      </FormBox>
    </>
  );
};

export default styled(ShiftForm)`
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
  background: #fff;

  @media print {
    width: 100%;
    overflow: visible;
    button {
      display: none;
    }
  }

  ${MoneyInput}, ${NumberInput}, ${Input} {
    height: 25px;
    text-align: right;
    padding: 0;
    flex: 0 1 50%;
    width: auto;
    min-width: 65px;
    border-bottom: 2px solid #ddd;
    border-right: 2px solid #ddd;

    &:read-only {
      border: none;
    }
  }

  ${MoneyInput} > input {
    width: auto;
    min-width: auto;
  }

  ${InputRow} {
    justify-content: stretch;
  }

  ${InputRow} > label {
    flex: 1 0 50%;
    min-width: auto;
  }

  ${BlockButton} {
    height: 30px;
    min-height: 30px;
    line-height: 1;
  }

  & label {
    text-align: left;
  }
`;

const ShiftInfoData = styled('div')`
  text-align: right;
`;

const ShiftInfoHeader = styled(Row)`
  margin-bottom: 10px;
  > ${Column} + ${Column} {
    padding-left: 40px;
  }

  > ${Column}:nth-child(1) ${ShiftInfoData} {
    min-width: 90px;
  }

  > ${Column}:nth-child(2) ${ShiftInfoData} {
    min-width: 60px;
  }

  /* prettier-ignore */
  > ${Column}:nth-child(4) ${ShiftInfoData},
  > ${Column}:nth-child(5) ${ShiftInfoData} {
    text-align: left;
  }
`;

const TopColumns = styled(Row)`
  min-width: 940px;

  ${MoneyText} {
    width: 120px;
  }
`;

const TopColumn = styled(Column)`
  width: calc(25% - 7.5px);
  min-height: 560px;
  @media print {
    width: unset;
  }

  & + & {
    padding-left: 10px;
  }

  :nth-child(3) {
    flex-basis: 26%;
  }
  :nth-child(4) {
    padding-top: 25px;
    flex-basis: 24%;
  }

  & + & ${BlockButton} {
    width: 100%;
  }

  ${BlockButton} {
    width: 250px;
  }
`;

const CenterColumn = styled(TopColumn)`
  @media print {
    width: 200px;
  }
  padding: 0 5px;
`;

const TopColumnButton = styled(BlockButton)`
  margin-bottom: 20px;
`;

const GeneralEntriesButton = styled(TopColumnButton)`
  margin-bottom: 120px;
`;

const EnterLotteryReportsButton = styled(TopColumnButton)`
  margin-bottom: 30px;
`;

const CalcDepositButton = styled(TopColumnButton)`
  && {
    min-height: 50px;
  }
`;

const TextAreaColumn = styled(Column)<{ hide?: boolean }>`
  label {
    text-align: center;
    margin-bottom: 3px;
  }

  & + & {
    margin-top: 20px;
  }

  textarea {
    min-height: 110px;
  }
  ${hideByDisplayNone}
`;

const SlipSection = styled(Column)`
  max-width: 100%;
  min-width: 800px;
`;

const SlipRow = styled(Row)`
  justify-content: stretch;

  ${InputRow} {
    flex-shrink: 1;
    padding-bottom: 0;
    padding-right: 10px;

    :nth-child(1) {
      max-width: 190px;
      label {
        text-align: right;
        padding-right: 5px;
      }
    }

    :nth-child(2) {
      max-width: 230px;
      input {
        text-align: left;
      }
    }

    :nth-child(3) {
      max-width: 260px;
      label,
      input {
        text-align: left;
      }
    }

    :nth-child(4) {
      max-width: 260px;
      margin-left: auto;
      margin-right: 60px;
      input {
        text-align: left;
      }
    }

    > label {
      flex-basis: 50%;
    }
    > ${MoneyInput}, > ${NumberInput} {
      flex-basis: 30%;
    }
  }
`;

const ChecksAndDeposit = styled(Row)`
  padding-bottom: 5px;
`;

const PullTabDeposit = styled(Row)<{ hide?: boolean }>`
  text-align: center;
  align-items: center;
  justify-content: flex-end;
  width: 210px;
  label {
    text-align: left;
    padding-right: 10px;
    padding-left: 10px;
  }

  ${MoneyInput} {
    max-width: 80px;
  }
  ${hideByDisplayNone}
`;

const WinnersChecks = styled(InputRow)`
  text-align: left;
  padding-top: 10px;
  width: 280px;
  label {
    text-align: left;
    padding-right: 5px;
  }

  ${MoneyInput} {
    max-width: 80px;
  }
`;

const TillSection = styled(Column)`
  ${MoneyText} {
    width: 120px;
    margin-right: 10px;
  }
`;

const TillRow = styled(Row)<{ elmers: boolean }>`
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  width: ${({ elmers }) => (elmers ? 300 : 600)}px;
  max-width: 100%;
  label {
    width: 150px;
  }

  ${MoneyInput} + label {
    padding-left: 20px;
  }

  > label {
    flex-basis: 60%;
  }
  > ${MoneyInput}, > ${NumberInput} {
    flex-basis: 35%;
  }
`;

const VpIn = styled(InputRow)`
  width: 200px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
`;

const baseButtonStyle = css`
  background: #fff;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #ddd;
  border-right: 2px solid #ddd;

  ${NumberInput} {
    background: transparent;
  }
`;

const VPInput = styled(MoneyInput)`
  margin-left: 10px;

  &&:read-only {
    ${baseButtonStyle}
  }
`;

const GuestRewards = styled(InputRow)`
  margin-bottom: 40px;
  padding: 5px;
  padding-right: 0;
`;

const SafeSection = styled(Column)`
  margin-top: 10px;
  margin-bottom: 5px;
  border: 1px solid black;
  max-width: 100%;
  width: 1000px;
  padding: 0 40px;
  border-left: none;
  h2 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px;
    font-size: 20px;
    text-decoration: underline;
  }
`;

const SectionDivider = styled.hr`
  margin: 2px 0 2px 0;
  height: 1px;
  width: 95%;
`;

const SafeRow = styled(Row)`
  justify-content: space-evenly;
  max-width: 100%;

  ${InputRow} {
    flex: 1 1 33%;
  }
  label {
    padding-left: 10px;
  }
`;

const AuditSection = styled(Column)``;

const CommentRow = styled(Row)`
  justify-content: stretch;
  align-items: center;
  max-width: 80%;

  ${Input} {
    flex: 1 1 60%;
    margin-left: 10px;
    height: 25px;
  }

  label {
    width: 125px;
    flex-grow: 0;
  }
`;

const AuditColumn = styled(Column)<HideableProps>`
  min-height: 20px;
  justify-content: flex-start;
  align-items: stretch;

  
  ${InputRow} > label {
    text-align: right;
    padding-right: 15px;
    flex-grow: 1;
    flex-basis: 65%;
    min-width: 120px;
    text-align: right;
  }
  
  ${InputRow} > ${MoneyInput},
  ${InputRow} > ${NumberInput},
  ${InputRow} > ${Input} {
    flex-basis: 30%;
  }

  select {
    height: 25px;
    width: 225px;
  }

  textarea {
    width: 225px;
    height: 60px;
    padding: 0;
  }

  ${hideable}
`;

const CartonCountKey = styled(Row)`
  margin-bottom: 40px;
`;

const AuditColumns = styled(Row)`
  max-width: 100%;
  min-width:700px;

  /* prettier-ignore */
  ${AuditColumn}:nth-child(1) {
    margin-right: 15px;

    ${InputRow}:nth-child(1) {
      margin-bottom: 25px;
    }

    ${InputRow}:nth-child(3) {
      margin-bottom: 25px;
    }

    ${InputRow}:nth-child(6) {
      margin-bottom: 25px;
    }
    ${InputRow}:nth-child(7) {
      margin-bottom: 30px;
    }
  }
  /* prettier-ignore */
  ${AuditColumn}:nth-child(2) {
    padding-top: 55px;
    label {
      height: 25px;
    }

    input {
      width: 100%;
      flex-grow: 0;
    }
  }
  /* prettier-ignore */
  ${AuditColumn}:nth-child(3) {
    margin-left: -75px;

    ${InputRow} > label {
      width: 225px;
      white-space: nowrap;
      text-align: right;
    }
  }
`;

const TransfersColumn = styled(Column)`
  margin-top: 5px;
  margin-bottom: 10px;
  ${Select} + ${Select} {
    margin-top: 3px;
  }

  ${CartonCountKey} + & {
    width: 300px;
    flex-direction: row;
    margin-bottom: 40px;
  }
`;

const PrintRow = styled(Row)`
  align-items: center;
  justify-content: center;
  margin-left: 75px;
  margin-top: 75px;
  & > label {
    padding-right: 70px;
    text-align: center;
  }
  @media print {
    display: none;
  }
`;

const PrintButton = styled(BlockButton)`
  flex-direction: column;
  width: 60px;
  min-width: auto;
`;

const CheckboxRow = styled(Row)`
  margin-left: 75px;
  margin-top: 20px;
  justify-content: space-evenly;
  > ${Column} {
    align-items: center;
  }
`;

const CancelRow = styled(Row)`
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 275px 0 75px;
`;

const AccountHeader = styled(Row)`
  margin-bottom: 15px;
`;

const EntryLabel = styled.label`
  min-width: 150px;
`;

const SignoffLabel = styled.label`
  width: 125px !important;
`;

const TotalRow = styled(Row)`
  margin-top: auto;
  width: 100%;
  overflow: hidden;
  align-items: center;

  > label {
    flex: 1 0 90px;
  }

  > ${NumberInput}, > ${MoneyInput} {
    flex: 0 1 100px;
    width: auto;
    max-width: 150px;
    margin-left: auto;
  }

  > ${MoneyInput} input {
    width: auto;
    min-width: auto;
  }
`;
