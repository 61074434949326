import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import Over21TestForm from '../components/Over21TestForm';
import withAuth from '../components/withAuth';
import Api from '../api/Api';

const Over21Test: PageComponent = props => {
  async function handleScore(isCorrect: boolean) {
    await Api.createOver21Test({
      passFail: isCorrect ? 'Pass' : 'Fail',
      testDate: new Date(),
    });
  }

  return (
    <Page {...props}>
      <Over21TestForm onScore={handleScore} />
    </Page>
  );
};

export default styled(withAuth(Over21Test))`
  justify-content: center;
  align-items: center;
`;
