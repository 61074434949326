import React, { useState } from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import CreateShiftForm, {
  createShiftFormDefaultValue,
} from '../components/CreateShiftForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import requireUser from '../utils/requireUser';
import getErrorMessage from '../utils/getErrorMessage';
import isToday from '../utils/isToday';
import isYesterday from '../utils/isYesterday';
import isBlank from '../utils/isBlank';
import ShiftMaster from '../models/ShiftMaster';
import TotalDollarsPlayedForm from '../components/TotalDollarsPlayedForm';
import { isElmers } from '../utils/isElmers';

const CreateShift: PageComponent = props => {
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState(createShiftFormDefaultValue);
  const [tdpReviewShift, setTdpReviewShift] = useState<ShiftMaster | null>(
    null,
  );
  const user = requireUser(props);
  const elmers = isElmers(user.storeId);

  function handleCancelClick() {
    navigate('/');
  }

  async function submit() {
    try {
      if (
        !isToday(value.shiftDate) &&
        !isYesterday(value.shiftDate) &&
        !window.confirm('Did you mean to enter that date?')
      ) {
        return;
      }
      if (
        !value.shiftDate ||
        isBlank(value.shift) ||
        isBlank(value.beginningTill) ||
        // Not present on Elmers form
        (!elmers && isBlank(value.beginningChange)) ||
        isBlank(value.beginningPokerBank)
      ) {
        throw new Error(
          'You must fill in the Shift, Date and the Cash Totals!',
        );
      }

      const shiftNumber = +value.shift;

      // Check if the user needs to update the previous shift's TDP before they can continue.
      // This only happens during the creation of the first shift for the day.
      if (shiftNumber === 1) {
        // If the previous shift has no TDP set, show the TDP form for it before allowing this to be submitted.
        const {
          shiftMaster: previousShiftMaster,
          canAudit,
        } = await Api.getPreviousTdpShift({
          shiftDate: value.shiftDate,
        });

        if (
          previousShiftMaster &&
          canAudit &&
          // Note: 'total dollars played' uses the 'pull tab prizes' column 🤷
          !previousShiftMaster.pullTabPrizes
        ) {
          setTdpReviewShift(previousShiftMaster);
          return;
        }
      }

      setSubmitting(true);
      const result = await Api.createShift({
        shiftDate: value.shiftDate,
        shiftNumber: +value.shift,
        beginningTill: +value.beginningTill,
        beginningChange: +value.beginningChange,
        beginningPokerBank: +value.beginningPokerBank,
      });
      if (result) {
        const { shift, shiftDate } = result.shift;

        for (const warning of result.warnings) {
          window.alert(warning);
        }

        navigate(`/audit-shift/${shiftDate}/${shift}?backRoute=/`);
      }
    } catch (error) {
      alert(getErrorMessage(error));
    } finally {
      setSubmitting(false);
    }
  }

  if (tdpReviewShift) {
    return (
      <TotalDollarsPlayedForm
        closeOnSave
        shiftMaster={tdpReviewShift}
        onClose={success => {
          setTdpReviewShift(null);
          if (success) {
            submit();
          }
        }}
      />
    );
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <CreateShiftForm
          value={value}
          onChange={setValue}
          employeeId={user.employeeId}
          onSubmit={submit}
          onCancelClick={handleCancelClick}
          disabled={submitting}
          isElmers={elmers}
        />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(CreateShift))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)`
  max-width: 1000px;
`;
