import { AxiosError } from 'axios';

export default function getErrorMessage(error: unknown): string {
  const { response } = error as AxiosError;

  return (
    (response &&
      response.data &&
      response.data.error &&
      response.data.error.message) ||
    (error as Error).message ||
    'Unknown error.'
  );
}
