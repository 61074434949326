import { KeyboardEvent, useCallback } from 'react';
import { ArrowKeyCode } from '../constants/ArrowKeyCode';

const DISABLED_KEY_CODES = [ArrowKeyCode.UP, ArrowKeyCode.DOWN];

/**
 * Create a onKeyDown callback that disables using the up/down arrow keys.
 * @param enabled whether the disabling is enabled. Defaults to true.
 */
export function useArrowKeyDisable(enabled = true) {
  const onKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event;
    if (DISABLED_KEY_CODES.includes(keyCode)) {
      event.preventDefault();
    }
  }, []);

  return {
    onKeyDown,
  };
}
