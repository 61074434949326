import React from 'react';
import styled from 'styled-components/macro';
import Row from './Row';
import FormBox from './FormBox';
import SideBySideButton from './SideBySideButton';
import isSuperAdmin from '../utils/isSuperAdmin';
import Employees from '../models/Employees';
import { navigate } from '@reach/router';
import { isElmers } from '../utils/isElmers';

export interface MainSwitchboardFormProps {
  user?: Employees;
  onAdminClick?: () => any;
  onManagerClick?: () => any;
  onCreateShiftClick?: () => any;
  onBackToShiftEntryClick?: () => any;
  onBrandInventoryClick?: () => any;
  onOver21TestClick?: () => any;
  onGuestPlayClick?: () => any;
  onTotalDollarsPlayedClick?: () => any;
  onExitAppClick?: () => any;
  onCloseClick?: () => any;
}

const MainSwitchboardForm: React.FC<MainSwitchboardFormProps> = props => {
  const {
    user,
    onAdminClick,
    onManagerClick,
    onCreateShiftClick,
    onBackToShiftEntryClick,
    onBrandInventoryClick,
    onOver21TestClick,
    onGuestPlayClick,
    onTotalDollarsPlayedClick,
    onExitAppClick,
    onCloseClick,
    ...rest
  } = props;

  const elmers = user && isElmers(user.storeId);

  return (
    <FormBox
      {...rest}
      title="Main Switchboard: Form"
      onCloseClick={onCloseClick}
    >
      <Row>
        <SideBySideButton onClick={onAdminClick} data-testid="admin-link">
          Administration
        </SideBySideButton>
        <SideBySideButton onClick={onManagerClick} data-testid="manager-link">
          Manager/Regional
        </SideBySideButton>
      </Row>
      <Row>
        <SideBySideButton
          onClick={onCreateShiftClick}
          data-testid="create-shift-link"
        >
          Create Shift
        </SideBySideButton>
        <SideBySideButton
          onClick={onBackToShiftEntryClick}
          data-testid="back-to-shift-entry-link"
        >
          Back to Shift Entry
        </SideBySideButton>
      </Row>
      <Row>
        <SideBySideButton
          onClick={onBrandInventoryClick}
          data-testid="brand-inventory-link"
          hide={elmers}
        >
          By Brand Inventory
        </SideBySideButton>
        <SideBySideButton
          onClick={onTotalDollarsPlayedClick}
          data-testid="total-dollars-played-link"
        >
          Total Dollars Played
        </SideBySideButton>
      </Row>
      <Row style={{ display: 'none' }}>
        <SideBySideButton
          onClick={onOver21TestClick}
          data-testid="over-21-test-link"
        >
          Over 21 Test
        </SideBySideButton>
        <SideBySideButton
          onClick={onGuestPlayClick}
          data-testid="guest-play-link"
        >
          Guest Play
        </SideBySideButton>
      </Row>
      {user && isSuperAdmin(user) && (
        <Row>
          <SideBySideButton
            onClick={() => navigate('/inventory-exception-report')}
            hide={elmers}
          >
            Inventory Exception Report
          </SideBySideButton>

          <SideBySideButton
            onClick={() => navigate('/workbook-export')}
            hide={elmers}
          >
            Workbook export
          </SideBySideButton>
          <SideBySideButton
            onClick={() => navigate('/workbook-export-elmers')}
            hide={!elmers}
          >
            Workbook export
          </SideBySideButton>
        </Row>
      )}

      <BottomRow>
        <SideBySideButton onClick={onExitAppClick} data-testid="exit-app-link">
          Exit Application
        </SideBySideButton>
      </BottomRow>
    </FormBox>
  );
};

export default styled(MainSwitchboardForm)`
  width: 500px;

  ${SideBySideButton} {
    width: 50%;
  }

  > ${Row} + ${Row} {
    margin-top: 20px;
  }
`;

const BottomRow = styled(Row)`
  justify-content: center;
`;
