import { navigate } from '@reach/router';
import React from 'react';
import Api from '../api/Api';
import User from '../models/User';
import Employees from '../models/User';

const isTest = typeof describe === 'function';

function isAuthorized(employee: Employees, securityCodes?: number | number[]) {
  if (!securityCodes) {
    return true;
  }
  const { securityCode } = employee;
  if (!securityCode) {
    return false;
  }
  const codes = Array.isArray(securityCodes) ? securityCodes : [securityCodes];
  return codes.includes(securityCode);
}

/**
 * HOC to check for a user and redirect to the login page if they aren't logged in
 * @param Component
 */
function withAuth<T>(
  Component: React.ComponentType<T>,
  securityCode?: number | number[],
) {
  return class extends React.Component<T, { user: User | null }> {
    mounted: boolean;

    constructor(props: T) {
      super(props);
      this.state = {
        user: null,
      };
      this.mounted = false;
    }

    async componentWillMount() {
      this.mounted = true;

      try {
        const user = await Api.getUser();
        if (this.mounted) {
          if (user && securityCode && !isAuthorized(user, securityCode)) {
            window.alert(
              'You do not have the correct Security Code for this Screen!',
            );
            window.history.back();
            return;
          }
          this.setState({ user });
        }
      } catch (e) {
        if (!isTest) {
          // If the user isn't logged in, redirect to the login page
          navigate('/login');
        }
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    render() {
      const { user } = this.state;

      if (!user) {
        return null;
      }

      return <Component {...this.props as T} user={user} />;
    }
  };
}

export default withAuth;
