import { AxiosInstance } from 'axios';
import PricexRefTable from '../models/PricexRefTable';

export async function getCigPrices(
  axios: AxiosInstance,
): Promise<PricexRefTable> {
  const response = await axios.get('/cig-prices');
  return response.data.cigPrices;
}

export async function patchCigPrices(
  axios: AxiosInstance,
  prices: PricexRefTable,
): Promise<void> {
  await axios.put('/cig-prices', prices);
}
