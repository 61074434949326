import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import LoginForm, { LoginFormResult } from '../components/LoginForm';
import { navigate } from '@reach/router';
import Api from '../api/Api';
import Store from '../models/Store';
import useAsyncEffect from '../utils/useAsyncEffect';
import logError from '../utils/logError';
import isOrStore from '../utils/isOrStore';

const Login: PageComponent = props => {
  const { user } = props;
  const [stores, setStores] = useState<Store[]>([]);
  const urlStoreId: string | null = useMemo(
    () => new URLSearchParams(window.location.search).get('store_id'),
    [],
  );

  useAsyncEffect(
    async cancelled => {
      if (!urlStoreId) {
        const allStores = (await Api.getStores()).filter(isOrStore);
        if (!cancelled()) {
          setStores(allStores);
        }
      }
    },
    [urlStoreId],
  );

  function handleLoginSubmit(loginData?: LoginFormResult) {
    if (!loginData) {
      return;
    }
    const { storeId, employeeId, password } = loginData;
    Api.login({ storeId, employeeId, password })
      .then(() => navigate('/'))
      .catch(error => {
        logError(error);
        alert(`Invalid username or password.`);
      });
  }

  // If a user exists, move them back to the switchboard
  if (user) {
    navigate('/');
    return null;
  }

  return (
    <Page {...props}>
      <LoginForm
        onSubmit={handleLoginSubmit}
        stores={stores}
        storeId={urlStoreId}
      />
    </Page>
  );
};

export default styled(Login)`
  justify-content: center;
  align-items: center;
`;
