import React, {
  FC,
  FormEvent,
  MouseEvent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components/macro';
import FormBox from '../components/FormBox';
import Input from '../components/Input';
import Page from '../components/Page';
import isValidDate from '../utils/isValidDate';
import BlockButton from '../components/BlockButton';
import Row from '../components/Row';
import ShiftMaster from '../models/ShiftMaster';
import Api from '../api/Api';
import getErrorMessage from '../utils/getErrorMessage';

const TotalDollarsPlayedForm: FC<{
  shiftMaster?: ShiftMaster | null;
  closeOnSave?: boolean;
  onClose(success: boolean): unknown;
}> = ({
  onClose,
  shiftMaster: initialShiftMaster = null,
  closeOnSave = false,
  ...rest
}) => {
  const [shiftMaster, setShiftMaster] = useState<ShiftMaster | null>(
    initialShiftMaster,
  );
  const [shiftDateString, setShiftDateString] = useState(
    (initialShiftMaster &&
      initialShiftMaster.shiftDate &&
      initialShiftMaster.shiftDate.slice(0, 10)) ||
      '',
  );
  const [totalDollarsPlayedString, setTotalDollarsPlayedString] = useState(
    (initialShiftMaster && initialShiftMaster.pullTabPrizes) || '',
  );
  const [disabled, setDisabled] = useState(false);
  const [saved, setSaved] = useState(true);
  const [unableToGetReport, setUnableToGetReport] = useState(false);
  const [canAuditTDP, setCanAuditTDP] = useState(true);
  const success = useMemo(
    () =>
      !!(saved && totalDollarsPlayedString && +totalDollarsPlayedString > 0),
    [saved, totalDollarsPlayedString],
  );

  const save = useCallback(async () => {
    if (saved || !canAuditTDP) {
      return;
    }

    if (shiftDateString.trim().length === 0) {
      return window.alert('You must enter a date.');
    }
    if (!isValidDate(shiftDateString)) {
      return window.alert(`Invalid date.`);
    }
    const totalDollarsPlayed = +totalDollarsPlayedString;
    if (Number.isNaN(totalDollarsPlayed)) {
      return window.alert('Total dollars played must be a valid number.');
    }

    try {
      setDisabled(true);
      const { shiftMaster: updatedShiftMaster, canAudit } = await Api.saveTdp(
        new Date(shiftDateString),
        totalDollarsPlayed,
      );

      setCanAuditTDP(canAudit);
      if (updatedShiftMaster) {
        setShiftMaster(updatedShiftMaster);
        setShiftDateString(updatedShiftMaster.shiftDate.slice(0, 10));
        setTotalDollarsPlayedString(
          updatedShiftMaster.pullTabPrizes
            ? updatedShiftMaster.pullTabPrizes.toString()
            : '',
        );
        setSaved(true);
        return true;
      } else {
        window.alert(`Unable to save. No shift was found matching that date.`);
        return false;
      }
    } catch (error) {
      window.alert(getErrorMessage(error));
    } finally {
      setDisabled(false);
    }
  }, [shiftDateString, totalDollarsPlayedString, saved, canAuditTDP]);

  const pageBack = useCallback(async () => {
    try {
      await save();
      setDisabled(true);
      const {
        shiftMaster: previousShiftMaster,
        canAudit,
      } = await Api.getPreviousTdpShift(shiftMaster);

      if (previousShiftMaster) {
        setShiftMaster(previousShiftMaster);
        setShiftDateString(previousShiftMaster.shiftDate.slice(0, 10) || '');
        setTotalDollarsPlayedString(
          previousShiftMaster.pullTabPrizes
            ? previousShiftMaster.pullTabPrizes.toString()
            : '',
        );
        setSaved(true);
      }
      setCanAuditTDP(canAudit);
    } finally {
      setDisabled(false);
    }
  }, [shiftMaster, save]);

  const pageForward = useCallback(async () => {
    if (shiftMaster) {
      await save();
      setDisabled(true);
      try {
        const {
          shiftMaster: nextShiftMaster,
          canAudit,
        } = await Api.getNextTdpShift(shiftMaster);

        if (nextShiftMaster) {
          setShiftMaster(nextShiftMaster);
          setShiftDateString(nextShiftMaster.shiftDate.slice(0, 10));
          setTotalDollarsPlayedString(
            nextShiftMaster.pullTabPrizes
              ? nextShiftMaster.pullTabPrizes.toString()
              : '',
          );
        } else {
          setShiftMaster(null);
          setShiftDateString('');
          setTotalDollarsPlayedString('');
        }
        setCanAuditTDP(canAudit);
        setSaved(true);
      } finally {
        setDisabled(false);
      }
    }
  }, [shiftMaster, save]);

  const handleFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      save();
      if (closeOnSave) {
        onClose(success);
      }
    },
    [save, success, closeOnSave, onClose],
  );

  const handleTotalDollarsPlayedChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setTotalDollarsPlayedString(event.currentTarget.value);
      setSaved(false);
    },
    [],
  );

  const handleShiftDateChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setShiftDateString(event.currentTarget.value);
      setSaved(false);
    },
    [],
  );

  const handleCloseClick = useCallback(
    async (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      await save();
      onClose(success);
    },
    [save, onClose, success],
  );

  const toggleUnableToGetReport = () => {
    const unable = !unableToGetReport;
    setUnableToGetReport(unable);
    if (unable) {
      setSaved(totalDollarsPlayedString === '1'); // only mark as unsaved if the value wasn't 1 to begin with
      setTotalDollarsPlayedString('1');
    }
  };

  return (
    <Page {...rest}>
      <FormBox title="Total Dollars Played Form">
        <form onSubmit={handleFormSubmit}>
          <InputLabel>Date</InputLabel>
          <ShiftDateInput
            type="date"
            value={shiftDateString}
            onChange={handleShiftDateChange}
            disabled={
              disabled ||
              !!shiftMaster /* Can't change the date when viewing an existing shift master */
            }
          />
          <InputLabel>Total Dollars Played</InputLabel>
          <Input
            isMoney
            value={totalDollarsPlayedString}
            onChange={handleTotalDollarsPlayedChange}
            disabled={disabled || !canAuditTDP || unableToGetReport}
          />

          <UnableRow>
            <input
              id="unable_to_get_report"
              name="unable_to_get_report"
              type="checkbox"
              checked={unableToGetReport}
              onChange={toggleUnableToGetReport}
            />
            <label htmlFor="unable_to_get_report">Unable to get report</label>
          </UnableRow>

          <ButtonRow>
            <StyledButton disabled={disabled || saved || !canAuditTDP}>
              Save
            </StyledButton>
            <StyledButton disabled={disabled} onClick={handleCloseClick}>
              Close
            </StyledButton>
          </ButtonRow>
        </form>
        <ArrowRow>
          <ArrowButton onClick={pageBack} disabled={disabled}>
            {'<'}
          </ArrowButton>
          <ArrowButton
            onClick={pageForward}
            disabled={disabled || !shiftMaster}
          >
            {'>'}
          </ArrowButton>
        </ArrowRow>
      </FormBox>
    </Page>
  );
};

export default styled(TotalDollarsPlayedForm)`
  justify-content: center;
  align-items: center;

  ${FormBox} {
    width: 300px;
    max-width: 100%;
  }

  ${FormBox} > form {
    display: flex;
    flex-direction: column;
  }
`;

const InputLabel = styled('label')``;

const ShiftDateInput = styled('input')`
  height: 40px;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  padding-left: 20px;
  margin-bottom: 2rem;
`;

const StyledButton = styled(BlockButton)`
  min-width: 100px;
  height: 40px;
  margin: 1rem auto;

  & + & {
    margin-left: 20px;
  }
`;

const UnableRow = styled(Row)`
  margin-top: 0.5rem;
`;

const ButtonRow = styled(Row)``;

const ArrowRow = styled(Row)`
  justify-content: center;
`;

const ArrowButton = styled(BlockButton)`
  padding: 0 0 0 0;
  min-width: 60px;
  width: 60px;

  & + & {
    margin-left: 20px;
  }
`;
