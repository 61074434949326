/**
 * Returns true if the current browser is google chrome.
 * From: https://stackoverflow.com/a/13348618/2130365
 */
export default function isChrome(): boolean {
  const win = window as any;
  const isChromium = win.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof win.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = !!winNav.userAgent.match('CriOS');

  return (
    isIOSChrome ||
    (isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false)
  );
}
