import React from 'react';
import styled from 'styled-components/macro';

export interface TableProps {
  headers?: string[];
}

const Table: React.FC<TableProps> = props => {
  const { headers, ...rest } = props;
  return (
    <table {...rest}>
      <thead>
        {headers && (
          <tr>
            {headers.map(header => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        )}
      </thead>
      <tbody>{props.children}</tbody>
      <tfoot />
    </table>
  );
};

export default styled(Table)``;
