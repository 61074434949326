import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import DateText from './DateText';
import last from '../utils/last';
import first from '../utils/first';
import FormBox from './FormBox';
import { BluOverShortEntry } from '../api/BluOverShortEndpoints';
import formatISODate from '../utils/formatISODate';

export interface BluOverShortFormProps {
  storeId: number;
  data: BluOverShortEntry[];
}

const BluOverShortForm: React.FC<BluOverShortFormProps> = props => {
  const { storeId, data, ...rest } = props;
  const sortedWeeks = useMemo(
    () =>
      data.sort((a, b) => {
        return (
          new Date(a.shiftDate).getTime() - new Date(b.shiftDate).getTime()
        );
      }),
    [data],
  );

  const firstWeek = first(sortedWeeks);
  const lastWeek = last(sortedWeeks);

  const beginningDate = firstWeek && firstWeek.shiftDate;
  const endingDate = lastWeek && lastWeek.shiftDate;
  const packsTotal = data.reduce((total, week) => total + week.sumOfBluPack, 0);
  const overShortTotal = data.reduce(
    (total, week) => total + (week.sumOfEndingBluInventory - week.expr3),
    0,
  );

  return (
    <FormBox {...rest} title="Cig sales1">
      <Header>
        <Title>Blu Over/Short</Title>
        <StoreIdRow>
          <StoreIdLabel>Store id:</StoreIdLabel>
          <Box>{storeId}</Box>
        </StoreIdRow>
        <WeekRow>
          <WeekLabel>Week</WeekLabel>
          <div>
            {beginningDate && formatISODate(beginningDate)}
            &nbsp;-&nbsp;
            {endingDate && formatISODate(endingDate)}
          </div>
        </WeekRow>
      </Header>
      <Table>
        {data.map((week, i) => (
          <TableRow key={i}>
            <Column>
              <div>{formatISODate(week.shiftDate)}</div>
              <ColumnTitle>Beg. Inventory</ColumnTitle>
              <Box>{week.sumOfBeginningBluInventory.toFixed(2)}</Box>
            </Column>
            <Column>
              <ColumnTitle>Purchases</ColumnTitle>
              <Box>{week.sumOfBluPurchases.toFixed(2)}</Box>
            </Column>
            <Column>
              <ColumnTitle>Pack</ColumnTitle>
              <Box>{week.sumOfBluPack.toFixed(2)}</Box>
            </Column>
            <Column>
              <ColumnTitle>Expected Inventory</ColumnTitle>
              <Box>{week.expr3.toFixed(2)}</Box>
            </Column>
            <Column>
              <ColumnTitle>Actual Inventory</ColumnTitle>
              <Box>{week.sumOfEndingBluInventory.toFixed(2)}</Box>
            </Column>
            <Column>
              <ColumnTitle>Blu Over/Short</ColumnTitle>
              <Box>{week.sumOfEndingBluInventory - week.expr3}</Box>
            </Column>
          </TableRow>
        ))}
      </Table>
      <Footer>
        <GrandTotal>Grand Total</GrandTotal>

        <div>
          <ColumnTitle>Packs</ColumnTitle>
          <Box>{packsTotal}</Box>
        </div>

        <Row>
          <OverShortTotalRow>
            <OverShortTotalLabel>Total for the week</OverShortTotalLabel>
            <Column>
              <OverShortText>Over/Shorts</OverShortText>
              <Box>{overShortTotal}</Box>
            </Column>
          </OverShortTotalRow>
        </Row>
      </Footer>
    </FormBox>
  );
};

const Title = styled('div')``;

const StoreIdLabel = styled('div')`
  margin-right: 10px;
`;

const WeekLabel = styled(Column)`
  align-items: center;
`;

const StoreIdRow = styled(Row)`
  align-items: center;
`;

const WeekRow = styled(Column)`
  align-items: center;
  color: black;
  ${DateText} {
    font-style: normal;
    display: inline-block;
  }
`;

const Header = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 60%;
  padding: 0 10px 0 10px;
  ${Title},
  ${StoreIdLabel},
  ${WeekLabel} {
    font-weight: bold;
  }
`;

const Box = styled(Row)`
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  padding: 0 5px 0 5px;
  background-color: white;
  color: black;
  font-style: normal;
  min-width: 55px;
  max-width: 80px;
`;

const Table = styled(Column)``;

const TableRow = styled(Row)`
  border-bottom: 2px solid black;
  padding-bottom: 3px;
  & > ${Column}:nth-child(1),
  & > ${Column}:nth-child(2) {
    margin-right: 11%;
  }

  & > ${Column}:nth-child(3) {
    margin-right: 14%;
    text-align: right;
  }

  > ${Column} {
    justify-content: flex-end;
    margin: 0 10px 0 10px;
  }

  ${DateText} {
    font-size: 11px;
    margin-left: -4px;
    margin-top: -2px;
  }

  > ${Column} ${Box} {
    margin-top: 10px;
  }

  > ${Column} + ${Column} {
    margin-left: 10px;
  }
`;

const ColumnTitle = styled('div')`
  text-decoration: underline;
  font-weight: bold;
`;

const Footer = styled(Row)`
  margin-top: 20px;

  /* Fix alignment with table columns*/
  > * {
    width: 100px;
    margin: 0 10px 0 10px;
    margin-right: 28%;
    text-align: left;
  }

  > *:nth-child(2) {
    margin-right: 0%;
    text-align: center;
    width: 60px;
  }

  > *:nth-child(3) {
    margin-right: 10px;
    flex: 1;
    justify-content: flex-end;
  }
`;

const OverShortTotalRow = styled(Row)`
  align-items: flex-end;
`;

const OverShortTotalLabel = styled('div')`
  margin-right: 10px;
  font-weight: bold;
  min-width: 100px;
`;

const OverShortText = styled('div')`
  font-weight: bold;
`;

const GrandTotal = styled('div')`
  font-weight: bold;
`;

export default styled(BluOverShortForm)`
  background: #fff;
  width: 1000px;
  margin: auto;
  font-size: 14px;

  ${Header},
  ${Table},
  ${Footer} {
    font-style: italic;
    color: blueviolet;
  }
`;
