import { AxiosInstance } from 'axios';

export async function createOver21Test(
  axios: AxiosInstance,
  props: {
    passFail: 'Pass' | 'Fail';
    testDate: Date | string;
  },
) {
  await axios.post('/over21test', props);
}
