import React, { useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import DateInput from './DateInput';
import List, { ListItem } from './List';
import replaceWhere from '../utils/replaceWhere';
import BlockButton from './BlockButton';
import getAge from '../utils/getAge';
import FormBox from './FormBox';
import noop from '../utils/noop';

export const BIRTHDAY_COUNT = 8;
export const OVER_21 = 'OVER';
export const UNDER_21 = 'UNDER';

export interface BirthdayEntry {
  id: number;
  date: Date;
  over21: boolean | null;
}

export interface Props {
  onScore?: (isCorrect: boolean) => any;
}

const Over21TestForm: React.FC<Props> = props => {
  const { onScore = noop, ...rest } = props;

  const [checked, setChecked] = useState(false);
  const [birthdates, setBirthdates] = useState(createInitialBirthdates);

  function handleOver21Change(
    birthdateId: number,
    event: ChangeEvent<HTMLSelectElement>,
  ) {
    const over21 = event.currentTarget.value === OVER_21;
    setBirthdates(
      replaceWhere(
        birthdates,
        birthdate => birthdate.id === birthdateId,
        birthdate => ({ ...birthdate, over21 }),
      ),
    );
  }

  function handleResetClick() {
    setChecked(false);
    setBirthdates(createInitialBirthdates());
  }

  function handleScoreClick() {
    setChecked(true);
    const isCorrect = birthdates.every(birthdate => {
      const { over21, date } = birthdate;
      const age = getAge(date);

      return (over21 && age >= 21) || (over21 === false && age < 21);
    });
    onScore(isCorrect);
  }

  return (
    <FormBox {...rest} title="Over21Test : Form">
      <h1>Is this person 21 Years old or Older?</h1>
      <InputColumn>
        <label>Birth Date</label>
        <List>
          {birthdates.map((birthdate, i) => {
            const { id, date, over21 } = birthdate;
            const selectValue =
              over21 === null ? '' : over21 ? OVER_21 : UNDER_21;
            const age = checked ? getAge(date) : 0;
            const correct = (over21 && age >= 21) || (!over21 && age < 21);
            let checkText: string;

            if (!checked) {
              checkText = '';
            } else if (correct) {
              checkText = 'Correct';
            } else {
              checkText = 'Wrong';
            }

            return (
              <ListItem key={id}>
                <DateInput disabled value={date} />
                <select
                  value={selectValue}
                  onChange={event => handleOver21Change(birthdate.id, event)}
                  disabled={checked}
                  data-testid={`input-row-${i}-select`}
                >
                  <option value="" disabled />
                  <option value={OVER_21}>Over 21</option>
                  <option value={UNDER_21}>Under 21</option>
                </select>
                {over21 !== null && (
                  <CorrectLabel data-testid={`input-row-${i}-correct-label`}>
                    {checkText}
                  </CorrectLabel>
                )}
              </ListItem>
            );
          })}
        </List>
      </InputColumn>
      <ButtonRow>
        {/* this is a no-op when you've already scored it */}
        <BlockButton onClick={handleScoreClick} data-testid="score-button">
          Score Exam
        </BlockButton>
        {checked && (
          <BlockButton onClick={handleResetClick} data-testid="reset-button">
            New Test
          </BlockButton>
        )}
      </ButtonRow>
    </FormBox>
  );
};

/**
 * Creates the initial state for the birthday list.
 * Dates should be around 1-3 years before/after 21 years ago.
 */
function createInitialBirthdates(): BirthdayEntry[] {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDay = now.getDate();
  const values: BirthdayEntry[] = [];

  for (let i = 0; i < BIRTHDAY_COUNT; i++) {
    const id = i + 1;
    const over21 = null;
    const yearOffset = 21;
    const monthOffset = Math.floor(Math.random() * 72) - 36; // plus or minus 3 years in months
    const dayOffset = Math.floor(Math.random() * 180) - 180; // plus or minus 6 months
    const date = new Date(
      currentYear - yearOffset,
      currentMonth + monthOffset,
      currentDay + dayOffset,
      1,
      1,
      1,
      1,
    );

    values.push({ id, date, over21 });
  }

  return values;
}

export default styled(Over21TestForm)`
  ${BlockButton} + ${BlockButton} {
    margin-left: 10px;
  }

  h1 {
    text-align: center;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const InputColumn = styled(Column)`
  > label {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  li + li {
    margin-top: 10px;
  }

  ${DateInput} {
    margin-right: 10px;
  }

  ${ListItem} {
    align-items: center;
  }
`;

const CorrectLabel = styled('div')`
  margin-left: 10px;
`;

const ButtonRow = styled(Row)`
  margin-top: 20px;
`;
