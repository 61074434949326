import React from 'react';
import styled from 'styled-components/macro';
import Checkbox, { CheckboxProps } from './Checkbox';

// Same props as regular checkbox props, but `checked` can be `null`.
export type NullableCheckboxProps = {
  [Key in keyof CheckboxProps]: Key extends 'checked'
    ? boolean | null
    : CheckboxProps[Key]
};

const NullableCheckbox: React.FC<NullableCheckboxProps> = props => {
  return (
    <Root
      {...props}
      checked={!!props.checked}
      nulled={props.checked === null}
    />
  );
};

// eslint-disable-next-line
const Root = styled(({ nulled, ...rest }) => <Checkbox {...rest} />)<{
  nulled: boolean;
}>`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.nulled ? 'black' : 'transparent')};
  }
`;

export default styled(NullableCheckbox)``;
