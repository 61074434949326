import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import MachineAuditForm from '../components/MachineAuditForm';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import { MachineAuditData } from '../api/MachineAuditEndpoints';
import Api from '../api/Api';
import SpinnerOverlay from '../components/SpinnerOverlay';

const MachineAudit: PageComponent = props => {
  const [
    machineAuditData,
    setMachineAuditData,
  ] = useState<MachineAuditData | null>(null);

  useAsyncEffect(async () => {
    setMachineAuditData(await Api.getMachineAuditData());
  }, []);

  if (!machineAuditData) {
    // loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <MachineAuditForm data={machineAuditData} />
    </Page>
  );
};

export default styled(withAuth(MachineAudit))`
  justify-content: center;
  align-items: center;
`;
