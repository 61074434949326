import { AxiosInstance } from 'axios';

export async function getInventoryExceptionReport(
  axios: AxiosInstance,
  date: Date,
): Promise<
  Array<{
    storeId: number;
    storeName: string;
    exception: string;
  }>
> {
  const response = await axios.get(`/inventory-exception-report`, {
    params: { date: date.toISOString() },
  });
  return response.data;
}
