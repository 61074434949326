import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import NumberInput from './NumberInput';
import Input from './Input';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';

export interface TrialCassetteReportFormProps {
  loadCap: number;
  twentiesToLoad: number;
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
}

const TrialCassetteReportForm: React.FC<
  TrialCassetteReportFormProps
> = props => {
  const { value, onChange = noop, loadCap, twentiesToLoad, ...rest } = props;

  return (
    <Column {...rest}>
      <HeaderRow>
        <h3>Trial Cassette Report</h3>
      </HeaderRow>
      <LabelRow>
        <label>Load Cap</label>
        <label>QTY Remaining from Trial Report</label>
        <label>20s to Load</label>
      </LabelRow>

      <Row>
        <NumberInput readOnly value={loadCap} />
        <NumberInput
          value={value.atmReportResidual || 0}
          onValueChange={atmReportResidual =>
            onChange({ ...value, atmReportResidual })
          }
          data-testid="quantityRemainingInput"
        />
        <NumberInput readOnly value={twentiesToLoad} />
      </Row>

      <Row>
        <TwentiesToLoadLabel>
          # of 20s to
          <br /> be loaded
        </TwentiesToLoadLabel>
        <NumberInput
          value={value.n20SLoaded || 0}
          onValueChange={n20SLoaded => onChange({ ...value, n20SLoaded })}
          data-testid="twentiesToBeLoadedInput"
        />
        <Input value={0 /* Unbound input? */} />
      </Row>
    </Column>
  );
};

export default styled(TrialCassetteReportForm)`
  h3 {
    text-align: center;
  }

  > ${Row} {
    align-items: center;
    width: 380px;
  }

  > ${Row} + ${Row} {
    margin-top: 10px;
    @media print {
      margin-top: 5px;
    }
  }

  ${NumberInput}, ${Input} {
    flex: 1 0 25%;
    width: auto;
    min-width: 0;
    margin-right: 20px;
  }

  label {
    flex: 1 0 30%;
    width: auto;
    min-width: 0;
  }

  ${NumberInput}, ${Input} {
    height: 25px;
    text-align: right;
    padding: 0;
  }
`;

const HeaderRow = styled(Row)`
  border-bottom: 2px solid #dcdfe5;
  justify-content: center;

  && {
    width: 100%;
  }
`;

const LabelRow = styled(Row)`
  margin-bottom: 20px;
  @media print {
    margin-bottom: 10px;
  }
`;

const TwentiesToLoadLabel = styled.label`
  && {
    flex: 1 0 27%;
    margin-left: 10px;
  }
`;
