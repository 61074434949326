import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import ScratchTicketForm from '../components/ScratchTicketForm';
import withAuth from '../components/withAuth';
import SpinnerOverlay from '../components/SpinnerOverlay';

const CreateLottoGame: PageComponent = props => {
  const { user } = props;

  if (!user) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <ScratchTicketForm storeId={user.storeId} />
    </Page>
  );
};

export default styled(withAuth(CreateLottoGame))``;
