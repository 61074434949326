export default interface ItsReport {
  activated: number | null;
  activatedDetails: string | null;
  onLinePrizes: number | null;
  onLineSales: number | null;
  shift: number;
  shiftDate: string;
  soffValidation: number | null;
  storeId: number;
  vpValidation: number | null;
}

export interface ItsReportDraft {
  activated: string | number | null;
  activatedDetails: string | null;
  onLinePrizes: string | number | null;
  onLineSales: string | number | null;
  shift: string | number;
  shiftDate: string;
  soffValidation: string | number | null;
  storeId: string | number;
  vpValidation: string | number | null;
}

export function itsReportFromDraft(draft: ItsReportDraft): ItsReport {
  return {
    ...draft,
    activated: draft.activated === null ? null : +draft.activated,
    onLinePrizes: draft.onLinePrizes === null ? null : +draft.onLinePrizes,
    onLineSales: draft.onLineSales === null ? null : +draft.onLineSales,
    shift: +draft.shift,
    soffValidation:
      draft.soffValidation === null ? null : +draft.soffValidation,
    storeId: +draft.storeId,
    vpValidation: draft.vpValidation === null ? null : +draft.vpValidation,
  };
}
