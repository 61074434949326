import Employees from '../models/Employees';
import isSuperAdmin from './isSuperAdmin';
import Api from '../api/Api';

interface ShiftInfo {
  storeId: string | number;
  shiftDate: string;
  shiftNumber: number;
}

const DAY = 1000 * 60 * 60 * 24;

export default async function canEditShift(
  employee: Employees,
  shift: ShiftInfo,
): Promise<boolean> {
  if (isSuperAdmin(employee)) {
    return true;
  }
  const days = (Date.now() - new Date(shift.shiftDate).getTime()) / DAY;

  if (days < 8) {
    return true;
  }

  const oldAuditRows = await Api.getOldAuditRows();
  const oldAuditRow = oldAuditRows.find(
    row =>
      row.id === +employee.employeeId &&
      row.storeId === employee.storeId &&
      new Date(row.editDate.slice(0, 10)).getTime() <=
        new Date(shift.shiftDate.slice(0, 10)).getTime(),
  );

  if (oldAuditRow) {
    return true;
  }

  return false;
}
