import React, { useState } from 'react';
import styled from 'styled-components/macro';
import InputRow from './InputRow';
import Row from './Row';
import BlockButton from './BlockButton';
import FormBox from './FormBox';
import ShiftMaster, {
  ShiftMasterDraft,
  shiftMasterFromDraft,
} from '../models/ShiftMaster';
import noop from '../utils/noop';
import Input from './Input';

export interface CashRegisterReportFormProps {
  value: ShiftMasterDraft;
  readOnly: boolean;
  onSubmit?: (value: ShiftMaster) => any;
  onCancel?: () => any;
  isElmers: boolean;
}

const CashRegisterReportForm: React.FC<CashRegisterReportFormProps> = props => {
  const [value, setValue] = useState<ShiftMasterDraft>({
    ...props.value,
    cigaretteDiscount:
      ((props.value.cigaretteDiscount && +props.value.cigaretteDiscount) || 0) -
      ((props.value.cigaretteCoupons && +props.value.cigaretteCoupons) || 0),
  });
  const {
    onSubmit = noop,
    onCancel = noop,
    readOnly,
    isElmers,
    ...rest
  } = props;

  function handleFoodChange(food: string) {
    setValue({ ...value, food });
  }

  function handleAlcoholBeveragesChange(alcoholBeverages: string) {
    setValue({ ...value, alcoholBeverages });
  }

  function handleCigaretteDiscountChange(cigaretteDiscount: string) {
    setValue({ ...value, cigaretteDiscount });
  }

  function handleCigaretteCouponsChange(cigaretteCoupons: string) {
    setValue({ ...value, cigaretteCoupons });
  }

  function handleFoodDiscountChange(foodDiscount: string) {
    setValue({ ...value, foodDiscount });
  }

  function handleEnterClick() {
    const shiftMaster = shiftMasterFromDraft(value);
    onSubmit({
      ...shiftMaster,
      cigaretteDiscount:
        (shiftMaster.cigaretteDiscount || 0) +
        (shiftMaster.cigaretteCoupons || 0),
    });
  }

  return (
    <FormBox {...rest} title="CRREPORT : Form" onCloseClick={handleEnterClick}>
      <h1>Cash Register Report</h1>

      <Subtitle>Z1 sub total (DOLLARS)</Subtitle>
      <InputRow>
        <label>Food_Beverage</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.food}
          onValueChange={handleFoodChange}
          data-testid="foodBeverageInput"
        />
      </InputRow>
      <InputRow>
        <label>Alcohol</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.alcoholBeverages}
          onValueChange={handleAlcoholBeveragesChange}
          data-testid="alcoholInput"
        />
      </InputRow>
      <InputRow hide={isElmers}>
        <label>Cig Discount</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.cigaretteDiscount}
          onValueChange={handleCigaretteDiscountChange}
          data-testid="cigDiscountInput"
        />
      </InputRow>
      <InputRow hide={isElmers}>
        <label>Cig Coupons</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.cigaretteCoupons}
          onValueChange={handleCigaretteCouponsChange}
          data-testid="cigCouponsInput"
        />
      </InputRow>
      <InputRow hide={isElmers}>
        <label>Mobile Coupons</label>
        <Input
          isMoney
          readOnly={readOnly}
          value={value.foodDiscount}
          onValueChange={handleFoodDiscountChange}
          data-testid="mobileCouponsInput"
        />
      </InputRow>
      <ButtonRow>
        <BlockButton onClick={handleEnterClick} data-testid="enterButton">
          Enter
        </BlockButton>
        <BlockButton onClick={onCancel} data-testid="cancelButton">
          Cancel
        </BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

export default styled(CashRegisterReportForm)`
  width: 800px;
  margin: auto;
  h1 {
    text-align: center;
  }

  ${InputRow} {
    width: 350px;
  }

  ${Input} {
    width: 150px;
  }
`;

const Subtitle = styled('div')`
  width: 350px;
  padding: 10px 0;
  text-align: right;
`;

const ButtonRow = styled(Row)`
  border-top: 4px solid black;
  padding-top: 8px;
  margin-top: 8px;
  justify-content: space-evenly;
  width: 700px;
  ${BlockButton} {
    min-width: unset;
    width: 90px;
    text-transform: uppercase;
  }
`;
