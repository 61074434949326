import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Omit from '../utils/Omit.d';
import { useArrowKeyDisable } from '../utils/useArrowKeyDisable';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value'> {
  isMoney?: boolean;
  onValueChange?: (value: string) => any;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'] | null;
}

const Input: React.FC<InputProps> = props => {
  const { onValueChange, isMoney, onChange, value, type, ...rest } = props;
  const [focused, setFocused] = useState(false);
  let displayValue = value;

  if (
    isMoney &&
    !focused &&
    value !== '' &&
    (typeof value === 'string' || typeof value === 'number')
  ) {
    const parsed = +value;
    if (!Number.isNaN(parsed)) {
      displayValue = '$' + parsed.toFixed(2);
    }
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(event.currentTarget.value);
    }
  }

  function handleFocus() {
    setFocused(true);
  }

  function handleBlur() {
    setFocused(false);
  }

  const arrowKeyDisableProps = useArrowKeyDisable(isMoney || type === 'number');

  return (
    <input
      {...rest}
      type={type}
      value={displayValue === null ? '' : displayValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      {...arrowKeyDisableProps}
    />
  );
};

/**
 * Generic input
 */
export default styled(Input)`
  height: 40px;
  background-color: white;
  outline: none;
  padding: 0 20px 0 20px;
  font-size: 16px;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  position: relative;

  &:disabled,
  &:read-only {
    background-color: #ddd;
  }

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-calendar-picker-indicator,
  &[type='date']::-webkit-outer-spin-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &[type='date']::-webkit-clear-button {
    display: none; /* Hide the button */
    -webkit-appearance: none; /* turn off default browser styling */
  }

  &[type='date']::-webkit-inner-spin-button,
  &[type='date']::-webkit-outer-spin-button {
    right: 2px;
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    right: 10px;
  }
`;
