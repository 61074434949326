export default function singleDecimal(
  value: number | string | null,
): number | string | null {
  if (!value) {
    return value;
  }
  if (typeof value === 'string') {
    return value.replace(/\.(\d).+/, '.$1');
  }
  return Math.floor(10 * value) / 10;
}
