import React from 'react';
import styled from 'styled-components/macro';
import InputRow from './InputRow';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import Select from './Select';
import TextArea from './TextArea';
import Input from './Input';
import FormBox from './FormBox';
import ShiftMaster from '../models/ShiftMaster';

export interface OverShortFormProps {
  shift: ShiftMaster;
  onChange(shift: ShiftMaster): any;
}

const OverShortForm: React.FC<OverShortFormProps> = props => {
  const { shift, onChange, children, ...rest } = props;

  return (
    <FormBox {...rest} title="Over/Short Form">
      <InputRow>
        <label>Store id</label>
        <NumberInput readOnly value={shift.storeId} />
      </InputRow>
      <InputRow>
        <label>shift date</label>
        <Input type="date" readOnly value={shift.shiftDate.slice(0, 10)} />
      </InputRow>
      <InputRow>
        <label>shift</label>
        <NumberInput readOnly value={shift.shift} />
      </InputRow>
      <InputRow>
        <label>Employee #</label>
        <NumberInput readOnly value={shift.employee || ''} />
      </InputRow>
      <InputRow>
        <label>FirstName</label>
        <Input readOnly value={shift.firstName || ''} />
      </InputRow>
      <InputRow>
        <label>LastName</label>
        <Input readOnly value={shift.lastName || ''} />
      </InputRow>
      <InputRow>
        <label>Over/Short:</label>
        <NumberInput readOnly value={shift.overShort || 0} />
      </InputRow>
      <InputRow>
        <label>Counseling Statement done:</label>
        <Select
          value={shift.idWriteUp || 0}
          onValueChange={idWriteUp =>
            onChange({ ...shift, idWriteUp: +idWriteUp })
          }
        >
          <option value={0} />
          <option value={2}>Done</option>
          <option value={1}>In Progress</option>
          <option value={3}>Deleted</option>
        </Select>
      </InputRow>
      <InputRow>
        <label>Notified Regional Manager:</label>
        <Select
          value={shift.idrm || 0}
          onValueChange={idrm => onChange({ ...shift, idrm: +idrm })}
        >
          <option value={0} />
          <option value={1}>Called</option>
          <option value={2}>Voicemail</option>
          <option value={3}>Left Message</option>
          <option value={4}>Operations Notified</option>
        </Select>
      </InputRow>
      <InputRow>
        <label>Forward to Eugene</label>
        <Select
          value={shift.iDmailed || 0}
          onValueChange={iDmailed =>
            onChange({ ...shift, iDmailed: +iDmailed })
          }
        >
          <option value={0} />
          <option value={1}>Eugene HR</option>
        </Select>
      </InputRow>
      <InputRow>
        <label>Other comments:</label>
        <TextArea
          value={shift.othercomments ? shift.othercomments : ''}
          onValueChange={othercomments => onChange({ ...shift, othercomments })}
        />
      </InputRow>
      {children}
    </FormBox>
  );
};

export default styled(OverShortForm)`
  ${Select} {
    flex: 1 1 auto;
    max-width: 220px;
  }

  ${InputRow}> ${Input},
  ${InputRow}> ${DateInput},
  ${InputRow}> ${NumberInput},
  ${InputRow}> select,
  ${InputRow}> textarea {
    width: 220px;
    margin-left: auto;
    margin-right: 180px;
  }

  ${InputRow} {
    align-items: center;
    justify-content: space-evenly;
  }

  label {
    min-width: 100px;
    width: auto;
    margin-left: auto;
  }

  textarea {
    width: 400px;
    margin-right: unset;
  }
`;
