import React from 'react';
import styled from 'styled-components/macro';

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  onValueChange?: (value: string) => any;
  readOnly?: boolean;
}

const Select: React.FC<SelectProps> = props => {
  const { onValueChange, onChange, ...rest } = props;

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(event.currentTarget.value);
    }
  }

  return <select {...rest} onChange={handleChange} />;
};

export default styled(Select)`
  height: 40px;
`;
