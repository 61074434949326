import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import DateText, { DateDisplayFormat } from './DateText';
import FormBox from './FormBox';
import { MachineAuditData } from '../api/MachineAuditEndpoints';

export interface MachineAuditFormProps {
  data: MachineAuditData;
}

const MachineAuditForm: React.FC<MachineAuditFormProps> = props => {
  const { data, ...rest } = props;
  const now = useMemo(() => new Date(), []);
  const dataDate = useMemo(() => new Date(data.maxOfDate), [data]);

  return (
    <FormBox {...rest} title="Video Deposit Log RM audit query for machines">
      <InsetBox>
        <PageTitle>
          Video Lottery Machine
          <br />
          Audit
          <br />
          Regional Manager
        </PageTitle>

        <DateRow>
          <div>
            <b>Date</b>
          </div>
          <div>
            <DateText date={dataDate} />
          </div>
        </DateRow>

        <Data>
          <MachineColumn>
            <MachineLabel>Machine 1</MachineLabel>
            <Row>{data.maxOfMachine1}</Row>
          </MachineColumn>
          <MachineColumn>
            <MachineLabel>Machine 2</MachineLabel>
            <Row>{data.maxOfMachine2}</Row>
          </MachineColumn>
          <MachineColumn>
            <MachineLabel>Machine 3</MachineLabel>
            <Row>{data.maxOfMachine3}</Row>
          </MachineColumn>
          <MachineColumn>
            <MachineLabel>Machine 4</MachineLabel>
            <Row>{data.maxOfMachine4}</Row>
          </MachineColumn>
          <MachineColumn>
            <MachineLabel>Machine 5</MachineLabel>
            <Row>{data.maxOfMachine5}</Row>
          </MachineColumn>
          <MachineColumn>
            <MachineLabel>Machine 6</MachineLabel>
            <Row>{data.maxOfMachine6}</Row>
          </MachineColumn>
        </Data>
        <Footer>
          <DateText format={DateDisplayFormat.Long} date={now} />
          <PageCounter>
            <b>Page</b> 1 of 1
          </PageCounter>
        </Footer>
      </InsetBox>
    </FormBox>
  );
};

export default styled(MachineAuditForm)`
  width: 800px;
  background: #aaa;
  font-size: 14px;
`;

const Data = styled(Row)`
  min-height: 500px;
  max-width: 100%;
`;

const PageTitle = styled('h1')`
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  width: 600px;
  max-width: 100%;
  margin-top: 50px;
`;

const InsetBox = styled(Column)`
  justify-content: space-between;
  padding: 5px;
  height: 100%;
  background: #fff;
  box-shadow: 2px 2px black;
  border: 1px solid black;
  padding: 30px;
  overflow: auto;
  & > * {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const Footer = styled(Row)`
  margin-top: auto;
  width: 700px;
  max-width: 100%;
  justify-content: space-between;
`;
const PageCounter = styled('div')``;

const DateRow = styled(Row)`
  width: 600px;
  margin: 0 auto;
  margin-bottom: 10px;
  ${DateText} {
    margin-left: 30px;
  }
`;

const MachineLabel = styled(Row)`
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  width: 100%;
  justify-content: center;
  padding: 7px 0 7px;
  margin-bottom: 20px;
`;

const MachineColumn = styled(Column)`
  align-items: center;
  text-align: center;
  flex: 1;
  font-weight: bold;
`;
