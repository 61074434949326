import React from 'react';
import styled from 'styled-components/macro';
import moment from 'moment';

export interface DateTextProps {
  date: Date;
  format?: DateDisplayFormat;
}

export enum DateDisplayFormat {
  Long = 'dddd, MMMM Do, YYYY',
  Short = 'M/D/YYYY',
}

const DateText: React.FC<DateTextProps> = props => {
  const { date, format = DateDisplayFormat.Short, ...rest } = props;
  if (!date) {
    return null;
  }

  return <span {...rest}>{moment(date).format(format)}</span>;
};

export default styled(DateText)``;
