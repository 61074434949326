import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import ReactList from 'react-list';
import NumberInput from './NumberInput';
import Input from './Input';
import replaceWhere from '../utils/replaceWhere';
import FormBox from './FormBox';
import WindowsCheckbox from './WindowsCheckbox';
import ScratchTickets from '../models/ScratchTickets';
import Row from './Row';
import Column from './Column';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';

export interface ScratchTicketFormProps {
  storeId: number;
}

const ScratchTicketForm: React.FC<ScratchTicketFormProps> = props => {
  const { storeId, ...rest } = props;
  const [scratchTickets, setScratchTickets] = useState<ScratchTickets[]>([]);
  const [pendingTicket, setPendingTicket] = useState<ScratchTickets>({
    storeId,
    gameName: '',
    gameNumber: 0,
    packetCount: 0,
    status: 0,
    value: 0,
  });
  const sortedScratchTickets = useMemo(() => {
    return scratchTickets.sort((a, b) => b.gameNumber - a.gameNumber);
  }, [scratchTickets]);

  useAsyncEffect(async () => {
    const tickets = await Api.getScratchTickets();
    setScratchTickets(tickets);
  }, []);

  async function handleTicketChange(
    newTicket: ScratchTickets,
    oldTicket: ScratchTickets,
  ) {
    const shouldSave =
      newTicket.gameNumber > 0 &&
      (newTicket.gameNumber === oldTicket.gameNumber ||
        !scratchTickets.some(
          ticket =>
            ticket !== oldTicket && ticket.gameNumber === newTicket.gameNumber,
        ));

    setScratchTickets(
      replaceWhere(
        scratchTickets,
        other => other === oldTicket,
        () => newTicket,
      ),
    );
    if (shouldSave) {
      await Api.updateScratchTicket(oldTicket.gameNumber, newTicket);
    }
  }

  function handlePendingTicketChange(newTicket: ScratchTickets) {
    setPendingTicket(newTicket);
  }

  async function handleAddPending() {
    if (
      scratchTickets.some(
        ticket => ticket.gameNumber === pendingTicket.gameNumber,
      )
    ) {
      window.alert('A ticket already exists with that game number.');
      return;
    }

    await Api.createScratchTicket(pendingTicket);
    setScratchTickets(
      [...scratchTickets, pendingTicket].sort(
        (a, b) => b.gameNumber - a.gameNumber,
      ),
    );
    setPendingTicket({
      storeId,
      gameName: '',
      gameNumber: 0,
      packetCount: 0,
      status: 0,
      value: 0,
    });
  }

  return (
    <FormBox {...rest} title="ScratchTickets">
      <HeaderRow>
        <Column>Store ID</Column>
        <Column>Packet Count</Column>
        <Column>Game Number</Column>
        <Column>Game Name</Column>
        <Column>Value</Column>
        <Column>Disabled</Column>
      </HeaderRow>
      <TableContainer>
        <ReactList
          itemRenderer={(index, key) => {
            if (index === 0) {
              return (
                <ScratchTicketRow
                  key={key}
                  ticket={pendingTicket}
                  onChange={handlePendingTicketChange}
                  onAdd={handleAddPending}
                  index={index}
                />
              );
            }
            return (
              <ScratchTicketRow
                key={key}
                ticket={sortedScratchTickets[index - 1]}
                onChange={handleTicketChange}
                index={index}
              />
            );
          }}
          length={sortedScratchTickets.length + 1}
          type="uniform"
        />
      </TableContainer>
    </FormBox>
  );
};

const ScratchTicketRow: React.FC<{
  index: number;
  ticket: ScratchTickets;
  onChange: (newTicket: ScratchTickets, oldTicket: ScratchTickets) => any;
  onAdd?: () => any;
}> = ({ ticket, onChange, onAdd, index, ...rest }) => (
  <TicketRow {...rest}>
    <Column>
      <NumberInput
        value={ticket.storeId}
        onValueChange={storeId => onChange({ ...ticket, storeId }, ticket)}
        data-testid={`ticketStoreIdInput${index}`}
      />
    </Column>
    <Column>
      <NumberInput
        value={ticket.packetCount || 0}
        min="0"
        onValueChange={packetCount =>
          onChange({ ...ticket, packetCount }, ticket)
        }
        data-testid={`ticketPacketCountInput${index}`}
      />
    </Column>
    <Column>
      <NumberInput
        value={ticket.gameNumber}
        min="0"
        onValueChange={gameNumber =>
          onChange({ ...ticket, gameNumber }, ticket)
        }
        data-testid={`ticketGameNumberInput${index}`}
      />
    </Column>
    <Column>
      <Input
        value={ticket.gameName || ''}
        onValueChange={gameName => onChange({ ...ticket, gameName }, ticket)}
        data-testid={`ticketGameNameInput${index}`}
      />
    </Column>
    <Column>
      <NumberInput
        value={ticket.value || 0}
        onValueChange={newValue =>
          onChange({ ...ticket, value: newValue }, ticket)
        }
        data-testid={`ticketValueInput${index}`}
      />
    </Column>
    <Row>
      <WindowsCheckbox
        checked={!!ticket.status}
        onChange={status =>
          onChange(
            {
              ...ticket,
              status: status ? 1 : 0,
            },
            ticket,
          )
        }
        data-testid={`ticketStatusCheckbox${index}`}
      />
      {onAdd && <button onClick={onAdd}>Add</button>}
    </Row>
  </TicketRow>
);

export default styled(ScratchTicketForm)`
  width: 840px;
  min-width: 840px;
  margin: auto;

  ${NumberInput},
  ${Input} {
    width: 100%;
    height: 25px;
  }
`;

const TableContainer = styled(Column)`
  height: 500px;
  overflow: auto;
  margin-bottom: 10px;
`;

const TableRow = styled(Row)`
  align-items: center;

  > *:nth-child(1),
  > *:nth-child(2),
  > *:nth-child(3),
  > *:nth-child(5) {
    width: 113px;
  }

  > *:nth-child(4) {
    width: 272px;
  }

  > *:nth-child(6) {
    width: 55px;
  }
`;

const HeaderRow = styled(TableRow)`
  font-weight: bold;
`;

const TicketRow = styled(TableRow)``;
