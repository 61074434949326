import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import ErrorLog from '../models/ErrorLog';
import ErrorLogForm from '../components/ErrorLogForm';
import SpinnerOverlay from '../components/SpinnerOverlay';

interface Props {
  autoPrint?: boolean;
}

const ErrorCheck: PageComponent<Props> = props => {
  const { autoPrint } = props;
  const [errorLogs, setErrorLogs] = useState<ErrorLog[] | null>(null);

  useAsyncEffect(async () => {
    setErrorLogs(await Api.getErrorLogData());
  }, []);

  if (!errorLogs) {
    // loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <ErrorLogForm data={errorLogs} autoPrint={autoPrint} />
    </Page>
  );
};

export default styled(withAuth(ErrorCheck))``;
