import React, { useState } from 'react';
import styled from 'styled-components/macro';
import debounce from 'lodash.debounce';
import Page, { PageComponent } from '../components/Page';
import ByBrandForm from '../components/ByBrandForm';
import withAuth from '../components/withAuth';
import { navigate } from '@reach/router';
import BrandInventoryForm from '../components/BrandInventoryForm';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import {
  BrandInventoryRowDraft,
  ActivePromo,
} from '../api/BrandInventoryEndpoints';
import replaceWhere from '../utils/replaceWhere';
import SpinnerOverlay from '../components/SpinnerOverlay';

const save = debounce(
  async (eopDate: string, rows: BrandInventoryRowDraft[]) => {
    await Api.insertIntoEopTableMultiple(
      rows.map(row => ({
        ...row,
        eopDate,
        brandId: row.brandId,
        brandCount: +row.packCount,
        brandCartonCount: +row.cartonCount,
      })),
    );
  },
  1000,
);

const BrandInventory: PageComponent<{ inventoryDate?: string }> = props => {
  const [brandInventoryData, setBrandInventoryData] = useState<
    BrandInventoryRowDraft[] | null
  >(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [closingTotal] = useState(0);
  const [closingBluTotal] = useState(0);
  const [activePromos, setActivePromos] = useState<ActivePromo[]>([]);
  const { user, inventoryDate, ...rest } = props;

  function handleDateFormSubmit(date: Date) {
    navigate(`/brand-inventory/${date.toISOString()}`);
  }

  function updateTotals(value: BrandInventoryRowDraft[]) {
    if (!inventoryDate) {
      return;
    }
    setGrandTotal(
      value
        .filter(row => row.brandId !== 203)
        .reduce((total, row) => total + +row.packCount + +row.cartonCount, 0),
    );
  }

  function handleProductChange(
    rowId: number,
    brandId: number,
    oldBrandId: number,
    packCount: number | string,
    cartonCount: number | string,
  ) {
    if (!brandInventoryData || !inventoryDate) {
      return;
    }
    const existingProduct =
      (rowId && brandInventoryData.find(product => product.id === rowId)) ||
      brandInventoryData.find(product => product.brandId === oldBrandId);
    const promo = activePromos.find(p => p.brandId === brandId);
    const brandName = promo ? promo.promoName : '';
    let newValue;

    if (existingProduct) {
      newValue = replaceWhere(
        brandInventoryData,
        value => (rowId ? value.id === rowId : value.brandId === oldBrandId),
        value => ({
          ...value,
          oldBrandId,
          brandId,
          packCount,
          cartonCount,
          brandName,
        }),
      );
    } else {
      newValue = [
        ...brandInventoryData,
        {
          id: 0,
          brandId,
          oldBrandId: brandId,
          packCount,
          cartonCount,
          brandName,
          isPromo: !!promo,
          firstProductName: '',
          isNew: true,
        },
      ];
    }

    setBrandInventoryData(newValue);
    updateTotals(newValue);
    save(inventoryDate, newValue);
  }

  function handleOtherProductChange(
    rowId: number,
    brandId: number,
    oldBrandId: number,
    brandCount: number | string,
    brandCartonCount: number | string,
  ) {
    if (!brandInventoryData || !inventoryDate) {
      return;
    }
    const existingProduct = brandInventoryData.find(
      product => product.brandId === brandId,
    );
    let newValue;

    if (existingProduct) {
      newValue = replaceWhere(
        brandInventoryData,
        value => (rowId ? value.id === rowId : value.brandId === brandId),
        value => ({
          ...value,
          oldBrandId,
          packCount: brandCount,
          cartonCount: brandCartonCount,
        }),
      );
    } else {
      newValue = [
        ...brandInventoryData,
        {
          id: brandInventoryData.length + 1,
          brandId,
          packCount: brandCount,
          cartonCount: brandCartonCount,
          brandName: '',
          isPromo: false,
          firstProductName: '',
          isNew: true,
        },
      ];
    }

    setBrandInventoryData(newValue);
    updateTotals(newValue);
    save(inventoryDate, newValue);
  }

  function handleSubmit() {
    navigate('/');
  }

  useAsyncEffect(async () => {
    setActivePromos(await Api.getActivePromos());
  }, []);

  useAsyncEffect(async () => {
    if (!inventoryDate) {
      return;
    }
    const values = await Api.getBrandInventoryData(inventoryDate);
    setBrandInventoryData(values);
    await updateTotals(values);
  }, [inventoryDate]);

  if (!inventoryDate) {
    return (
      <Page {...rest}>
        <ByBrandForm onSubmit={handleDateFormSubmit} />
      </Page>
    );
  }

  if (!user || !brandInventoryData) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...rest}>
      <BrandInventoryForm
        storeId={user.storeId}
        eopDate={inventoryDate}
        grandTotal={grandTotal}
        closingTotal={closingTotal}
        closingBluTotal={closingBluTotal}
        activePromos={activePromos}
        value={brandInventoryData}
        onProductChange={handleProductChange}
        onOtherProductChange={handleOtherProductChange}
        onSubmit={handleSubmit}
      />
    </Page>
  );
};

export default styled(withAuth(BrandInventory))`
  justify-content: center;
  align-items: center;
`;
