import { AxiosInstance } from 'axios';
import ShiftMaster from '../models/ShiftMaster';

/**
 * Save the total dollars played to the last shift on the passed date.
 * Returns the shift if successful.
 * Returns `null` if no shift was found on the given date.
 */
export async function saveTdp(
  axios: AxiosInstance,
  date: Date,
  totalDollarsPlayed: number,
): Promise<{ shiftMaster: ShiftMaster | null; canAudit: boolean }> {
  const response = await axios.post(
    `/total-dollars-played/shift/${date.toISOString()}`,
    {
      totalDollarsPlayed,
    },
  );

  return response.data;
}

/**
 * Gets the previous shift for paginating backwards in the total-dollars-played form.
 */
export async function getPreviousTdpShift(
  axios: AxiosInstance,
  shiftMaster: Pick<ShiftMaster, 'shiftDate'> | null,
): Promise<{ shiftMaster: ShiftMaster | null; canAudit: boolean }> {
  const response = await axios.get(
    shiftMaster
      ? `/total-dollars-played/shift/${shiftMaster.shiftDate}/previous`
      : `/total-dollars-played/shift`,
  );

  return response.data;
}

/**
 * Gets the next shift for paginating forwards in the total-dollars-played form.
 */
export async function getNextTdpShift(
  axios: AxiosInstance,
  shiftMaster: ShiftMaster,
): Promise<{ shiftMaster: ShiftMaster | null; canAudit: boolean }> {
  const response = await axios.get(
    `/total-dollars-played/shift/${shiftMaster.shiftDate}/next`,
  );

  return response.data;
}
