import React, { useState, MouseEvent, FormEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Input from './Input';
import Form from './Form';
import InputRow from './InputRow';
import BlockLink from './BlockLink';
import BlockButton from './BlockButton';
import DateInput from './DateInput';
import FormBox from './FormBox';
import OkDialog from './OkDialog';
import Employees from '../models/Employees';
import isSuperAdmin from '../utils/isSuperAdmin';
import { navigate } from '@reach/router';
import isValidDate from '../utils/isValidDate';
import { isElmers } from '../utils/isElmers';

export type ManagersShiftAuditSubmitResult = { date: Date; shift: number };
export type ManagersShiftAuditFormOnSubmit = (
  result: ManagersShiftAuditSubmitResult,
) => any;
export interface ORManagersShiftAuditFormProps {
  user?: Employees;
  onAuditSubmit?: ManagersShiftAuditFormOnSubmit;
  onCloseClick?: () => any;
  onOverShortLogClick?: () => any;
  onCigaretteOverShortClick?: () => any;
  onBluOverShortClick?: () => any;
  onVideoDepositLogClick?: () => any;
  onMachineAuditClick?: () => any;
  onAuditSafeLoadClick?: () => any;
  onVideoDepositManagerClick?: () => any;
}

let prevShiftDate: string | undefined;
let prevShiftNumber: string | undefined;

const ManagersShiftAuditForm: React.FC<
  ORManagersShiftAuditFormProps
> = props => {
  const {
    user,
    onAuditSubmit,
    onCloseClick,
    onOverShortLogClick,
    onCigaretteOverShortClick,
    onBluOverShortClick,
    onVideoDepositLogClick,
    onMachineAuditClick,
    onAuditSafeLoadClick,
    onVideoDepositManagerClick,
    ...rest
  } = props;

  const [auditDate, setAuditDate] = useState(
    () => prevShiftDate || new Date().toISOString().slice(0, 10),
  );
  const [shift, setShift] = useState(prevShiftNumber || '');
  const [validationError, setValidationError] = useState<string>('');
  const [showValidationError, setShowValidationError] = useState<boolean>(
    false,
  );

  function handleCloseClick(event: MouseEvent) {
    event.preventDefault(); // stop audit form from submitting
    if (onCloseClick) {
      onCloseClick();
    }
  }

  function handleAuditFormSubmit(event: FormEvent<HTMLFormElement> | null) {
    if (event) {
      event.preventDefault();
    }
    const auditDateObject = new Date(auditDate + 'T00:00:00.000Z');

    if (!shift) {
      setValidationError('Invalid shift number');
      setShowValidationError(true);
      return;
    }
    if (!isValidDate(auditDateObject)) {
      setValidationError('Invalid shift date');
      setShowValidationError(true);
      return;
    }

    prevShiftDate = auditDate;
    prevShiftNumber = shift;

    if (onAuditSubmit) {
      onAuditSubmit({
        date: auditDateObject,
        shift: parseInt(shift, 10),
      });
    }
  }

  function handleValidationErrorClose() {
    setShowValidationError(false);
    setValidationError('');
  }

  function onCanAuditOldShiftsClick() {
    navigate('/old-audit-permissions');
  }
  const elmers = user && isElmers(user.storeId);

  return (
    <FormBox
      {...rest}
      title="AuditShift : Form"
      onCloseClick={() => navigate('/')}
    >
      <AuditForm onSubmit={handleAuditFormSubmit} data-testid="auditForm">
        <h1>Manager's Shift Audit</h1>
        <InputRow>
          <label>Enter Date:</label>
          <Input
            type="date"
            value={auditDate}
            onValueChange={setAuditDate}
            data-testid="auditDateInput"
          />
        </InputRow>
        <InputRow>
          <label>Enter Shift:</label>
          <Input
            type="number"
            value={shift}
            onValueChange={setShift}
            data-testid="auditShiftInput"
          />
        </InputRow>
        <InputRow>
          <BlockButton data-testid="auditShiftButton" type="submit">
            Audit Shift
          </BlockButton>
          <BlockButton onClick={handleCloseClick} data-testid="closeButton">
            Close
          </BlockButton>
        </InputRow>
      </AuditForm>
      <ButtonColumn>
        <ColumnHeader>
          Manager use <br />
          (use only)
        </ColumnHeader>
        <BlockLink
          onClick={onOverShortLogClick}
          data-testid="overShortLogButton"
        >
          Over/Short Log
        </BlockLink>
        <BlockLink
          onClick={onCigaretteOverShortClick}
          data-testid="cigaretteOverShortButton"
          hide={elmers}
        >
          Cigarette Over/Short
        </BlockLink>
        <BlockLink
          onClick={onBluOverShortClick}
          data-testid="bluOverShortButton"
          hide={elmers}
        >
          Blu Over/Short
        </BlockLink>
        <BlockLink
          onClick={onVideoDepositLogClick}
          data-testid="videoDepositLogButton"
        >
          Video Deposit Log
        </BlockLink>
      </ButtonColumn>
      <ButtonColumn>
        <ColumnHeader>
          Regional Manager
          <br />
          (use only)
        </ColumnHeader>
        <BlockLink
          onClick={onMachineAuditClick}
          data-testid="machineAuditButton"
          hide={elmers}
        >
          Machine Audit
        </BlockLink>
        <BlockLink
          onClick={onAuditSafeLoadClick}
          data-testid="auditSafeLoadButton"
        >
          Audit Safe Load
        </BlockLink>
        <BlockLink
          onClick={onVideoDepositManagerClick}
          data-testid="videoDepositManagerButton"
        >
          Video Deposit Regional Manager
        </BlockLink>
        {user && isSuperAdmin(user) && (
          <BlockLink onClick={onCanAuditOldShiftsClick}>
            Can Audit Old Shifts
          </BlockLink>
        )}
      </ButtonColumn>
      {showValidationError && (
        <OkDialog
          message={validationError}
          onOkClicked={handleValidationErrorClose}
          onClose={handleValidationErrorClose}
        />
      )}
    </FormBox>
  );
};

export default styled(ManagersShiftAuditForm)`
  width: 1024px;
  flex-direction: row;
`;

const ColumnHeader = styled(`div`)`
  text-align: center;
  margin-bottom: 10px;
`;

const AuditForm = styled(Form)`
  padding-right: 50px;

  ${Input},
  ${DateInput} {
    width: 200px;
  }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  label {
    margin-right: 20px;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 10px;
  }
`;

const ButtonColumn = styled(Column)`
  flex: 1;

  ${BlockLink} + ${BlockLink} {
    margin-top: 10px;
  }

  & + & {
    margin-left: 10px;
  }
`;
