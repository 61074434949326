import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import AuditSafeLoadReport from '../components/AuditSafeLoadReport';
import Api from '../api/Api';
import useAsyncEffect from '../utils/useAsyncEffect';
import VideoDepositLog from '../models/VideoDepositLog';
import isValidDate from '../utils/isValidDate';
import { navigate } from '@reach/router';
import SpinnerOverlay from '../components/SpinnerOverlay';

const AuditSafeLoad: PageComponent = props => {
  const [data, setData] = useState<VideoDepositLog[] | null>(null);

  useAsyncEffect(async () => {
    const startDate = window.prompt('Enter beginning date:');
    if (!startDate) {
      navigate('/manager');
      return;
    }
    if (!isValidDate(startDate)) {
      window.alert('Invalid date');
      navigate('/manager');
      return;
    }

    const endDate = window.prompt('Enter ending date:');
    if (!endDate) {
      navigate('/manager');
      return;
    }
    if (!isValidDate(endDate)) {
      window.alert('Invalid date');
      navigate('/manager');
      return;
    }

    setData(await Api.getAuditSafeReportData(startDate, endDate));
  }, []);

  if (!data) {
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <AuditSafeLoadReport data={data} />
    </Page>
  );
};

export default styled(withAuth(AuditSafeLoad))`
  align-items: center;
  justify-content: center;

  @media print {
    display: block;
    width: auto;
    height: auto;
    overflow: visible;
  }
`;
