import { useMemo } from 'react';

export default function useSearchParams<Key extends string>(...keys: Key[]) {
  const searchParams = useMemo(() => {
    const params: Partial<Record<Key, string | null>> = {};
    const urlSearchParams = new URLSearchParams(window.location.search);
    for (const key of keys) {
      params[key] = urlSearchParams.get(key) || null;
    }
    return params as Record<Key, string | null>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, keys);

  return searchParams;
}
