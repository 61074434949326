import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import Input from './Input';
import { useArrowKeyDisable } from '../utils/useArrowKeyDisable';

export interface NumberInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: number) => any;
}

const NumberInput: React.FC<NumberInputProps> = props => {
  const { onValueChange, onChange, value, step = '1', ...rest } = props;
  const [inputValue, setInputValue] = useState(value);
  const valueRef = useRef(value);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value: newValue } = event.currentTarget;

    setInputValue(newValue);
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      const parsed = +newValue;
      if (!Number.isNaN(parsed)) {
        valueRef.current = parsed;
        onValueChange(parsed);
      }
    }
  }

  useEffect(() => {
    if (valueRef.current !== value) {
      valueRef.current = value;
      setInputValue(value);
    }
  }, [value]);

  const disableArrowProps = useArrowKeyDisable();

  return (
    <Input
      {...rest}
      type="number"
      step={step}
      onChange={handleChange}
      value={inputValue}
      {...disableArrowProps}
    />
  );
};

export default styled(NumberInput)``;
