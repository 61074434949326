import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import VideoDepositLogInputForm from '../components/VideoDepositLogInputForm';
import withAuth from '../components/withAuth';
import VideoDepositLog from '../models/VideoDepositLog';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import Store from '../models/Store';
import SpinnerOverlay from '../components/SpinnerOverlay';
import isValidDate from '../utils/isValidDate';
import { useMemo } from 'react';
import logError from '../utils/logError';

const CreateVideoDepositLog: PageComponent = props => {
  const { user } = props;
  const [loading, setLoading] = useState(true);
  const [
    priorVideoDepositLog,
    setPriorVideoDepositLog,
  ] = useState<VideoDepositLog | null>(null);
  const [reviewableVideoDepositLogs, setReviewableVideoDepositLogs] = useState<
    VideoDepositLog[] | null
  >(null);
  const [store, setStore] = useState<Store | null>(null);
  const [value, setValue] = useState<VideoDepositLog>(() => ({
    additionalBag1: 0,
    additionalBag10: 0,
    additionalBag11: 0,
    additionalBag12: 0,
    additionalBag13: 0,
    additionalBag14: 0,
    additionalBag15: 0,
    additionalBag16: 0,
    additionalBag17: 0,
    additionalBag18: 0,
    additionalBag19: 0,
    additionalBag2: 0,
    additionalBag20: 0,
    additionalBag3: 0,
    additionalBag4: 0,
    additionalBag5: 0,
    additionalBag6: 0,
    additionalBag7: 0,
    additionalBag8: 0,
    additionalBag9: 0,
    atmAdditionCount: 0,
    atmIncrease: 0,
    atmIncreaseNotes: '',
    atmJammed: 0,
    atmJammedBillNotes: '',
    atmJammedBillOut: 0,
    atmRejected: 0,
    atmRejectedCount: 0,
    atmRemainingCount: 0,
    atmReportNum20SDispensed: 0,
    atmReportResidual: 0,
    atmResidualCount: 0,
    atmRunningTotal: 0,
    bankBagNumber: 0,
    bankBagNumberRm: 0,
    bankCorrection: 0,
    checkOutBag1: 0,
    checkOutBag10: 0,
    checkOutBag2: 0,
    checkOutBag3: 0,
    checkOutBag4: 0,
    checkOutBag5: 0,
    checkOutBag6: 0,
    checkOutBag7: 0,
    checkOutBag8: 0,
    checkOutBag9: 0,
    chipChange1: 0,
    chipChange2: 0,
    chipChange3: 0,
    chipChange4: 0,
    chipChange5: 0,
    chipChange6: 0,
    chipChange7: 0,
    chipChange8: 0,
    cleanSweep: 0,
    comments: '',
    date: '',
    dateRm: null,
    depositAmount: 0,
    depositCheckOutRm1: 0,
    depositCheckOutRm10: 0,
    depositCheckOutRm2: 0,
    depositCheckOutRm3: 0,
    depositCheckOutRm4: 0,
    depositCheckOutRm5: 0,
    depositCheckOutRm6: 0,
    depositCheckOutRm7: 0,
    depositCheckOutRm8: 0,
    depositCheckOutRm9: 0,
    detail100S: 0,
    detail10S: 0,
    detail1S: 0,
    detail20S: 0,
    detail50S: 0,
    detail5S: 0,
    firstName: '',
    id: -1,
    jamBillsIn: 0,
    lastName: '',
    load100S: 0,
    load10S: 0,
    load20S: 0,
    load50S: 0,
    load5S: 0,
    machine1: 0,
    machine1Total: 0,
    machine2: 0,
    machine2Total: 0,
    machine3: 0,
    machine3Total: 0,
    machine4: 0,
    machine4Total: 0,
    machine5: 0,
    machine5Total: 0,
    machine6: 0,
    machine6Total: 0,
    machine7: 0,
    machine7Total: 0,
    machine8: 0,
    machine8Total: 0,
    moneytoIncreaseSafeAmount: 0,
    n20SLoaded: 0,
    netDepositCalc: 0,
    newLoad100S: 0,
    regionalManagerName: '',
    rmBankCorrection: 0,
    rmNotes: '',
    rmatmNotes: '',
    rmid: '',
    rollOver1: 0,
    rollOver2: 0,
    rollOver3: 0,
    rollOver4: 0,
    rollOver5: 0,
    rollOver6: 0,
    rollOver7: 0,
    rollOver8: 0,
    storeId: 0,
    storeName: '',
    totalCashInCalc: 0,
    totalDepositCalc: 0,
    totalLoadedIntoSafeCalc: 0,
    totalOverShortCalc: 0,
    vlmPullPriorDay: 0,
  }));

  useAsyncEffect(
    async cancelled => {
      if (!user) {
        return;
      }
      setLoading(true);
      const { storeId } = user;

      const [userStore, priorVdl, reviewableLogs] = await Promise.all([
        Api.getStoreById(storeId),
        Api.getPriorVideoDepositLog({
          storeId: user.storeId,
          date: value.date || new Date().toISOString(),
        }).catch(error => {
          logError('Failed to fetch prior VDL', error);
          return null;
        }),
        Api.getReviewableVideoDepositLogs(user.storeId),
      ]);

      if (!cancelled()) {
        setPriorVideoDepositLog(priorVdl);
        setStore(userStore);
        setReviewableVideoDepositLogs(reviewableLogs);
        setLoading(false);
      }
    },
    [user],
  );

  async function save(videoDepositLog: VideoDepositLog) {
    try {
      if (!videoDepositLog.date || !isValidDate(videoDepositLog.date)) {
        throw new Error('Invalid date.');
      }
      if (
        videoDepositLog.machine1 !== 0 ||
        videoDepositLog.machine2 !== 0 ||
        videoDepositLog.machine3 !== 0 ||
        videoDepositLog.machine4 !== 0 ||
        videoDepositLog.machine5 !== 0 ||
        videoDepositLog.machine6 !== 0
      ) {
        await Api.createVideoDepositLog(videoDepositLog);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function handleCloseClick(
    videoDepositLog: VideoDepositLog,
    shouldSave: boolean,
  ) {
    if (shouldSave) {
      await save(videoDepositLog);
    }
    navigate('/manager');
  }

  const reloadPriorVdl = useMemo(
    () =>
      debounce(
        async (fields: {
          date: string;
          videoDepositLogId: number;
          storeId: number;
        }) => {
          setLoading(true);
          try {
            const newPriorVdl = await Api.getPriorVideoDepositLog(fields);
            setPriorVideoDepositLog(newPriorVdl);
          } finally {
            setLoading(false);
          }
        },
        1000,
      ),
    [],
  );

  function handleChange(newValue: VideoDepositLog) {
    if (value && value.date && newValue.date && newValue.date !== value.date) {
      // Reload prior VDL if the user changes the VDL date
      reloadPriorVdl({
        date: newValue.date,
        videoDepositLogId: newValue.id,
        storeId: newValue.storeId,
      });
    }
    setValue(newValue);
  }

  useEffect(() => {
    const body = document.querySelector('body');
    if (!body) {
      return () => {
        // intentionally empty
      };
    }
    // This applies workarounds for the background being blue in print and looking way out of place.
    const originalBackground = body.style.backgroundColor;
    const beforePrint = () => {
      body.style.backgroundColor = '#eeeeee';
    };
    const afterPrint = () => {
      body.style.backgroundColor = originalBackground;
    };
    window.addEventListener('beforeprint', beforePrint);
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('beforeprint', beforePrint);
      window.removeEventListener('afterprint', afterPrint);
    };
  }, []);

  if (loading || !user || !store || !reviewableVideoDepositLogs) {
    // Loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <VideoDepositLogInputForm
        onSave={save}
        onCloseClick={handleCloseClick}
        storeId={user.storeId}
        employee={user}
        priorVideoDepositLog={priorVideoDepositLog}
        reviewableVideoDepositLogs={reviewableVideoDepositLogs}
        store={store}
        value={value}
        onChange={handleChange}
      />
    </Page>
  );
};

export default styled(withAuth(CreateVideoDepositLog))`
  @media print {
    max-height: 100vh;
  }
`;
