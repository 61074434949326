import React, { FormEvent } from 'react';
import styled from 'styled-components/macro';

export type FormOnSubmit = (result: FormEvent<HTMLFormElement>) => any;
export interface FormProps {
  onSubmit?: FormOnSubmit;
}

const Form: React.FC<FormProps> = props => {
  const { onSubmit, ...rest } = props;

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  }

  return (
    <form
      {...rest}
      onSubmit={handleSubmit}
      noValidate // We'll want to explicitly handle any validation ourselves, so this prevents browsers from stepping in
    />
  );
};

export default styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
`;
