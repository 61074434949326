import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Table from './Table';
import DateText, { DateDisplayFormat } from './DateText';
import FormBox from './FormBox';
import Row from './Row';

export interface EopPayoutsReportPayout {
  id: number;
  shiftDate: Date;
  shiftNumber: number;
  storeName: string;
  payout: number;
  comments: string;
}

export interface EopPayoutsReportProps {
  date: Date;
  payouts: EopPayoutsReportPayout[];
}

const EopPayoutsReport: React.FC<EopPayoutsReportProps> = props => {
  const { payouts, date, ...next } = props;

  return (
    <FormBox {...next} title="EOP Payouts Report">
      <InsetBox>
        <PageTitle>Payouts Report</PageTitle>
        <PayoutsTable
          headers={[
            'shift date',
            'shift',
            'Store_Name',
            'Payouts',
            'Payouts Comments',
          ]}
        >
          {payouts.map(payout => (
            <tr key={payout.id}>
              <td>
                <DateText date={payout.shiftDate} />
              </td>
              <td>{payout.shiftNumber}</td>
              <td>{payout.storeName}</td>
              <td>{payout.payout}</td>
              <td>{payout.comments}</td>
            </tr>
          ))}
        </PayoutsTable>
        <Footer>
          <DateText format={DateDisplayFormat.Long} date={date} />
          <PageCounter>Page 1 of 1</PageCounter>
        </Footer>
      </InsetBox>
    </FormBox>
  );
};

const PageTitle = styled('h1')`
  text-align: left;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: normal;
`;

const InsetBox = styled(Column)`
  background: #fff;
  box-shadow: 2px 2px black;
  border: 1px solid black;
  font-size: 12px;
  min-height: 600px;
  padding: 20px 30px;
  justify-content: flex-start;
`;

const PayoutsTable = styled(Table)`
  width: 400px;
  margin: 0px 0 0 5px;
  td,
  th {
    background-color: white;
    text-align: left;

    &:nth-child(even) {
      text-align: right;
      padding-right: 15px;
    }
  }

  th {
    &:nth-child(1) {
      text-align: center;
    }
  }
`;
const Footer = styled(Row)`
  margin-top: auto;
  border-top: 1px solid black;
  padding-top: 15px;
  width: 100%;
  padding-right: 40px;
  justify-content: space-between;
`;
const PageCounter = styled('div')``;

export default styled(EopPayoutsReport)`
  margin: auto;
  width: 700px;
  background: #aaa;
`;
