import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import NumberInput from './NumberInput';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';

export interface NumberOfSafeTubesLoadedFormProps {
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
  netDeposit: number;
  totalLoadedIntoSafe: number;
}

const NumberOfSafeTubesLoadedForm: React.FC<
  NumberOfSafeTubesLoadedFormProps
> = props => {
  const {
    value,
    onChange = noop,
    netDeposit,
    totalLoadedIntoSafe,
    ...rest
  } = props;

  return (
    <Column {...rest}>
      <h3>
        Number of Tubes Loaded into Safe
        <br />
        (same as safe Load Receipt)
      </h3>
      <JamBills>
        <label>Jam Bills In:</label>
        <NumberInput
          value={value.jamBillsIn || 0}
          onValueChange={jamBillsIn => onChange({ ...value, jamBillsIn })}
          data-testid="jamBillsInInput"
        />
      </JamBills>
      <MoneyInputColumn>
        <StyledInputRow>
          <label>1's</label>
          <NumberInput
            value={value.load100S || 0}
            onValueChange={load100S => onChange({ ...value, load100S })}
            data-testid="onesLoadedInput"
          />
        </StyledInputRow>
        <StyledInputRow>
          <label>5's</label>
          <NumberInput
            value={value.load5S || 0}
            onValueChange={load5S => onChange({ ...value, load5S })}
            data-testid="fivesLoadedInput"
          />
        </StyledInputRow>

        <StyledInputRow>
          <label>20's</label>
          <NumberInput
            value={value.load20S || 0}
            onValueChange={load20S => onChange({ ...value, load20S })}
            data-testid="twentiesLoadedInput"
          />
        </StyledInputRow>

        <StyledInputRow>
          <label>100's</label>
          <NumberInput
            value={value.newLoad100S}
            onValueChange={newLoad100S => onChange({ ...value, newLoad100S })}
            data-testid="hundredsLoadedInput"
          />
        </StyledInputRow>
      </MoneyInputColumn>
      <Totals>
        <TotalsLabel>Total Loaded into Safe</TotalsLabel>
        <NumberInput value={totalLoadedIntoSafe} />
      </Totals>

      <Totals>
        <TotalsLabel>Net Deposit:</TotalsLabel>
        <NumberInput value={netDeposit} />
      </Totals>
    </Column>
  );
};

export default styled(NumberOfSafeTubesLoadedForm)`
  text-align: center;

  h3 {
    margin-bottom: 20px;
    @media print {
      margin-bottom: 10px;
    }
  }
`;

const StyledInputRow = styled(InputRow)`
  justify-content: space-between;
  input {
    height: 25px;
    text-align: right;
  }

  ${NumberInput} {
    padding: 0;
  }

  > label {
    width: 100px;
    text-align: right;
    margin-right: 10px;
  }
`;

const MoneyInputColumn = styled(Column)`
  justify-content: center;
  align-items: center;
`;

const JamBills = styled(StyledInputRow)`
  && {
    justify-content: center;

    ${NumberInput} {
      width: 130px;
    }

    > label {
      margin-right: 40px;
    }
  }
`;

const TotalsLabel = styled.label`
  && {
    width: 151px;
  }
`;

const Totals = styled(StyledInputRow)`
  &&& {
    justify-content: flex-start;
    margin-right: 105px;
    ${NumberInput} {
      width: 130px;
      > input {
        height: 25px;
      }
      margin-right: 9px;
    }

    > label {
      margin-left: auto;
      margin-right: 4px;
    }
  }
`;
