import React from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinners/ScaleLoader';
import Overlay from './Overlay';

const SpinnerOverlay: React.FC = props => {
  return (
    <Overlay {...props}>
      <Spinner color="white" />
    </Overlay>
  );
};

export default styled(SpinnerOverlay)`
  align-items: center;
  justify-content: center;
`;
