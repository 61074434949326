import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import ScratchTicketBackStockForm from '../components/ScratchTicketBackStockForm';
import withAuth from '../components/withAuth';

const ScratchTicketBackstockPage: PageComponent = props => {
  return (
    <Page {...props}>
      <ScratchTicketBackStockForm
        products={[
          {
            date: new Date(),
            gameNumber: 2,
            id: 1,
            packNumber: 2,
          },
          {
            date: new Date(),
            gameNumber: 2,
            id: 1,
            packNumber: 2,
          },
          {
            date: new Date(),
            gameNumber: 2,
            id: 1,
            packNumber: 2,
          },
          {
            date: new Date(),
            gameNumber: 2,
            id: 1,
            packNumber: 2,
          },
        ]}
        returns={[
          {
            id: 2,
            date: new Date(),
            endingInventory: 2,
            gameNumber: 2,
            packNumber: 1234,
          },
        ]}
      />
    </Page>
  );
};

export default styled(withAuth(ScratchTicketBackstockPage))``;
