import { AxiosInstance } from 'axios';

export interface MachineAuditData {
  storeId: number;
  maxOfDate: string;
  maxOfMachine1: number;
  maxOfMachine2: number;
  maxOfMachine3: number;
  maxOfMachine4: number;
  maxOfMachine5: number;
  maxOfMachine6: number;
  maxOfMachine7: number;
  maxOfMachine8: number;
}

export async function getMachineAuditData(
  axios: AxiosInstance,
): Promise<MachineAuditData> {
  const response = await axios.get('/machineAudit');
  return response.data.machineAuditData;
}
