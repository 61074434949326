import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import Input from './Input';
import BlockButton from './BlockButton';
import noop from '../utils/noop';

interface EmployeeSearchFormProps {
  /**
   * Function to call when the search button is pressed.
   * Return true if the search finds something/should be cleared, false otherwise
   */
  onSearchClick?: (employeeId: string, employeeName: string) => any;
}

const EmployeeSearchForm: React.FC<EmployeeSearchFormProps> = ({
  onSearchClick = noop,
  ...rest
}) => {
  const [employeeId, setEmployeeId] = useState('');
  const [employeeName, setEmployeeName] = useState('');

  async function handleSearchClick() {
    await onSearchClick(employeeId, employeeName);
    setEmployeeId('');
    setEmployeeName('');
  }

  return (
    <Column {...rest}>
      <InputRow>
        <label>Employee Name</label>
        <Input
          value={employeeName}
          onValueChange={v => setEmployeeName(v)}
          data-testid="searchNameInput"
        />
      </InputRow>
      <InputRow>
        <label>Employee ID</label>
        <Input
          value={employeeId}
          onValueChange={v => setEmployeeId(v)}
          data-testid="searchEmployeeIdInput"
        />
      </InputRow>
      <BlockButton onClick={handleSearchClick} data-testid="searchButton">
        Search
      </BlockButton>
    </Column>
  );
};

export default styled(EmployeeSearchForm)``;
