import React, { FC, useEffect } from 'react';
import styled from 'styled-components/macro';
import Overlay from './Overlay';
import Column from './Column';

export interface ModalProps {
  disableClose?: boolean;
  noScroll?: boolean;
  onClose?: () => any;
}

const Modal: FC<ModalProps> = props => {
  const { disableClose, onClose, noScroll, children, ...rest } = props;

  // Disable body overflow when modal mounts. Re-enable it on unmount.
  useEffect(() => {
    const prevOverflow = document.body.style.overflow;
    if (prevOverflow !== 'none') {
      document.body.style.overflow = 'none';
      return () => {
        document.body.style.overflow = prevOverflow;
      };
    }
  }, []);

  return (
    <ModalOverlay onClick={disableClose ? null : onClose} noScroll={noScroll}>
      <Column {...rest}>{children}</Column>
    </ModalOverlay>
  );
};

export default styled(Modal)`
  position: absolute;
  top: 120px;
  margin-bottom: 120px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #e5ebf1;
  z-index: 3;
  max-width: 100%;
`;

const ModalOverlay = styled(Overlay)`
  background: none;
  opacity: 1;
  /* Doesn't overflow everything else when its in a form box */
  height: 100%;
`;
