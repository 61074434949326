import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import NumberInput from './NumberInput';
import Row from './Row';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';

export interface AtmFillFormProps {
  value: VideoDepositLog;
  onChange?: (value: VideoDepositLog) => any;
}

const AtmFillForm: React.FC<AtmFillFormProps> = props => {
  const { value, onChange = noop, ...rest } = props;

  return (
    <Column {...rest}>
      <h3>ATM Fill</h3>
      <Row>
        <h3>Actual Cassette Counts</h3>
      </Row>
      <InputRow>
        <label>Count in Cassette:</label>
        <NumberInput
          value={value.atmResidualCount || 0}
          onValueChange={atmResidualCount =>
            onChange({ ...value, atmResidualCount })
          }
          data-testid="countInCassetteInput"
        />
      </InputRow>
      <InputRow>
        <label>Count in Rejected:</label>
        <NumberInput
          value={value.atmRejectedCount || 0}
          onValueChange={atmRejectedCount =>
            onChange({ ...value, atmRejectedCount })
          }
          data-testid="countInRejectedInput"
        />
      </InputRow>
      <FinalReportNumbersHeader>
        Final *Cassette Total* Report Numbers
      </FinalReportNumbersHeader>
      <InputRow>
        <label>Bills Dispensed:</label>
        <NumberInput
          value={value.atmReportNum20SDispensed || 0}
          onValueChange={atmReportNum20SDispensed =>
            onChange({ ...value, atmReportNum20SDispensed })
          }
          data-testid="billsDispensedInput"
        />
      </InputRow>
    </Column>
  );
};

export default styled(AtmFillForm)`
  > input,
  ${NumberInput} {
    height: 25px;
    padding: 0;
    text-align: right;
    flex: 1 1 auto;
    margin-right: 50px;
    max-width: 131px;
  }

  h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  ${InputRow} {
    > label {
      flex: 2 1 auto;
    }
    justify-content: flex-start;
  }
`;

const FinalReportNumbersHeader = styled.h3`
  width: 180px;
  && {
    text-align: left;
  }
`;
