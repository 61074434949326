import { useEffect } from 'react';
import logError from './logError';

/**
 * Like `useEffect`, but can receive an async function and can't be cancelled.
 */
export default function useAsyncEffect(
  effect: (cancelled: () => boolean) => any,
  deps?: ReadonlyArray<any> | undefined,
) {
  useEffect(() => {
    let cancelled = false;
    const getCancelled = () => cancelled;
    Promise.resolve(getCancelled)
      .then(effect)
      .catch(logError);

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
