import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import CanAuditOldShifts, {
  CanAuditOldShiftsDraft,
} from '../models/CanAuditOldShifts';
import FormBox from '../components/FormBox';
import Table from '../components/Table';
import Input from '../components/Input';
import replaceWhere from '../utils/replaceWhere';
import isValidDate from '../utils/isValidDate';

const OldAuditPermissions: PageComponent = props => {
  const [rows, setRows] = useState<CanAuditOldShifts[]>([]);
  const [newRow, setNewRow] = useState<CanAuditOldShiftsDraft>({
    id: '',
    storeId: '',
    editDate: '',
    notes: '',
  });

  useAsyncEffect(async () => {
    const data = await Api.getOldAuditRows();
    setRows(data);
  }, []);

  async function handleRowChange<K extends keyof CanAuditOldShifts>(
    row: CanAuditOldShifts,
    key: K,
    value: CanAuditOldShifts[K],
  ) {
    const existingRow = rows.find(other => other === row);
    if (!existingRow) {
      return;
    }
    const updatedRow = { ...existingRow, [key]: value };

    setRows(replaceWhere(rows, other => other === row, () => updatedRow));
    await Api.updateOldAuditRow(existingRow.id, updatedRow);
  }

  async function handleAddClick() {
    const id = parseInt(newRow.id, 10);
    const storeId = parseInt(newRow.storeId, 10);

    if (Number.isNaN(id)) {
      window.alert('Invalid employee id.');
      return;
    }
    if (Number.isNaN(storeId)) {
      window.alert('Invalid store id.');
      return;
    }
    if (!isValidDate(newRow.editDate)) {
      window.alert('Invalid edit date.');
      return;
    }

    const newRowObject = await Api.createOldAuditRow({
      id,
      storeId,
      editDate: newRow.editDate,
      notes: newRow.notes,
    });
    setNewRow({
      id: '',
      storeId: '',
      editDate: '',
      notes: '',
    });
    setRows([...rows, newRowObject]);
  }

  async function handleRemoveClick(rowId: number) {
    await Api.deleteOldAuditRow(rowId);
    setRows(rows.filter(other => other.id !== rowId));
  }

  return (
    <Page {...props}>
      <FormBox>
        <Table headers={['Employee ID', 'Store ID', 'Edit Date', 'Notes']}>
          {rows.map((row, i) => (
            <tr key={i}>
              <td>
                <Input
                  type="number"
                  value={row.id}
                  onValueChange={id => handleRowChange(row, 'id', +id)}
                />
              </td>
              <td>
                <Input
                  type="number"
                  value={row.storeId}
                  onValueChange={storeId =>
                    handleRowChange(row, 'storeId', +storeId)
                  }
                />
              </td>
              <td>
                <Input
                  type="date"
                  value={row.editDate.slice(0, 10)}
                  onValueChange={editDate =>
                    handleRowChange(row, 'editDate', editDate)
                  }
                />
              </td>
              <td>
                <Input
                  value={row.notes}
                  onValueChange={notes => handleRowChange(row, 'notes', notes)}
                />
              </td>
              <td>
                <button onClick={() => handleRemoveClick(row.id)}>
                  Remove
                </button>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <Input
                type="number"
                value={newRow.id}
                onValueChange={id => setNewRow({ ...newRow, id })}
              />
            </td>
            <td>
              <Input
                type="number"
                value={newRow.storeId}
                onValueChange={storeId => setNewRow({ ...newRow, storeId })}
              />
            </td>
            <td>
              <Input
                type="date"
                value={newRow.editDate}
                onValueChange={editDate => setNewRow({ ...newRow, editDate })}
              />
            </td>
            <td>
              <Input
                value={newRow.notes}
                onValueChange={notes => setNewRow({ ...newRow, notes })}
              />
            </td>
            <td>
              <button onClick={handleAddClick}>Add</button>
            </td>
          </tr>
        </Table>
        <BackButton onClick={() => window.history.back()}>Back</BackButton>
      </FormBox>
    </Page>
  );
};

export default styled(withAuth(OldAuditPermissions, [1, 3]))`
  justify-content: center;
  align-items: center;

  ${FormBox} {
    width: 1000px;
  }
`;

const BackButton = styled('button')`
  width: 100px;
  margin-top: 20px;
`;
