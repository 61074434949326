import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import ManagersShiftAuditForm, {
  ManagersShiftAuditSubmitResult,
} from '../components/ManagerShiftAuditForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';
import BaseShiftData from '../models/BaseShiftData';
import Api from '../api/Api';

const Manager: PageComponent = props => {
  const routeTo = (route: string) => () => navigate(route);
  const [mostRecentShiftData, setMostRecentShiftData] = useState<
    BaseShiftData | undefined
  >(undefined);

  const updateShiftData = async () => {
    // Needed for knowing which shift master to open when clicking "Back to shift entry" from the main switchboarxd
    setMostRecentShiftData(await Api.getMostRecentShiftInfo());
  };

  useEffect(() => {
    updateShiftData();
  }, []);

  async function handleAuditShiftClick(result: ManagersShiftAuditSubmitResult) {
    if (!mostRecentShiftData) {
      return;
    }

    return navigate(
      `/audit-shift/${result.date.toISOString()}/${
        result.shift
      }?backRoute=/manager`,
    );
  }

  async function handleVideoDepositLogClick() {
    navigate(`/video-deposit-log/create`);
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <ManagersShiftAuditForm
          user={props.user}
          onCloseClick={routeTo('/')}
          onOverShortLogClick={routeTo('/over-short-log')}
          onCigaretteOverShortClick={routeTo('/cig-over-short')}
          onBluOverShortClick={routeTo('/blu-over-short')}
          onVideoDepositLogClick={handleVideoDepositLogClick}
          onMachineAuditClick={routeTo('/machine-audit')}
          onAuditSafeLoadClick={routeTo('/audit-safe-load')}
          onVideoDepositManagerClick={routeTo(
            '/video-deposit-regional-manager',
          )}
          onAuditSubmit={handleAuditShiftClick}
        />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(Manager, [1, 3]))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)``;
