import React from 'react';
import styled from 'styled-components/macro';
import DateInput from './DateInput';
import NumberInput from './NumberInput';
import replaceWhere from '../utils/replaceWhere';
import BlockButton from './BlockButton';
import Row from './Row';
import Table from './Table';
import FormBox from './FormBox';
import Column from './Column';

export interface ScratchTicketBackStockFormProduct {
  id: number;
  date: Date;
  gameNumber: number;
  packNumber: number;
}

export interface ScratchTicketBackStockFormReturn {
  id: number;
  date: Date;
  gameNumber: number;
  packNumber: number;
  endingInventory: number;
}

export interface ScratchTicketBackStockFormProps {
  products: ScratchTicketBackStockFormProduct[];
  returns: ScratchTicketBackStockFormReturn[];
  onProductsChange?: (value: ScratchTicketBackStockFormProduct[]) => any;
  onReturnsChange?: (value: ScratchTicketBackStockFormReturn[]) => any;
  onDoneClick?: () => any;
}

const ScratchTicketBackStockForm: React.FC<
  ScratchTicketBackStockFormProps
> = props => {
  const {
    products,
    returns,
    onProductsChange,
    onReturnsChange,
    onDoneClick,
    ...rest
  } = props;

  function handleProductChange(product: ScratchTicketBackStockFormProduct) {
    if (!onProductsChange) {
      return;
    }
    onProductsChange(
      replaceWhere(products, other => other.id === product.id, () => product),
    );
  }

  function handleReturnChange(product: ScratchTicketBackStockFormReturn) {
    if (!onReturnsChange) {
      return;
    }
    onReturnsChange(
      replaceWhere(returns, other => other.id === product.id, () => product),
    );
  }

  return (
    <FormBox
      {...rest}
      title="ScratchTicketBackStockForm : Form"
      onCloseClick={onDoneClick}
    >
      <TableWrapper>
        <TableTitle>Back Stock</TableTitle>
        <BackStockTable headers={['Date', 'Game Number', 'Pack Number']}>
          {products.map(product => (
            <tr key={product.id}>
              <td>
                <DateInput
                  value={product.date}
                  onValueChange={date =>
                    handleProductChange({ ...product, date })
                  }
                  data-testid={`productDateInput${product.id}`}
                />
              </td>
              <td>
                <NumberInput
                  value={product.gameNumber}
                  onValueChange={gameNumber =>
                    handleProductChange({ ...product, gameNumber })
                  }
                  data-testid={`productGameNumberInput${product.id}`}
                />
              </td>
              <td>
                <NumberInput
                  value={product.packNumber}
                  onValueChange={packNumber =>
                    handleProductChange({ ...product, packNumber })
                  }
                  data-testid={`productPackNumberInput${product.id}`}
                />
              </td>
            </tr>
          ))}
        </BackStockTable>
      </TableWrapper>
      <TableWrapper>
        <TableTitle>Returns</TableTitle>
        <ReturnsTable
          headers={['Date', 'Game Number', 'Pack Number', 'Ending Inventory']}
        >
          {returns.map(product => (
            <tr key={product.id}>
              <td>
                <DateInput
                  value={product.date}
                  onValueChange={date =>
                    handleReturnChange({ ...product, date })
                  }
                  data-testid={`returnDateInput${product.id}`}
                />
              </td>
              <td>
                <NumberInput
                  value={product.gameNumber}
                  onValueChange={gameNumber =>
                    handleReturnChange({ ...product, gameNumber })
                  }
                  data-testid={`returnGameNumberInput${product.id}`}
                />
              </td>
              <td>
                <NumberInput
                  value={product.packNumber}
                  onValueChange={packNumber =>
                    handleReturnChange({ ...product, packNumber })
                  }
                  data-testid={`returnPackNumberInput${product.id}`}
                />
              </td>
              <td>
                <NumberInput
                  value={product.endingInventory}
                  onValueChange={endingInventory =>
                    handleReturnChange({ ...product, endingInventory })
                  }
                  data-testid={`productEndingInventoryInput${product.id}`}
                />
              </td>
            </tr>
          ))}
        </ReturnsTable>
      </TableWrapper>
      <ButtonRow>
        <BlockButton onClick={onDoneClick} data-testid="doneButton">
          Done
        </BlockButton>
      </ButtonRow>
    </FormBox>
  );
};

export default styled(ScratchTicketBackStockForm)`
  width: 800px;
  margin: auto;
  ${NumberInput},
  ${DateInput} {
    width: 100%;
  }
`;

const TableTitle = styled('h2')`
  font-size: 24px;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 15px;
`;

const TableWrapper = styled(Column)`
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-bottom: 20px;
  align-self: flex-start;
  th {
    font-weight: normal;
    text-align: left;
    padding: 2px;
  }

  td,
  th {
    border-bottom: 1px solid black;
    padding: 2px;
  }

  th:nth-child(1) {
    width: 105px;
  }

  th:nth-child(2) {
    width: 100px;
  }

  th:last-child {
    width: 225px;
  }

  td:last-child {
    input {
      width: 110px;
    }
  }

  input {
    text-align: right;
    padding: 3px 5px;
    &[type='date'] {
      padding-right: 30px;
    }
  }
`;

const BackStockTable = styled(Table)`
  border-collapse: collapse;
  width: 600px;
`;

const ReturnsTable = styled(Table)`
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 700px;
  th:nth-child(3) {
    width: 100px;
  }

  th:last-child {
    width: 180px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 15px;
`;
