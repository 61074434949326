import { isElmers } from './isElmers';

const orStoreNames = new Set(
  [
    'DELTA',
    'SHELDON',
    'WEST 11',
    'COTTAGE GROVE',
    'FASTTRACK',
    'MOHAWK',
    'THURSTON',
    'PINEBROOK',
    'WAGNER',
    'REDMOND',
    'MAIN ST',
    'BENTO',
    'SOUTHTRAIL',
    'STEWART PKWAY',
    'GREEN',
    'SENECA',
    'BASELINE',
    'CORNELL',
    'TIGARD',
    'TV HWY',
    "DOOZY'S",
    'RALEIGH W',
    'SUNSET',
    'KEIZER',
    'CHELSICA',
    'MARKET',
    'CROSS RD',
    'MURRAY',
    'MEDO',
    'WINDY',
    'ALOHA',
    'FARMINGTON',
    'BG',
    'HALSEY',
    'DIVISION',
    'WILSONVILLE',
    `ORIGINAL JOE'S`,
    'Tyinn',
    'Marcola',
    'Garden',
    'Santiam',
    'J STREET',
    'RIVER CITY',
    'ROGUE',
    'CEDAR',
    'EAGLE LANDING',
    'ELM',
    'MOUNTAIN VIEW',
    'PINE',
    'CRATER LAKE',
    'LINN',
    'DELTA WATERS',
    'BIDDLE',
    'DELI BEAN',
    'ROBERTS',
    'SISKIYOU',
    'CENTRAL',
    'COTTONWOOD',
    'BARNETT',
    'HIGHLAND',
    'CHURCH',
    'COLEMAN CREEK',
    'ALBANY',
    'EUGENE',
    'GRANTS PASS',
    'MEDFORD',
    'S. MEDFORD',
  ].map(s => s.toUpperCase()),
);

export default function isOrStore(store: {
  storeName: string | null;
  storeId: number;
}): boolean {
  return (
    !!(store.storeName && orStoreNames.has(store.storeName.toUpperCase())) ||
    isElmers(store)
  );
}
