import { AxiosInstance } from 'axios';
import ItsReport from '../models/ItsReport';

export async function getLotteryReportData(
  axios: AxiosInstance,
  shiftDate: string,
  shiftNumber: number,
): Promise<ItsReport | null> {
  const response = await axios.get(
    `/lotteryReport/${shiftDate}/${shiftNumber}`,
  );
  return response.data.lotteryReportData;
}

export async function addOrUpdateLotteryReportData(
  axios: AxiosInstance,
  itsReport: ItsReport,
): Promise<void> {
  await axios.put('/lotteryReport', { itsReport });
}
