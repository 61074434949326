import { TubesPerRoll } from '../types/TubesPerRoll';
import {
  ALL_THREE_HUNDRED_BASE_TUBE_STORES,
  THREE_HUNDRED_BASE_TUBE_STORES,
} from '../constants/store/ThreeHundredBaseTubesStores';

export const DEFAULT_TUBES_PER_ROLL: TubesPerRoll = {
  onesPerRoll: 20,
  fivesPerRoll: 20,
  tensPerRoll: 10,
  twentiesPerRoll: 10,
  fiftiesPerRoll: 4,
  hundredsPerRoll: 2,
};

export const THREE_HUNDRED_BASE_TUBES_PER_ROLL: TubesPerRoll = {
  onesPerRoll: 20,
  fivesPerRoll: 20,
  tensPerRoll: 10,
  twentiesPerRoll: 15,
  fiftiesPerRoll: 6,
  hundredsPerRoll: 3,
};

function getThreeHundredEpochDateForStore(storeId: number): Date {
  const configForStore = THREE_HUNDRED_BASE_TUBE_STORES.find(({ storeIds }) =>
    storeIds.includes(storeId),
  );
  if (configForStore) {
    return configForStore.startDate;
  }
  // Should not get here, but this is a graceful fallback.
  return new Date();
}

export function getTubesPerRollForStore(
  storeId: number,
  date: string,
): TubesPerRoll {
  if (!ALL_THREE_HUNDRED_BASE_TUBE_STORES.includes(storeId)) {
    return DEFAULT_TUBES_PER_ROLL;
  }

  if (
    new Date(date).getTime() <
    getThreeHundredEpochDateForStore(storeId).getTime()
  ) {
    return DEFAULT_TUBES_PER_ROLL;
  }
  return THREE_HUNDRED_BASE_TUBES_PER_ROLL;
}
