import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import Spinner from 'react-spinners/ScaleLoader';
import debounce from 'lodash.debounce';
import Page, { PageComponent } from '../components/Page';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import FormBox from '../components/FormBox';
import { navigate } from '@reach/router';
import BlockButton from '../components/BlockButton';
import dateToInputValue from '../utils/dateToInputValue';
import { useEffect } from 'react';
import isValidDate from '../utils/isValidDate';
import Row from '../components/Row';

const InventoryExceptionReport: PageComponent = props => {
  const [reportDate, setReportDate] = useState<Date | null>(null);
  const [dateInputValue, setDateInputValue] = useState(() =>
    dateToInputValue(new Date()),
  );
  const [report, setReport] = useState<Array<{
    storeId: number;
    storeName: string;
    exception: string;
  }> | null>(null);
  const debounceSetReportDate = useMemo(() => debounce(setReportDate, 500), []);

  const close = () => navigate('/');

  useAsyncEffect(
    async isCancelled => {
      if (reportDate) {
        setReport(null);
        const fetchedReport = await Api.getInventoryExceptionReport(reportDate);
        if (!isCancelled()) {
          setReport(fetchedReport);
        }
      }
    },
    [reportDate],
  );

  useEffect(() => {
    const dateObject = new Date(dateInputValue);
    if (isValidDate(dateObject)) {
      debounceSetReportDate(current => {
        if (current && current.toISOString() === dateObject.toISOString()) {
          return current;
        }
        return dateObject;
      });
    }
  }, [dateInputValue, debounceSetReportDate]);

  return (
    <Page {...props}>
      <FormBox title="Inventory Exception Report" onCloseClick={close}>
        <TopRow>
          <label>By brand date</label>
          <input
            type="date"
            value={dateInputValue}
            onChange={event => setDateInputValue(event.currentTarget.value)}
          />
        </TopRow>
        <TableWrapper>
          {report ? (
            <table>
              <thead>
                <tr>
                  <th>Store</th>
                  <th>Exception</th>
                </tr>
              </thead>
              <tbody>
                {report.map(({ storeId, storeName, exception }) => (
                  <tr key={storeId}>
                    <td>{storeName}</td>
                    <td>{exception}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot />
            </table>
          ) : (
            <div className="spinner">
              <Spinner />
            </div>
          )}
        </TableWrapper>

        <BlockButton onClick={close}>Close</BlockButton>
      </FormBox>
    </Page>
  );
};

export default styled(withAuth(InventoryExceptionReport))`
  justify-content: center;
  align-items: center;

  ${FormBox} {
    width: 500px;
    height: 600px;
  }

  ${BlockButton} {
    width: 8rem;
    margin: 2rem auto 0;
  }

  th {
    text-align: left;
  }

  td,
  th {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const TopRow = styled(Row)`
  align-items: center;
  padding-bottom: 1rem;

  label {
    margin-right: 1rem;
  }

  input {
    flex-shrink: 0;
    width: 180px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }
`;

const TableWrapper = styled('div')`
  height: 600px;
  overflow: auto;
  position: relative;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
