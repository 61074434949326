import { AxiosInstance } from 'axios';
import W2G from '../models/W2G';

export async function getW2GReportData(
  axios: AxiosInstance,
  startDate: Date | string,
  endDate: Date | string,
): Promise<W2G[]> {
  const response = await axios.get(
    `/w2g/report/${new Date(startDate).toISOString()}/${new Date(
      endDate,
    ).toISOString()}`,
  );
  return response.data.w2gReportData;
}
