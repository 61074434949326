import { AxiosInstance } from 'axios';

export interface BrandInventoryRow {
  id: number;
  brandId: number;
  packCount: number;
  cartonCount: number;
  brandName: string;
  firstProductName: string;
  oldBrandId?: number | null;
  isPromo: boolean;
}

export interface BrandInventoryRowDraft {
  id: number;
  brandId: number;
  packCount: number | string;
  cartonCount: number | string;
  brandName: string;
  firstProductName: string;
  isPromo: boolean;
  oldBrandId?: number | null;
  isNew?: boolean;
}

export interface ActivePromo {
  promoName: string;
  brandId: number;
  price: number;
}

export async function getBrandInventoryData(
  axios: AxiosInstance,
  eopDate: string,
): Promise<BrandInventoryRow[]> {
  const response = await axios.get(`/brandInventory/${eopDate}`);
  const rows: BrandInventoryRow[] = response.data.brandInventoryData;
  return rows;
}

export async function getActivePromos(
  axios: AxiosInstance,
): Promise<ActivePromo[]> {
  const response = await axios.get('/activePromos');
  return response.data.activePromos;
}

export async function insertIntoEopTable(
  axios: AxiosInstance,
  row: {
    brandId: number;
    brandCount: number;
    brandCartonCount: number;
    eopDate: Date | string;
  },
): Promise<void> {
  await axios.post('/insertIntoEopTable', {
    ...row,
    eopDate: new Date(row.eopDate).toISOString(),
  });
}

export async function insertIntoEopTableMultiple(
  axios: AxiosInstance,
  rows: Array<{
    brandId: number;
    brandCount: number;
    brandCartonCount: number;
    eopDate: Date | string;
  }>,
): Promise<void> {
  await axios.post(
    '/insertIntoEopTable/many',
    rows.map(row => ({
      ...row,
      eopDate: new Date(row.eopDate).toISOString(),
    })),
  );
}

export async function insertIntoEopTableOther(
  axios: AxiosInstance,
  props: {
    brandId: number;
    brandCount: number;
    brandCartonCount: number;
    eopDate: Date | string;
  },
): Promise<void> {
  await axios.post('/insertIntoEopTableOther', {
    ...props,
    eopDate: new Date(props.eopDate).toISOString(),
  });
}

export async function getBrandInventoryTotals(
  axios: AxiosInstance,
  {
    eopDate,
    bluPackCount,
  }: {
    eopDate: Date | string;
    bluPackCount: number;
  },
): Promise<{
  closingTotal: number;
  closingBluTotal: number;
  grandTotal: number;
}> {
  const response = await axios.post('/getBrandInventoryTotals', {
    eopDate: new Date(eopDate).toISOString(),
    bluPackCount,
  });
  return response.data;
}
