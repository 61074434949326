import { useEffect } from 'react';

type Layout = 'portrait' | 'landscape';

let portraitMounted = false;
let landscapeMounted = false;
const portraitStyles = getPrintStyle('portrait');
const landscapeStyles = getPrintStyle('landscape');

export default function usePrintLayout(layout: Layout): void {
  useEffect(() => {
    const priorPortraitMounted = portraitMounted;
    const priorLandscapeMounted = landscapeMounted;
    if (layout === 'portrait') {
      unmountLandscape();
      mountPortrait();
    } else {
      unmountPortrait();
      mountLandscape();
    }
    return () => {
      if (layout === 'portrait') {
        unmountPortrait();
      } else {
        unmountLandscape();
      }
      if (priorLandscapeMounted) {
        mountLandscape();
      }
      if (priorPortraitMounted) {
        mountPortrait();
      }
    };
  }, [layout]);
}

function getPrintStyle(layout: Layout): HTMLStyleElement {
  const css = `@page { size: ${layout}; }`;
  const style = document.createElement('style');

  style.type = 'text/css';
  style.media = 'print';

  if ((style as any).styleSheet) {
    (style as any).styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }

  return style;
}

function mountLandscape() {
  if (!landscapeMounted) {
    document.head.appendChild(landscapeStyles);
    landscapeMounted = true;
  }
}

function mountPortrait() {
  if (!portraitMounted) {
    document.head.appendChild(portraitStyles);
    portraitMounted = true;
  }
}

function unmountLandscape() {
  if (landscapeMounted) {
    document.head.removeChild(landscapeStyles);
    landscapeMounted = false;
  }
}

function unmountPortrait() {
  if (portraitMounted) {
    document.head.removeChild(portraitStyles);
    portraitMounted = false;
  }
}
