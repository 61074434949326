import React, { MouseEvent } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Form from './Form';
import InputRow from './InputRow';
import Input from './Input';
import BlockButton from './BlockButton';
import Row from './Row';
import { FaPrint } from './icons';
import DateInput from './DateInput';
import TextArea from './TextArea';
import MoneyInput from './MoneyInput';
import NumberInput from './NumberInput';
import FormBox from './FormBox';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';
import PaginationRow from './PaginationRow';
import { isElmers } from '../utils/isElmers';
import { hideByDisplayNone } from '../utils/hideable';

export interface VideoDepositLogRmFormProps {
  storeId: number;
  employeeName: string;
  value: VideoDepositLog;
  videoDepositLogs: VideoDepositLog[];
  onSave?(value: VideoDepositLog): any;
  onChange?(value: VideoDepositLog): any;
  onCloseClick?(value: VideoDepositLog): any;
  onPrintClick?(value: VideoDepositLog): any;
  onVideoDepositLogChange?(id: number): any;
}

const VideoDepositLogRmForm: React.FC<VideoDepositLogRmFormProps> = props => {
  const {
    employeeName,
    videoDepositLogs,
    onSave = noop,
    onChange = noop,
    onCloseClick = noop,
    onPrintClick = noop,
    onVideoDepositLogChange = noop,
    value,
    storeId,
    ...rest
  } = props;

  const name =
    (value.regionalManagerName || '').length === 0
      ? employeeName
      : value.regionalManagerName;

  const pageCount = videoDepositLogs.length;
  let pageNumber = videoDepositLogs.findIndex(vdl => vdl.id === value.id);

  if (pageNumber === -1) {
    pageNumber = 0;
  }

  function handleSubmit() {
    onPrintClick(value);
  }

  function handleCloseClick(event: MouseEvent<HTMLButtonElement> | null) {
    if (event) {
      event.preventDefault();
    }
    onCloseClick(value);
  }

  const elmers = isElmers(storeId);

  return (
    <FormBox
      {...rest}
      title="Video Deposit Log RM input"
      onCloseClick={() => handleCloseClick(null)}
    >
      <VdlForm onSubmit={handleSubmit} data-testid="form">
        <Row>
          <InputColumn>
            <InputRow>
              <label>Date:</label>
              <Input
                type="date"
                value={value.dateRm ? value.dateRm.slice(0, 10) : ''}
                onValueChange={date =>
                  onChange({ ...value, dateRm: date + 'T00:00:00.000Z' })
                }
              />
            </InputRow>
            <InputRow>
              <label>Regional Manager Name:</label>
              <Input
                value={name}
                onValueChange={regionalManagerName =>
                  onChange({ ...value, regionalManagerName })
                }
                data-testid="managerNameInput"
              />
            </InputRow>
            <InputRow>
              <label>Regional Manager Deposit:</label>
              <MoneyInput
                value={value.depositAmount || 0}
                onValueChange={depositAmount =>
                  onChange({ ...value, depositAmount })
                }
                data-testid="managerDepositInput"
              />
            </InputRow>
            <InputRow>
              <label>Bank Bag Number:</label>
              <NumberInput
                value={value.bankBagNumberRm || 0}
                onValueChange={bankBagNumberRm =>
                  onChange({ ...value, bankBagNumberRm })
                }
                data-testid="bankBagNumberInput"
              />
            </InputRow>
            <InputRow hide={elmers}>
              <label>RM Bank Correction:</label>
              <NumberInput
                value={value.rmBankCorrection || 0}
                onValueChange={rmBankCorrection =>
                  onChange({ ...value, rmBankCorrection })
                }
                data-testid="rmBankCorrectionInput"
              />
            </InputRow>
          </InputColumn>
          <InputColumn>
            <label>Safe Cassette Notes:</label>
            <TextArea
              value={value.rmNotes || ''}
              onValueChange={rmNotes => onChange({ ...value, rmNotes })}
              data-testid="cassetteNotesInput"
            />
            <AtmNotesColumn hide={elmers}>
              <label>ATM Notes:</label>
              <TextArea
                value={value.rmatmNotes || ''}
                onValueChange={rmatmNotes => onChange({ ...value, rmatmNotes })}
                data-testid="atmNotesInput"
              />
            </AtmNotesColumn>
          </InputColumn>
        </Row>
        <ButtonRow>
          <BlockButton data-testid="printButton">
            <FaPrint />
            Print
          </BlockButton>
          <BlockButton onClick={handleCloseClick} data-testid="closeButton">
            Save and Close
          </BlockButton>
        </ButtonRow>
      </VdlForm>
      <PaginationRow
        page={pageNumber}
        pageCount={pageCount}
        onChange={async newPageNumber => {
          await onSave(value);
          const newVdl = videoDepositLogs[newPageNumber];
          if (newVdl) {
            onVideoDepositLogChange(newVdl.id);
          }
        }}
        paginationStrategy="left-increase"
      />
    </FormBox>
  );
};

export default styled(VideoDepositLogRmForm)`
  ${PaginationRow} {
    margin-top: 20px;
    @media print {
      display: none;
    }
  }
`;

const VdlForm = styled(Form)`
  ${BlockButton} {
    flex-direction: column;
    margin-top: 20px;
    width: 95px;
    min-width: 95px;
    margin-right: 100px;
    padding: 0;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 10px;
  }
  ${NumberInput}, ${MoneyInput},  ${Input}, ${DateInput} {
     height: 25px;
     width: 180px;
     text-align: left;
     padding: 0;
   }

   ${TextArea} {
     padding: 5px;
     height: 70px;
   }
`;

const InputColumn = styled(Column)`
  flex: 1;

  &:first-child {
    padding-right: 5px;
  }

  &:last-child {
    padding-left: 5px;
  }
`;

const ButtonRow = styled(Row)`
  margin-top: 100px;
  margin-left: 150px;
  @media print {
    display: none;
  }
`;

const AtmNotesColumn = styled(Column)<{ hide: boolean }>`
  ${hideByDisplayNone}
`;
