import React from 'react';
import styled from 'styled-components/macro';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?: (value: string) => any;
}

const TextArea: React.FC<TextAreaProps> = props => {
  const { onValueChange, onChange, ...rest } = props;

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (onChange) {
      onChange(event);
    }
    if (onValueChange) {
      onValueChange(event.currentTarget.value);
    }
  }

  return <textarea {...rest} onChange={handleChange} />;
};

/**
 * Generic text area
 */
export default styled(TextArea)`
  background-color: white;
  outline: none;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #ddd;
  border-right: 2px solid #ddd;
  resize: none;
  padding: 20px;
  font-size: 16px;
`;
