import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import debounce from 'lodash/debounce';
import Page, { PageComponent } from '../components/Page';
import GuestPlayForm from '../components/GuestPlayForm';
import Column from '../components/Column';
import withAuth from '../components/withAuth';
import useAsyncEffect from '../utils/useAsyncEffect';
import GuestPlay from '../models/GuestPlay';
import Api from '../api/Api';
import logError from '../utils/logError';
import SpinnerOverlay from '../components/SpinnerOverlay';

const GuestPlayPage: PageComponent = props => {
  const [guestPlay, setGuestPlay] = useState<GuestPlay | null>(null);

  const saveGuestPlay = useMemo(
    () =>
      debounce(async (value: GuestPlay) => {
        try {
          await Api.updateGuestPlay(value);
        } catch (error) {
          logError(error);
          window.alert('Failed to save guest play');
        }
      }, 500),
    [],
  );

  function handleGuestPlayChange(value: GuestPlay) {
    setGuestPlay(value);
    saveGuestPlay(value);
  }

  useAsyncEffect(async () => {
    setGuestPlay(await Api.createGuestPlay());
  }, []);

  if (!guestPlay) {
    // loading
    return <SpinnerOverlay />;
  }

  return (
    <Page {...props}>
      <FormWrapper>
        <GuestPlayForm value={guestPlay} onChange={handleGuestPlayChange} />
      </FormWrapper>
    </Page>
  );
};

export default styled(withAuth(GuestPlayPage))`
  justify-content: center;
  align-items: center;
`;

const FormWrapper = styled(Column)``;
