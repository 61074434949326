import { AxiosInstance } from 'axios';
import VideoDepositLog from '../models/VideoDepositLog';

export async function getAuditSafeReportData(
  axios: AxiosInstance,
  startDate: Date | string,
  endDate: Date | string,
): Promise<VideoDepositLog[]> {
  const response = await axios.get(
    `/auditSafeReport/${new Date(startDate).toISOString()}/${new Date(
      endDate,
    ).toISOString()}`,
  );
  return response.data.auditSafeReportData;
}
