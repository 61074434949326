import padZero from './padZero';

export default function todayISO() {
  const now = new Date();
  const years = now.getFullYear();
  const months = padZero(now.getMonth() + 1);
  const day = padZero(now.getDate());

  return `${years}-${months}-${day}T00:00:00.000`;
}
