import React from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import InputRow from './InputRow';
import Input from './Input';
import Select from './Select';
import DateInput from './DateInput';
import NullableCheckbox from './NullableCheckbox';
import TextArea from './TextArea';
import FormBox from './FormBox';
import GuestPlay from '../models/GuestPlay';
import noop from '../utils/noop';

export interface GuestPlayFormProps {
  value: GuestPlay;
  onChange?: (value: GuestPlay) => any;
}

const GuestPlayForm: React.FC<GuestPlayFormProps> = props => {
  const { value, onChange = noop, ...rest } = props;

  function handleFirstNameChange(firstName: string) {
    onChange({ ...value, firstName });
  }

  function handleLastNameChange(lastName: string) {
    onChange({ ...value, lastName });
  }

  function handleAddressChange(address: string) {
    onChange({ ...value, address });
  }

  function handleCityChange(city: string) {
    onChange({ ...value, city });
  }

  function handleStateChange(state: string) {
    onChange({ ...value, state });
  }

  function handleZipChange(zip: string) {
    onChange({ ...value, zip });
  }

  function handlePhoneChange(phone: string) {
    onChange({ ...value, phone });
  }

  function handleEmailChange(email: string) {
    onChange({ ...value, email });
  }

  function handlePlayFrequencyChange(frequency: string) {
    onChange({ ...value, frequency: +frequency });
  }

  function handleBirthdayChange(dob: Date) {
    onChange({ ...value, dob });
  }

  function handleTextMessagesChange(textMessages: boolean) {
    onChange({ ...value, textMessages: textMessages ? 1 : 0 });
  }

  function handleTobaccoChange(tobacco: boolean) {
    onChange({ ...value, tobacco: tobacco ? 1 : 0 });
  }

  function handleFoodChange(food: boolean) {
    onChange({ ...value, food: food ? 1 : 0 });
  }

  function handleLottoChange(lotto: boolean) {
    onChange({ ...value, lotto: lotto ? 1 : 0 });
  }

  function handleNotesChange(notes: string) {
    onChange({ ...value, notes });
  }

  return (
    <FormBox {...rest} title="GuestPlayQuery">
      <h1>Guest Play</h1>
      <Form>
        <InputRow>
          <label>Store ID:</label>
          <IdInput disabled value={value.storeId} />
        </InputRow>

        <InputRow>
          <label>First Name:</label>
          <Input
            value={value.firstName || ''}
            onValueChange={handleFirstNameChange}
            data-testid="firstName-input"
          />
        </InputRow>

        <InputRow>
          <label>Last Name:</label>
          <Input
            value={value.lastName || ''}
            onValueChange={handleLastNameChange}
            data-testid="lastName-input"
          />
        </InputRow>

        <InputRow>
          <label>Address:</label>
          <StreetAddressInput
            value={value.address || ''}
            onValueChange={handleAddressChange}
            data-testid="address-input"
          />
        </InputRow>

        <AddressRow>
          <label>City</label>
          <CityInput
            value={value.city || ''}
            onValueChange={handleCityChange}
            data-testid="city-input"
          />
          <label>State</label>
          <StateInput
            value={value.state || ''}
            onValueChange={handleStateChange}
            data-testid="state-input"
          />
          <label>Zip</label>
          <ZipInput
            value={value.zip || ''}
            onValueChange={handleZipChange}
            data-testid="zip-input"
          />
        </AddressRow>

        <InputRow>
          <label>Phone:</label>
          <Input
            value={value.phone || ''}
            onValueChange={handlePhoneChange}
            data-testid="phone-input"
          />
        </InputRow>

        <InputRow>
          <label>Email</label>
          <Input
            value={value.email || ''}
            onValueChange={handleEmailChange}
            data-testid="email-input"
          />
        </InputRow>

        <InputRow>
          <label>Frequency of Play</label>
          <Select
            value={value.frequency || ''}
            onValueChange={handlePlayFrequencyChange}
            data-testid="playFrequency-select"
          >
            <option value="" disabled>
              Play frequency
            </option>
            <option value="1">Once / Week</option>
            <option value="3">3 Times / Week</option>
            <option value="5">5+ Times / Week</option>
          </Select>
        </InputRow>

        <InputRow>
          <label>Date of Birth</label>
          <DateInput
            value={value.dob || ''}
            required // Needed to supress the browser-provided x button
            onValueChange={handleBirthdayChange}
            data-testid="birthday-input"
          />
        </InputRow>

        <CheckboxRow>
          <NullableCheckbox
            checked={value.textMessages !== 0}
            onValueChange={handleTextMessagesChange}
            data-testid="textMessages-checkbox"
          />
          <label>Text Messages?</label>

          <NullableCheckbox
            checked={value.tobacco !== 0}
            onValueChange={handleTobaccoChange}
            data-testid="tobacco-checkbox"
          />
          <label>Tobacco</label>

          <NullableCheckbox
            checked={value.food !== 0}
            onValueChange={handleFoodChange}
            data-testid="food-checkbox"
          />
          <label>Food</label>

          <NullableCheckbox
            checked={value.lotto !== 0}
            onValueChange={handleLottoChange}
            data-testid="lotto-checkbox"
          />
          <label>Lotto</label>
        </CheckboxRow>

        <NotesRow>
          <label>Notes</label>
          <TextArea
            value={value.notes || ''}
            onValueChange={handleNotesChange}
            data-testid="notes-input"
          />
        </NotesRow>
      </Form>
    </FormBox>
  );
};

const IdInput = styled(Input)`
  width: 75px;
`;

const StreetAddressInput = styled(Input)`
  width: 300px;
`;

const CityInput = styled(Input)`
  width: 100px;
`;

const StateInput = styled(Input)`
  width: 75px;
`;

const ZipInput = styled(Input)`
  width: 75px;
`;

const AddressRow = styled(InputRow)`
  ${Input} + label {
    margin-left: 10px;
    margin-right: 30px;
    width: auto;
    min-width: 40px;
  }
`;

const CheckboxRow = styled(InputRow)`
  justify-content: flex-start;

  ${NullableCheckbox} {
    margin-right: 20px;
  }

  label {
    margin-right: 20px;
  }
`;

const NotesRow = styled(InputRow)`
  textarea {
    width: 310px;
  }
`;

export default styled(GuestPlayForm)`
  width: 800px;
  margin: auto;
  h1 {
    text-align: left;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  ${InputRow} {
    padding-left: 5px;
    justify-content: flex-start;
  }

  ${CheckboxRow} {
    padding-left: 0;
  }

  ${DateInput},
  ${Input} {
    height: 25px;
  }

  label {
    width: 150px;
  }

  ${NullableCheckbox} {
    margin-right: 10px;
  }

  ${NullableCheckbox} + label {
    width: auto;
  }

  label > input:not([type='checkbox']) {
    margin-left: 20px;
  }
`;
