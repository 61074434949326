import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import NumberInput from './NumberInput';
import Row from './Row';
import VideoDepositLog from '../models/VideoDepositLog';
import noop from '../utils/noop';

export interface AddCashReportNumbersFormProps {
  value: VideoDepositLog;
  balanced: boolean;
  totalLoadQuantity: number;
  onChange?: (value: VideoDepositLog) => any;
}

const AddCashReportNumbersForm: React.FC<
  AddCashReportNumbersFormProps
> = props => {
  const {
    value,
    onChange = noop,
    balanced,
    totalLoadQuantity,
    ...rest
  } = props;

  return (
    <Column {...rest}>
      <h3>*Add Cash* Report Numbers</h3>
      <Row>
        <LabelColumn>
          <Row>
            <label>Remaining Count:</label>
          </Row>
          <AdditionCount>
            <label>Addition Count</label>
          </AdditionCount>
          <TotalQuantityLabel>
            Total Load QTY to Cassette in ATM:
          </TotalQuantityLabel>
        </LabelColumn>
        <InputColumn>
          <Row>
            <RemainingCountInput
              value={value.atmRemainingCount || 0}
              onValueChange={atmRemainingCount =>
                onChange({ ...value, atmRemainingCount })
              }
              data-testid="remainingCountInput"
            />
          </Row>
          <Row>
            <NumberInput
              value={value.atmAdditionCount || 0}
              onValueChange={atmAdditionCount =>
                onChange({ ...value, atmAdditionCount })
              }
              data-testid="additionCountInput"
            />
            <BalancingLabel red={!balanced}>
              {balanced ? 'Balanced' : 'UnBalanced'}
            </BalancingLabel>
          </Row>
          <Row>
            <TotalQuantityInput
              readOnly
              value={totalLoadQuantity}
              data-testid="totalLoadCountInput"
            />
          </Row>
        </InputColumn>
      </Row>
    </Column>
  );
};

export default styled(AddCashReportNumbersForm)`
  > input,
  ${NumberInput} {
    height: 25px;
    text-align: right;
    padding: 0;
  }

  h3 {
    width: 140px;
    margin-bottom: 10px;
  }
`;

const BalancingLabel = styled(Column)<{ red: boolean }>`
  background-color: ${props => (props.red ? '#F00' : '#ffffff')};
  text-align: left;
  justify-content: center;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  width: 82px;
`;

const TotalQuantityLabel = styled.label`
  width: 180px;
`;

const RemainingCountInput = styled(NumberInput)``;

const AdditionCount = styled(InputRow)`
  margin-bottom: 0;
`;

const TotalQuantityInput = styled(NumberInput)`
  &&& {
    text-align: left;
    width: 160px;
  }
`;

const LabelColumn = styled(Column)`
  ${Row} {
    margin-bottom: 14px;
  }
  > :last-child {
    margin-bottom: 30px;
  }
`;

const InputColumn = styled(Column)`
  ${Row} {
    margin-bottom: 5px;
  }
  > :last-child {
    margin-top: 5px;
  }
`;
