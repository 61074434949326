export default interface ScratchTicketDetails {
  amountSold: number | null;
  beginningInventory: number | null;
  endingInventory: number | null;
  gameNumber: number | null;
  outFlag: number | null;
  replacementFlag: number;
  shift: number;
  shiftDate: string;
  slot: number;
  storeId: number;
  value?: number | null;
}

export interface ScratchTicketDetailsDraft {
  amountSold: string | number | null;
  beginningInventory: string | number | null;
  endingInventory: string | number | null;
  gameNumber: string | number | null;
  outFlag: string | number | null;
  replacementFlag: string | number;
  shift: string | number;
  shiftDate: string;
  slot: string | number;
  storeId: string | number;
  value?: number | null;
}

export function scratchTicketDetailsFromDraft(
  draft: ScratchTicketDetailsDraft,
): ScratchTicketDetails {
  return {
    amountSold: draft.amountSold === null ? null : +draft.amountSold,
    beginningInventory:
      draft.beginningInventory === null ? null : +draft.beginningInventory,
    endingInventory:
      draft.endingInventory === null ? null : +draft.endingInventory,
    gameNumber: draft.gameNumber === null ? null : +draft.gameNumber,
    outFlag: draft.outFlag === null ? null : +draft.outFlag,
    replacementFlag: +draft.replacementFlag,
    shift: +draft.shift,
    shiftDate: draft.shiftDate,
    slot: +draft.slot,
    storeId: +draft.storeId,
    value: draft.value,
  };
}
