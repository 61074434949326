import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Row from './Row';
import BlockButton from './BlockButton';
import { FaEdit, FaPrint, FaSignOutAlt } from './icons';
import FormBox from './FormBox';

export interface CheckForErrorsFormProps {
  onRunClick?: () => any;
  onPrintClick?: () => any;
  onReturnClick?: () => any;
}

const CheckForErrorsForm: React.FC<CheckForErrorsFormProps> = props => {
  const { onRunClick, onPrintClick, onReturnClick, ...rest } = props;

  return (
    <FormBox
      {...rest}
      title="CheckForErrors : Form"
      onCloseClick={onReturnClick}
    >
      <Row>
        <ButtonColumn>
          Run Error Check and View Log
          <BlockButton onClick={onRunClick} data-testid="runButton">
            <FaEdit size="1.5em" />
          </BlockButton>
        </ButtonColumn>
        <ButtonColumn>
          Print Audit Error Log
          <BlockButton onClick={onPrintClick} data-testid="printButton">
            <FaPrint size="1.5em" />
          </BlockButton>
        </ButtonColumn>
      </Row>
      <ButtonColumn>
        Return to Switchboard
        <BlockButton onClick={onReturnClick} data-testid="returnButton">
          <FaSignOutAlt size="1.5em" />
        </BlockButton>
      </ButtonColumn>
    </FormBox>
  );
};

export default styled(CheckForErrorsForm)`
  width: 600px;
  max-width: 100%;
  

  ${BlockButton} {
    padding: 10px;
    margin-top: 10px;
    min-width: unset;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 50px;
  }

  > ${Row}:last-child {
    margin-top: 50px;
    justify-content: center;
  }
`;

const ButtonColumn = styled(Column)`
  flex: 0 50%;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;
