import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import InputRow from './InputRow';
import Row from './Row';
import NumberInput from './NumberInput';
import MoneyInput from './MoneyInput';
import BlockButton from './BlockButton';
import DateInput from './DateInput';
import FormBox from './FormBox';
import PricexRefTable from '../models/PricexRefTable';
import Brandsales from '../models/Brandsales';
import noop from '../utils/noop';
import replaceWhere from '../utils/replaceWhere';
import useAsyncEffect from '../utils/useAsyncEffect';
import Api from '../api/Api';
import Input from './Input';

interface Result {
  sumOfPacks: number;
  sumOfCartons: number;
  blupack: number;
  tobaccoPack: number;
  tobaccoCarton: number;
  tobaccoBlu: number;
}

export interface CigSalesFormProps {
  storeId: number;
  shiftDate: string;
  shiftNumber: number;
  readOnly: boolean;
  onSubmit?: (result: Result) => any;
  onCancel?: () => any;
}

const CigSalesForm: React.FC<CigSalesFormProps> = props => {
  const [brandSales, setBrandSales] = useState<Brandsales[] | null>(null);
  const [cigPrices, setCigPrices] = useState<PricexRefTable | null>(null);
  const {
    storeId,
    shiftDate,
    shiftNumber,
    readOnly,
    onSubmit = noop,
    onCancel = noop,
    ...rest
  } = props;

  useAsyncEffect(async isCancelled => {
    const [fetchedCigPrices, fetchedBrandSales] = await Promise.all([
      Api.getCigPrices(),
      Api.getShiftBrandSales(shiftDate, +shiftNumber),
    ]);

    if (!isCancelled()) {
      if (!fetchedCigPrices) {
        window.alert(
          `You must set this store's cigarette prices in the PricexRefTable before you can do that.`,
        );
        onCancel();
        return;
      }
      setBrandSales(fetchedBrandSales);
      setCigPrices(fetchedCigPrices);
    }
  }, []);

  if (!brandSales || !cigPrices) {
    return null;
  }

  const totalSales =
    (cigPrices.pack1Price || 0) * getProductSaleCount(cigPrices.pack1Id, true) +
    (cigPrices.pack2Price || 0) * getProductSaleCount(cigPrices.pack2Id, true) +
    (cigPrices.pack3Price || 0) * getProductSaleCount(cigPrices.pack3Id, true) +
    (cigPrices.pack4Price || 0) * getProductSaleCount(cigPrices.pack4Id, true) +
    (cigPrices.pack5Price || 0) * getProductSaleCount(cigPrices.pack5Id, true) +
    (cigPrices.pack6Price || 0) * getProductSaleCount(cigPrices.pAck6Id, true) +
    (cigPrices.pack7Price || 0) * getProductSaleCount(cigPrices.pAck7Id, true) +
    (cigPrices.pack8Price || 0) * getProductSaleCount(cigPrices.pack8Id, true) +
    (cigPrices.pack9Price || 0) * getProductSaleCount(cigPrices.pack9Id, true) +
    (cigPrices.pack10Price || 0) *
      getProductSaleCount(cigPrices.pack10Id, true) +
    (cigPrices.pack11Price || 0) *
      getProductSaleCount(cigPrices.pack11Id, true) +
    (cigPrices.pack12Price || 0) *
      getProductSaleCount(cigPrices.pack12Id, true) +
    (cigPrices.pack13Price || 0) *
      getProductSaleCount(cigPrices.pack13Id, true) +
    (cigPrices.pack14Price || 0) *
      getProductSaleCount(cigPrices.pack14Id, true) +
    (cigPrices.pack15Price || 0) *
      getProductSaleCount(cigPrices.pack15Id, true) +
    (cigPrices.pack16Price || 0) *
      getProductSaleCount(cigPrices.pack16Id, true) +
    (cigPrices.pack17Price || 0) *
      getProductSaleCount(cigPrices.pack17Id, true) +
    (cigPrices.pack18Price || 0) *
      getProductSaleCount(cigPrices.pack18Id, true) +
    (cigPrices.pack19Price || 0) *
      getProductSaleCount(cigPrices.pack19Id, true) +
    (cigPrices.pack20Price || 0) *
      getProductSaleCount(cigPrices.pack20Id, true) +
    (cigPrices.pack21Price || 0) *
      getProductSaleCount(cigPrices.pack21Id, true) +
    (cigPrices.pack22Price || 0) *
      getProductSaleCount(cigPrices.pack22Id, true) +
    (cigPrices.pack23Price || 0) *
      getProductSaleCount(cigPrices.pack23Id, true) +
    (cigPrices.pack24Price || 0) *
      getProductSaleCount(cigPrices.pack24Id, true) +
    (cigPrices.pack25Price || 0) *
      getProductSaleCount(cigPrices.pack25Id, true) +
    (cigPrices.carton1Price || 0) *
      getProductSaleCount(cigPrices.carton1Id, false) +
    (cigPrices.carton2Price || 0) *
      getProductSaleCount(cigPrices.carton2Id, false) +
    (cigPrices.carton3Price || 0) *
      getProductSaleCount(cigPrices.carton3Id, false) +
    (cigPrices.carton4Price || 0) *
      getProductSaleCount(cigPrices.carton4Id, false) +
    (cigPrices.carton5Price || 0) *
      getProductSaleCount(cigPrices.carton5Id, false) +
    (cigPrices.carton6Price || 0) *
      getProductSaleCount(cigPrices.carton6Id, false) +
    (cigPrices.carton7Price || 0) *
      getProductSaleCount(cigPrices.carton7Id, false) +
    (cigPrices.carton8Price || 0) *
      getProductSaleCount(cigPrices.carton8Id, false) +
    (cigPrices.carton9Price || 0) *
      getProductSaleCount(cigPrices.carton9Id, false) +
    (cigPrices.carton10Price || 0) *
      getProductSaleCount(cigPrices.carton10Id, false) +
    (cigPrices.carton11Price || 0) *
      getProductSaleCount(cigPrices.carton11Id, false) +
    (cigPrices.carton12Price || 0) *
      getProductSaleCount(cigPrices.carton12Id, false) +
    (cigPrices.carton13Price || 0) *
      getProductSaleCount(cigPrices.carton13Id, false) +
    (cigPrices.carton14Price || 0) *
      getProductSaleCount(cigPrices.carton14Id, false) +
    (cigPrices.carton15Price || 0) *
      getProductSaleCount(cigPrices.carton15Id, false) +
    (cigPrices.carton16Price || 0) *
      getProductSaleCount(cigPrices.carton16Id, false) +
    (cigPrices.carton17Price || 0) *
      getProductSaleCount(cigPrices.carton17Id, false) +
    (cigPrices.carton18Price || 0) *
      getProductSaleCount(cigPrices.carton18Id, false) +
    (cigPrices.carton19Price || 0) *
      getProductSaleCount(cigPrices.carton19Id, false) +
    (cigPrices.carton20Price || 0) *
      getProductSaleCount(cigPrices.carton20Id, false) +
    (cigPrices.carton21Price || 0) *
      getProductSaleCount(cigPrices.carton21Id, false) +
    (cigPrices.carton22Price || 0) *
      getProductSaleCount(cigPrices.carton22Id, false) +
    (cigPrices.carton23Price || 0) *
      getProductSaleCount(cigPrices.carton23Id, false) +
    (cigPrices.carton24Price || 0) *
      getProductSaleCount(cigPrices.carton24Id, false);

  function getProductSaleCount(
    brandId: number | null,
    isPack: boolean,
  ): number {
    if (brandId === 0 || brandId === null || !brandSales) {
      return 0;
    }
    const brandSale = brandSales.find(
      sale => sale.brandId === brandId && sale.pack === (isPack ? 1 : 0),
    );

    if (brandSale) {
      return brandSale.count || 0;
    }
    return 0;
  }

  function handleBrandCountChange(
    brandId: number | null,
    isPack: boolean,
    count: number,
  ) {
    if (!brandId || !brandSales) {
      return;
    }
    if (count < 0) {
      count = 0;
    }
    const existingBrandSale = brandSales.find(
      brandSale => brandSale.brandId === brandId && !!brandSale.pack === isPack,
    );

    if (existingBrandSale) {
      const newbrandSales = replaceWhere(
        brandSales,
        brandSale => brandSale === existingBrandSale,
        brandSale => ({
          ...brandSale,
          count,
        }),
      );
      setBrandSales(newbrandSales);
    } else {
      const newBrandSales = [
        ...brandSales,
        {
          brandId,
          count,
          shiftDate,
          storeId,
          pack: isPack ? 1 : 0,
          shift: shiftNumber,
          timestamp: new Date().toISOString(),
        },
      ];
      setBrandSales(newBrandSales);
    }
  }

  async function handleDoneClick() {
    if (!brandSales || !cigPrices) {
      return;
    }
    const sumOfPacks = brandSales
      .filter(sale => !!sale.pack && sale.brandId !== cigPrices.pack25Id)
      .reduce((total, sale) => total + (sale.count || 0), 0);
    const sumOfCartons = brandSales
      .filter(sale => !sale.pack)
      .reduce((total, sale) => total + (sale.count || 0), 0);
    const blupack = brandSales.find(
      sale => sale.pack !== 0 && sale.brandId === cigPrices.pack25Id,
    );
    const tobaccoPackTotal =
      (cigPrices.pack1Price || 0) *
        getProductSaleCount(cigPrices.pack1Id, true) +
      (cigPrices.pack2Price || 0) *
        getProductSaleCount(cigPrices.pack2Id, true) +
      (cigPrices.pack3Price || 0) *
        getProductSaleCount(cigPrices.pack3Id, true) +
      (cigPrices.pack4Price || 0) *
        getProductSaleCount(cigPrices.pack4Id, true) +
      (cigPrices.pack5Price || 0) *
        getProductSaleCount(cigPrices.pack5Id, true) +
      (cigPrices.pack6Price || 0) *
        getProductSaleCount(cigPrices.pAck6Id, true) +
      (cigPrices.pack7Price || 0) *
        getProductSaleCount(cigPrices.pAck7Id, true) +
      (cigPrices.pack8Price || 0) *
        getProductSaleCount(cigPrices.pack8Id, true) +
      (cigPrices.pack9Price || 0) *
        getProductSaleCount(cigPrices.pack9Id, true) +
      (cigPrices.pack10Price || 0) *
        getProductSaleCount(cigPrices.pack10Id, true) +
      (cigPrices.pack11Price || 0) *
        getProductSaleCount(cigPrices.pack11Id, true) +
      (cigPrices.pack12Price || 0) *
        getProductSaleCount(cigPrices.pack12Id, true) +
      (cigPrices.pack13Price || 0) *
        getProductSaleCount(cigPrices.pack13Id, true) +
      (cigPrices.pack14Price || 0) *
        getProductSaleCount(cigPrices.pack14Id, true) +
      (cigPrices.pack15Price || 0) *
        getProductSaleCount(cigPrices.pack15Id, true) +
      (cigPrices.pack16Price || 0) *
        getProductSaleCount(cigPrices.pack16Id, true) +
      (cigPrices.pack17Price || 0) *
        getProductSaleCount(cigPrices.pack17Id, true) +
      (cigPrices.pack18Price || 0) *
        getProductSaleCount(cigPrices.pack18Id, true) +
      (cigPrices.pack19Price || 0) *
        getProductSaleCount(cigPrices.pack19Id, true) +
      (cigPrices.pack20Price || 0) *
        getProductSaleCount(cigPrices.pack20Id, true) +
      (cigPrices.pack21Price || 0) *
        getProductSaleCount(cigPrices.pack21Id, true) +
      (cigPrices.pack22Price || 0) *
        getProductSaleCount(cigPrices.pack22Id, true) +
      (cigPrices.pack23Price || 0) *
        getProductSaleCount(cigPrices.pack23Id, true) +
      (cigPrices.pack24Price || 0) *
        getProductSaleCount(cigPrices.pack24Id, true);

    const tobaccoCartonTotal =
      (cigPrices.carton1Price || 0) *
        getProductSaleCount(cigPrices.carton1Id, false) +
      (cigPrices.carton2Price || 0) *
        getProductSaleCount(cigPrices.carton2Id, false) +
      (cigPrices.carton3Price || 0) *
        getProductSaleCount(cigPrices.carton3Id, false) +
      (cigPrices.carton4Price || 0) *
        getProductSaleCount(cigPrices.carton4Id, false) +
      (cigPrices.carton5Price || 0) *
        getProductSaleCount(cigPrices.carton5Id, false) +
      (cigPrices.carton6Price || 0) *
        getProductSaleCount(cigPrices.carton6Id, false) +
      (cigPrices.carton7Price || 0) *
        getProductSaleCount(cigPrices.carton7Id, false) +
      (cigPrices.carton8Price || 0) *
        getProductSaleCount(cigPrices.carton8Id, false) +
      (cigPrices.carton9Price || 0) *
        getProductSaleCount(cigPrices.carton9Id, false) +
      (cigPrices.carton10Price || 0) *
        getProductSaleCount(cigPrices.carton10Id, false) +
      (cigPrices.carton11Price || 0) *
        getProductSaleCount(cigPrices.carton11Id, false) +
      (cigPrices.carton12Price || 0) *
        getProductSaleCount(cigPrices.carton12Id, false) +
      (cigPrices.carton13Price || 0) *
        getProductSaleCount(cigPrices.carton13Id, false) +
      (cigPrices.carton14Price || 0) *
        getProductSaleCount(cigPrices.carton14Id, false) +
      (cigPrices.carton15Price || 0) *
        getProductSaleCount(cigPrices.carton15Id, false) +
      (cigPrices.carton16Price || 0) *
        getProductSaleCount(cigPrices.carton16Id, false) +
      (cigPrices.carton17Price || 0) *
        getProductSaleCount(cigPrices.carton17Id, false) +
      (cigPrices.carton18Price || 0) *
        getProductSaleCount(cigPrices.carton18Id, false) +
      (cigPrices.carton19Price || 0) *
        getProductSaleCount(cigPrices.carton19Id, false) +
      (cigPrices.carton20Price || 0) *
        getProductSaleCount(cigPrices.carton20Id, false) +
      (cigPrices.carton21Price || 0) *
        getProductSaleCount(cigPrices.carton21Id, false) +
      (cigPrices.carton22Price || 0) *
        getProductSaleCount(cigPrices.carton22Id, false) +
      (cigPrices.carton23Price || 0) *
        getProductSaleCount(cigPrices.carton23Id, false) +
      (cigPrices.carton24Price || 0) *
        getProductSaleCount(cigPrices.carton24Id, false);

    const tobaccoBluTotal =
      (cigPrices.pack25Price || 0) *
      getProductSaleCount(cigPrices.pack25Id, true);

    if (!readOnly) {
      await Api.updateShiftBrandSales(shiftDate, shiftNumber, brandSales);
    }

    const result = {
      sumOfPacks,
      sumOfCartons,
      blupack: (blupack && blupack.count) || 0,
      tobaccoPack: tobaccoPackTotal,
      tobaccoCarton: tobaccoCartonTotal,
      tobaccoBlu: tobaccoBluTotal,
    };

    onSubmit(result);
  }

  return (
    <FormBox {...rest} title="Cig Sales" onCloseClick={handleDoneClick}>
      <FormHeader>
        <HeaderField>
          <label>Store #:</label>
          <NumberInput readOnly value={storeId} />
        </HeaderField>
        <HeaderField>
          <label>Shift Date:</label>
          <Input
            type="date"
            readOnly
            value={shiftDate.slice(0, 10)}
            data-testid="shiftDateInput"
          />
        </HeaderField>
        <HeaderField>
          <label>Shift:</label>
          <NumberInput
            readOnly
            value={shiftNumber}
            data-testid="shiftNumberInput"
          />
        </HeaderField>
      </FormHeader>

      <Products>
        <TableColumn>
          <PacksHeader>Packs</PacksHeader>
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={0}
            name={cigPrices.patck1Name || ''}
            price={cigPrices.pack1Price || 0}
            count={getProductSaleCount(cigPrices.pack1Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack1Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={1}
            name={cigPrices.pack2Name || ''}
            price={cigPrices.pack2Price || 0}
            count={getProductSaleCount(cigPrices.pack2Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack2Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={2}
            name={cigPrices.pack3Name || ''}
            price={cigPrices.pack3Price || 0}
            count={getProductSaleCount(cigPrices.pack3Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack3Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={3}
            name={cigPrices.pack4Name || ''}
            price={cigPrices.pack4Price || 0}
            count={getProductSaleCount(cigPrices.pack4Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack4Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={4}
            name={cigPrices.pack5Name || ''}
            price={cigPrices.pack5Price || 0}
            count={getProductSaleCount(cigPrices.pack5Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack5Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={5}
            name={cigPrices.pack6Name || ''}
            price={cigPrices.pack6Price || 0}
            count={getProductSaleCount(cigPrices.pAck6Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pAck6Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={6}
            name={cigPrices.pack7Name || ''}
            price={cigPrices.pack7Price || 0}
            count={getProductSaleCount(cigPrices.pAck7Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pAck7Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={7}
            name={cigPrices.pack8Name || ''}
            price={cigPrices.pack8Price || 0}
            count={getProductSaleCount(cigPrices.pack8Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack8Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={8}
            name={cigPrices.pAck9Name || ''}
            price={cigPrices.pack9Price || 0}
            count={getProductSaleCount(cigPrices.pack9Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack9Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={9}
            name={cigPrices.pAck10Name || ''}
            price={cigPrices.pack10Price || 0}
            count={getProductSaleCount(cigPrices.pack10Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack10Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={10}
            name={cigPrices.pack11Name || ''}
            price={cigPrices.pack11Price || 0}
            count={getProductSaleCount(cigPrices.pack11Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack11Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={11}
            name={cigPrices.pack12Name || ''}
            price={cigPrices.pack12Price || 0}
            count={getProductSaleCount(cigPrices.pack12Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack12Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={12}
            name={cigPrices.pAck13Name || ''}
            price={cigPrices.pack13Price || 0}
            count={getProductSaleCount(cigPrices.pack13Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack13Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={13}
            name={cigPrices.pAck14Name || ''}
            price={cigPrices.pack14Price || 0}
            count={getProductSaleCount(cigPrices.pack14Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack14Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={14}
            name={cigPrices.pack15Name || ''}
            price={cigPrices.pack15Price || 0}
            count={getProductSaleCount(cigPrices.pack15Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack15Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={15}
            name={cigPrices.pack16Name || ''}
            price={cigPrices.pack16Price || 0}
            count={getProductSaleCount(cigPrices.pack16Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack16Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={16}
            name={cigPrices.pack17Name || ''}
            price={cigPrices.pack17Price || 0}
            count={getProductSaleCount(cigPrices.pack17Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack17Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={17}
            name={cigPrices.pack18Name || ''}
            price={cigPrices.pack18Price || 0}
            count={getProductSaleCount(cigPrices.pack18Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack18Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={18}
            name={cigPrices.pack19Name || ''}
            price={cigPrices.pack19Price || 0}
            count={getProductSaleCount(cigPrices.pack19Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack19Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={19}
            name={cigPrices.pack20Name || ''}
            price={cigPrices.pack20Price || 0}
            count={getProductSaleCount(cigPrices.pack20Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack20Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={20}
            name={cigPrices.pack21Name || ''}
            price={cigPrices.pack21Price || 0}
            count={getProductSaleCount(cigPrices.pack21Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack21Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={21}
            name={cigPrices.pack22Name || ''}
            price={cigPrices.pack22Price || 0}
            count={getProductSaleCount(cigPrices.pack22Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack22Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={22}
            name={cigPrices.pack23Name || ''}
            price={cigPrices.pack23Price || 0}
            count={getProductSaleCount(cigPrices.pack23Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack23Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={23}
            name={cigPrices.pack24Name || ''}
            price={cigPrices.pack24Price || 0}
            count={getProductSaleCount(cigPrices.pack24Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack24Id, true, count)
            }
          />
          <PackOrCartonRow
            isPack
            readOnly={readOnly}
            index={24}
            name={cigPrices.pack25Name || ''}
            price={cigPrices.pack25Price || 0}
            count={getProductSaleCount(cigPrices.pack25Id || 0, true)}
            onChange={count =>
              handleBrandCountChange(cigPrices.pack25Id, true, count)
            }
          />
        </TableColumn>
        <TableColumn>
          <CartonsHeader>Cartons</CartonsHeader>
          <PackOrCartonRow
            index={0}
            readOnly={readOnly}
            name={cigPrices.carton1Name || ''}
            price={cigPrices.carton1Price || 0}
            count={getProductSaleCount(cigPrices.carton1Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton1Id, false, count)
            }
          />
          <PackOrCartonRow
            index={1}
            readOnly={readOnly}
            name={cigPrices.carton2Name || ''}
            price={cigPrices.carton2Price || 0}
            count={getProductSaleCount(cigPrices.carton2Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton2Id, false, count)
            }
          />
          <PackOrCartonRow
            index={2}
            readOnly={readOnly}
            name={cigPrices.carton3Name || ''}
            price={cigPrices.carton3Price || 0}
            count={getProductSaleCount(cigPrices.carton3Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton3Id, false, count)
            }
          />
          <PackOrCartonRow
            index={3}
            readOnly={readOnly}
            name={cigPrices.carton4Name || ''}
            price={cigPrices.carton4Price || 0}
            count={getProductSaleCount(cigPrices.carton4Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton4Id, false, count)
            }
          />
          <PackOrCartonRow
            index={4}
            readOnly={readOnly}
            name={cigPrices.carton5Name || ''}
            price={cigPrices.carton5Price || 0}
            count={getProductSaleCount(cigPrices.carton5Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton5Id, false, count)
            }
          />
          <PackOrCartonRow
            index={5}
            readOnly={readOnly}
            name={cigPrices.carton6Name || ''}
            price={cigPrices.carton6Price || 0}
            count={getProductSaleCount(cigPrices.carton6Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton6Id, false, count)
            }
          />
          <PackOrCartonRow
            index={6}
            readOnly={readOnly}
            name={cigPrices.carton7Name || ''}
            price={cigPrices.carton7Price || 0}
            count={getProductSaleCount(cigPrices.carton7Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton7Id, false, count)
            }
          />
          <PackOrCartonRow
            index={7}
            readOnly={readOnly}
            name={cigPrices.carton8Name || ''}
            price={cigPrices.carton8Price || 0}
            count={getProductSaleCount(cigPrices.carton8Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton8Id, false, count)
            }
          />
          <PackOrCartonRow
            index={8}
            readOnly={readOnly}
            name={cigPrices.carton9Name || ''}
            price={cigPrices.carton9Price || 0}
            count={getProductSaleCount(cigPrices.carton9Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton9Id, false, count)
            }
          />
          <PackOrCartonRow
            index={9}
            readOnly={readOnly}
            name={cigPrices.carton10Name || ''}
            price={cigPrices.carton10Price || 0}
            count={getProductSaleCount(cigPrices.carton10Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton10Id, false, count)
            }
          />
          <PackOrCartonRow
            index={10}
            readOnly={readOnly}
            name={cigPrices.carton11Name || ''}
            price={cigPrices.carton11Price || 0}
            count={getProductSaleCount(cigPrices.carton11Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton11Id, false, count)
            }
          />
          <PackOrCartonRow
            index={11}
            readOnly={readOnly}
            name={cigPrices.carton12Name || ''}
            price={cigPrices.carton12Price || 0}
            count={getProductSaleCount(cigPrices.carton12Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton12Id, false, count)
            }
          />
          <PackOrCartonRow
            index={12}
            readOnly={readOnly}
            name={cigPrices.carton13Name || ''}
            price={cigPrices.carton13Price || 0}
            count={getProductSaleCount(cigPrices.carton13Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton13Id, false, count)
            }
          />
          <PackOrCartonRow
            index={13}
            readOnly={readOnly}
            name={cigPrices.carton14Name || ''}
            price={cigPrices.carton14Price || 0}
            count={getProductSaleCount(cigPrices.carton14Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton14Id, false, count)
            }
          />
          <PackOrCartonRow
            index={14}
            readOnly={readOnly}
            name={cigPrices.carton15Name || ''}
            price={cigPrices.carton15Price || 0}
            count={getProductSaleCount(cigPrices.carton15Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton15Id, false, count)
            }
          />
          <PackOrCartonRow
            index={15}
            readOnly={readOnly}
            name={cigPrices.carton16Name || ''}
            price={cigPrices.carton16Price || 0}
            count={getProductSaleCount(cigPrices.carton16Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton16Id, false, count)
            }
          />
          <PackOrCartonRow
            index={16}
            readOnly={readOnly}
            name={cigPrices.carton17Name || ''}
            price={cigPrices.carton17Price || 0}
            count={getProductSaleCount(cigPrices.carton17Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton17Id, false, count)
            }
          />
          <PackOrCartonRow
            index={17}
            readOnly={readOnly}
            name={cigPrices.carton18Name || ''}
            price={cigPrices.carton18Price || 0}
            count={getProductSaleCount(cigPrices.carton18Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton18Id, false, count)
            }
          />
          <PackOrCartonRow
            index={18}
            readOnly={readOnly}
            name={cigPrices.carton19Name || ''}
            price={cigPrices.carton19Price || 0}
            count={getProductSaleCount(cigPrices.carton19Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton19Id, false, count)
            }
          />
          <PackOrCartonRow
            index={19}
            readOnly={readOnly}
            name={cigPrices.carton20Name || ''}
            price={cigPrices.carton20Price || 0}
            count={getProductSaleCount(cigPrices.carton20Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton20Id, false, count)
            }
          />
          <PackOrCartonRow
            index={20}
            readOnly={readOnly}
            name={cigPrices.carton21Name || ''}
            price={cigPrices.carton21Price || 0}
            count={getProductSaleCount(cigPrices.carton21Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton21Id, false, count)
            }
          />
          <PackOrCartonRow
            index={21}
            readOnly={readOnly}
            name={cigPrices.carton22Name || ''}
            price={cigPrices.carton22Price || 0}
            count={getProductSaleCount(cigPrices.carton22Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton22Id, false, count)
            }
          />
          <PackOrCartonRow
            index={22}
            readOnly={readOnly}
            name={cigPrices.carton23Name || ''}
            price={cigPrices.carton23Price || 0}
            count={getProductSaleCount(cigPrices.carton23Id || 0, false)}
            onChange={count =>
              handleBrandCountChange(cigPrices.carton23Id, false, count)
            }
          />
        </TableColumn>
      </Products>
      <FormFooter>
        <TotalSales>
          <label>Total Sales:</label>
          <MoneyInput
            readOnly
            value={totalSales.toFixed(2)}
            data-testid="totalSalesInput"
          />
        </TotalSales>
        <ButtonSetSeparator />
        <ButtonRow>
          <BlockButton onClick={handleDoneClick} data-testid="doneButton">
            Done
          </BlockButton>
          <BlockButton onClick={onCancel} data-testid="cancelButton">
            Cancel
          </BlockButton>
        </ButtonRow>
      </FormFooter>
    </FormBox>
  );
};

export default styled(CigSalesForm)`
  width: 1000px;
  margin: auto;
  ${DateInput},
  ${NumberInput},
  ${MoneyInput} {
    height: 25px;
  }
`;

function PackOrCartonRow({
  index,
  isPack,
  name,
  price,
  count,
  readOnly,
  onChange,
}: {
  index: number;
  name: string;
  price: number;
  count: number;
  readOnly: boolean;
  isPack?: boolean;
  onChange?: (newCount: number) => any;
}) {
  return (
    <ProductRow>
      <label>{name}</label>
      <NumberInput
        readOnly={readOnly}
        value={count}
        onValueChange={onChange}
        min={0}
        data-testid={
          isPack ? `packCountInput${index + 1}` : `cartonCountInput${index + 1}`
        }
      />
      <EqualSeparator>=</EqualSeparator>
      <MoneyInput readOnly value={(count * price).toFixed(2)} />
    </ProductRow>
  );
}

const ProductRow = styled(InputRow)`
  align-items: stretch;
  justify-content: stretch;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }
  > label {
    flex-basis: 175px;
  }

  > ${NumberInput} {
    flex-basis: 80px;
    min-width: 50px;
    text-align: right;
    padding: 7px 3px;
  }

  ${MoneyInput} {
    width: 110px;
    > span {
      padding-left: 13px;
      width: 30px;
      color: #000;
    }

    > input {
      padding-right: 4px;
      text-align: left;
    }
  }
`;

const Products = styled(Row)`
  flex-wrap: nowrap;
  width: 100%;
  min-width: 875px;
  overflow-x: auto;
`;

const TotalSales = styled(InputRow)`
  margin-top: 55px;

  label {
    white-space: nowrap;
  }
  ${MoneyInput}:read-only {
    background: #fff;
    &,
    & input {
      cursor: not-allowed;
    }
  }
`; // cursor: not-allowed added to avoid confusion since the original has a white background

const EqualSeparator = styled('span')`
  margin-left: 10px;
  align-self: center;
  flex: 0 0 20px;
`;
const ButtonSetSeparator = styled('div')`
  border-top: 2px solid black;
  flex-shrink: 1;
  flex-basis: 65%;
  height: 2px;
  margin: 40px 0 0 auto;
`;

const ColumnHeader = styled('h4')`
  margin-bottom: 20px;
  text-align: left;
`;

const PacksHeader = styled(ColumnHeader)``;
const CartonsHeader = styled(ColumnHeader)``;

const TableColumn = styled(Column)`
  flex: 1 1 50%;
  min-width: 350px;
  max-width: 450px;
  align-self: stretch;

  ${CartonsHeader} {
    margin-left: -35px;
  }

  label {
    padding: 7px 15px 7px 0;
  }

  & + & {
    padding-left: 15px;
    margin-left: auto;
  }
`;

const HeaderField = styled(Row)`
  align-items: center;
  label {
    margin-right: 20px;
  }
  & + & {
    margin-left: 20px;
  }
`;

const FormHeader = styled(InputRow)`
  justify-content: flex-start;
  margin-bottom: 20px;
`;

const FormFooter = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-right: 40px;

  ${MoneyInput} {
    margin-left: 10px;
  }
`;

const ButtonRow = styled(Row)`
  justify-content: flex-end;
  width: 100%;
  ${BlockButton} {
    width: 125px;
    min-width: unset;
  }

  ${BlockButton} + ${BlockButton} {
    margin-left: 20px;
  }
`;
