/**
 * Get the first value of an array. Returns `undefined` if the array is empty.
 * `array[0]` doesn't handle empty arrays correctly (return type lacks `| undefined`).
 */
export default function first<T>(array: T[]): T | undefined {
  const { length } = array;
  if (length > 0) {
    return array[0];
  }
  return undefined;
}
