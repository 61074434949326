import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import EmployeeForm, { EmployeeFormEmployee } from '../components/EmployeeForm';
import withAuth from '../components/withAuth';
import Api from '../api/Api';
import getErrorMessage from '../utils/getErrorMessage';
import { navigate } from '@reach/router';

export const newEmployee: EmployeeFormEmployee = {
  employeeId: '0',
  firstName: '',
  lastName: '',
  securityCode: 0,
  password: '',
};

const CreateEmployee: PageComponent = props => {
  const [employee, setEmployee] = useState(newEmployee);
  const [employeeId, setEmployeeId] = useState<string | null>(null);

  function onEmployeeChange(changedEmployee: EmployeeFormEmployee) {
    setEmployee(changedEmployee);
  }

  async function handleSaveClick() {
    try {
      const createdEmployee = employeeId
        ? await Api.updateEmployee(employeeId, employee)
        : await Api.createEmployee(employee);

      setEmployee({
        employeeId: createdEmployee.employeeId,
        firstName: createdEmployee.firstName || '',
        lastName: createdEmployee.lastName || '',
        securityCode: createdEmployee.securityCode || 0,
        password: createdEmployee.password || '',
      });

      if (employeeId) {
        alert('Employee updated successfully');
      } else {
        alert('Employee created successfully');
      }

      navigate('/admin');
    } catch (error) {
      alert(getErrorMessage(error));
    }
  }

  async function onSearchClicked(
    searchedEmployeeId: string,
    employeeName: string,
  ) {
    const searchedEmployee = await Api.findEmployee(
      searchedEmployeeId,
      employeeName,
    );

    if (searchedEmployee) {
      setEmployee({
        employeeId: searchedEmployee.employeeId,
        firstName: searchedEmployee.firstName || '',
        lastName: searchedEmployee.lastName || '',
        securityCode: searchedEmployee.securityCode || 0,
        password: searchedEmployee.password || '',
      });
      setEmployeeId(searchedEmployee.employeeId);
    } else {
      window.alert('No employee found with that name/id.');
    }
  }

  return (
    <Page {...props}>
      <EmployeeForm
        employee={employee}
        onChange={onEmployeeChange}
        onSearchClicked={onSearchClicked}
        onSave={handleSaveClick}
      />
    </Page>
  );
};

export default styled(withAuth(CreateEmployee))`
  justify-content: center;
  align-items: center;
`;
