import React from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import Form from './Form';
import Row from './Row';
import Input from './Input';
import BlockButton from './BlockButton';
import MoneyInput from './MoneyInput';
import FormBox from './FormBox';
import noop from '../utils/noop';

export interface Value {
  carton10Id: number | null;
  carton10Name: string | null;
  carton10Price: number | string | null;
  carton11Id: number | null;
  carton11Name: string | null;
  carton11Price: number | string | null;
  carton12Id: number | null;
  carton12Name: string | null;
  carton12Price: number | string | null;
  carton13Id: number | null;
  carton13Name: string | null;
  carton13Price: number | string | null;
  carton14Id: number | null;
  carton14Name: string | null;
  carton14Price: number | string | null;
  carton15Id: number | null;
  carton15Name: string | null;
  carton15Price: number | string | null;
  carton16Id: number | null;
  carton16Name: string | null;
  carton16Price: number | string | null;
  carton17Id: number | null;
  carton17Name: string | null;
  carton17Price: number | string | null;
  carton18Id: number | null;
  carton18Name: string | null;
  carton18Price: number | string | null;
  carton19Id: number | null;
  carton19Name: string | null;
  carton19Price: number | string | null;
  carton1Id: number | null;
  carton1Name: string | null;
  carton1Price: number | string | null;
  carton20Id: number | null;
  carton20Name: string | null;
  carton20Price: number | string | null;
  carton21Id: number | null;
  carton21Name: string | null;
  carton21Price: number | string | null;
  carton22Id: number | null;
  carton22Name: string | null;
  carton22Price: number | string | null;
  carton23Id: number | null;
  carton23Name: string | null;
  carton23Price: number | string | null;
  carton24Id: number | null;
  carton24Name: string | null;
  carton24Price: number | string | null;
  carton2Id: number | null;
  carton2Name: string | null;
  carton2Price: number | string | null;
  carton3Id: number | null;
  carton3Name: string | null;
  carton3Price: number | string | null;
  carton4Id: number | null;
  carton4Name: string | null;
  carton4Price: number | string | null;
  carton5Id: number | null;
  carton5Name: string | null;
  carton5Price: number | string | null;
  carton6Id: number | null;
  carton6Name: string | null;
  carton6Price: number | string | null;
  carton7Id: number | null;
  carton7Name: string | null;
  carton7Price: number | string | null;
  carton8Id: number | null;
  carton8Name: string | null;
  carton8Price: number | string | null;
  carton9Id: number | null;
  carton9Name: string | null;
  carton9Price: number | string | null;
  pAck10Name: string | null;
  pAck13Name: string | null;
  pAck14Name: string | null;
  pAck6Id: number | null;
  pAck7Id: number | null;
  pAck9Name: string | null;
  pack10Id: number | null;
  pack10Price: number | string | null;
  pack11Id: number | null;
  pack11Name: string | null;
  pack11Price: number | string | null;
  pack12Id: number | null;
  pack12Name: string | null;
  pack12Price: number | string | null;
  pack13Id: number | null;
  pack13Price: number | string | null;
  pack14Id: number | null;
  pack14Price: number | string | null;
  pack15Id: number | null;
  pack15Name: string | null;
  pack15Price: number | string | null;
  pack16Id: number | null;
  pack16Name: string | null;
  pack16Price: number | string | null;
  pack17Id: number | null;
  pack17Name: string | null;
  pack17Price: number | string | null;
  pack18Id: number | null;
  pack18Name: string | null;
  pack18Price: number | string | null;
  pack19Id: number | null;
  pack19Name: string | null;
  pack19Price: number | string | null;
  pack1Id: number | null;
  pack1Price: number | string | null;
  pack20Id: number | null;
  pack20Name: string | null;
  pack20Price: number | string | null;
  pack21Id: number | null;
  pack21Name: string | null;
  pack21Price: number | string | null;
  pack22Id: number | null;
  pack22Name: string | null;
  pack22Price: number | string | null;
  pack23Id: number | null;
  pack23Name: string | null;
  pack23Price: number | string | null;
  pack24Id: number | null;
  pack24Name: string | null;
  pack24Price: number | string | null;
  pack25Id: number | null;
  pack25Name: string | null;
  pack25Price: number | string | null;
  pack2Id: number | null;
  pack2Name: string | null;
  pack2Price: number | string | null;
  pack3Id: number | null;
  pack3Name: string | null;
  pack3Price: number | string | null;
  pack4Id: number | null;
  pack4Name: string | null;
  pack4Price: number | string | null;
  pack5Id: number | null;
  pack5Name: string | null;
  pack5Price: number | string | null;
  pack6Name: string | null;
  pack6Price: number | string | null;
  pack7Name: string | null;
  pack7Price: number | string | null;
  pack8Id: number | null;
  pack8Name: string | null;
  pack8Price: number | string | null;
  pack9Id: number | null;
  pack9Price: number | string | null;
  patck1Name: string | null;
  storeId: number;
}

export interface Props {
  value: Value;
  onChange?: (value: Value) => any;
  onSubmit?: (value: Value) => any;
}

const CigPriceSetupForm: React.FC<Props> = props => {
  const { value, onChange = noop, onSubmit = noop, ...rest } = props;

  function handleSubmit() {
    onSubmit(value);
  }

  return (
    <FormBox {...rest} title="CigPriceSetup" onCloseClick={handleSubmit}>
      <StoreIdLabel>
        StoreID
        <Input value={value.storeId} />
      </StoreIdLabel>
      <Form onSubmit={handleSubmit} data-testid="form">
        <ProductColumn>
          <ColumnHeader>Packs</ColumnHeader>
          <Row>
            <Input
              value={value.pack1Id || ''}
              onValueChange={pack1Id =>
                onChange({ ...value, pack1Id: Number(pack1Id) })
              }
            />
            <Input
              value={value.patck1Name || ''}
              onValueChange={patck1Name => onChange({ ...value, patck1Name })}
            />
            <Input
              isMoney
              value={value.pack1Price || 0}
              onValueChange={pack1Price => onChange({ ...value, pack1Price })}
              data-testid="packPriceInput1"
            />
          </Row>
          <Row>
            <Input
              value={value.pack2Id || ''}
              onValueChange={pack2Id =>
                onChange({ ...value, pack2Id: Number(pack2Id) })
              }
            />
            <Input
              value={value.pack2Name || ''}
              onValueChange={pack2Name => onChange({ ...value, pack2Name })}
            />
            <Input
              isMoney
              value={value.pack2Price || 0}
              onValueChange={pack2Price => onChange({ ...value, pack2Price })}
              data-testid="packPriceInput2"
            />
          </Row>
          <Row>
            <Input
              value={value.pack3Id || ''}
              onValueChange={pack3Id =>
                onChange({ ...value, pack3Id: Number(pack3Id) })
              }
            />
            <Input
              value={value.pack3Name || ''}
              onValueChange={pack3Name => onChange({ ...value, pack3Name })}
            />
            <Input
              isMoney
              value={value.pack3Price || 0}
              onValueChange={pack3Price => onChange({ ...value, pack3Price })}
              data-testid="packPriceInput3"
            />
          </Row>
          <Row>
            <Input
              value={value.pack4Id || ''}
              onValueChange={pack4Id =>
                onChange({ ...value, pack4Id: Number(pack4Id) })
              }
            />
            <Input
              value={value.pack4Name || ''}
              onValueChange={pack4Name => onChange({ ...value, pack4Name })}
            />
            <Input
              isMoney
              value={value.pack4Price || 0}
              onValueChange={pack4Price => onChange({ ...value, pack4Price })}
              data-testid="packPriceInput4"
            />
          </Row>
          <Row>
            <Input
              value={value.pack5Id || ''}
              onValueChange={pack5Id =>
                onChange({ ...value, pack5Id: Number(pack5Id) })
              }
            />
            <Input
              value={value.pack5Name || ''}
              onValueChange={pack5Name => onChange({ ...value, pack5Name })}
            />
            <Input
              isMoney
              value={value.pack5Price || 0}
              onValueChange={pack5Price => onChange({ ...value, pack5Price })}
              data-testid="packPriceInput5"
            />
          </Row>
          <Row>
            <Input
              value={value.pAck6Id || ''}
              onValueChange={pAck6Id =>
                onChange({ ...value, pAck6Id: Number(pAck6Id) })
              }
            />
            <Input
              value={value.pack6Name || ''}
              onValueChange={pack6Name => onChange({ ...value, pack6Name })}
            />
            <Input
              isMoney
              value={value.pack6Price || 0}
              onValueChange={pack6Price => onChange({ ...value, pack6Price })}
              data-testid="packPriceInput6"
            />
          </Row>
          <Row>
            <Input
              value={value.pAck7Id || ''}
              onValueChange={pAck6Id =>
                onChange({ ...value, pAck7Id: Number(pAck6Id) })
              }
            />
            <Input
              value={value.pack7Name || ''}
              onValueChange={pack7Name => onChange({ ...value, pack7Name })}
            />
            <Input
              isMoney
              value={value.pack7Price || 0}
              onValueChange={pack7Price => onChange({ ...value, pack7Price })}
              data-testid="packPriceInput7"
            />
          </Row>
          <Row>
            <Input
              value={value.pack8Id || ''}
              onValueChange={pack8Id =>
                onChange({ ...value, pack8Id: Number(pack8Id) })
              }
            />
            <Input
              value={value.pack8Name || ''}
              onValueChange={pack8Name => onChange({ ...value, pack8Name })}
            />
            <Input
              isMoney
              value={value.pack8Price || 0}
              onValueChange={pack8Price => onChange({ ...value, pack8Price })}
              data-testid="packPriceInput8"
            />
          </Row>
          <Row>
            <Input
              value={value.pack9Id || ''}
              onValueChange={pack9Id =>
                onChange({ ...value, pack9Id: Number(pack9Id) })
              }
            />
            <Input
              value={value.pAck9Name || ''}
              onValueChange={pAck9Name => onChange({ ...value, pAck9Name })}
            />
            <Input
              isMoney
              value={value.pack9Price || 0}
              onValueChange={pack9Price => onChange({ ...value, pack9Price })}
              data-testid="packPriceInput9"
            />
          </Row>
          <Row>
            <Input
              value={value.pack10Id || ''}
              onValueChange={pack10Id =>
                onChange({ ...value, pack10Id: Number(pack10Id) })
              }
            />
            <Input
              value={value.pAck10Name || ''}
              onValueChange={pAck10Name => onChange({ ...value, pAck10Name })}
            />
            <Input
              isMoney
              value={value.pack10Price || 0}
              onValueChange={pack10Price => onChange({ ...value, pack10Price })}
              data-testid="packPriceInput10"
            />
          </Row>
          <Row>
            <Input
              value={value.pack11Id || ''}
              onValueChange={pack11Id =>
                onChange({ ...value, pack11Id: Number(pack11Id) })
              }
            />
            <Input
              value={value.pack11Name || ''}
              onValueChange={pack11Name => onChange({ ...value, pack11Name })}
            />
            <Input
              isMoney
              value={value.pack11Price || 0}
              onValueChange={pack11Price => onChange({ ...value, pack11Price })}
              data-testid="packPriceInput11"
            />
          </Row>
          <Row>
            <Input
              value={value.pack12Id || ''}
              onValueChange={pack12Id =>
                onChange({ ...value, pack12Id: Number(pack12Id) })
              }
            />
            <Input
              value={value.pack12Name || ''}
              onValueChange={pack12Name => onChange({ ...value, pack12Name })}
            />
            <Input
              isMoney
              value={value.pack12Price || 0}
              onValueChange={pack12Price => onChange({ ...value, pack12Price })}
              data-testid="packPriceInput12"
            />
          </Row>
          <Row>
            <Input
              value={value.pack13Id || ''}
              onValueChange={pack13Id =>
                onChange({ ...value, pack13Id: Number(pack13Id) })
              }
            />
            <Input
              value={value.pAck13Name || ''}
              onValueChange={pAck13Name => onChange({ ...value, pAck13Name })}
            />
            <Input
              isMoney
              value={value.pack13Price || 0}
              onValueChange={pack13Price => onChange({ ...value, pack13Price })}
              data-testid="packPriceInput13"
            />
          </Row>
          <Row>
            <Input
              value={value.pack14Id || ''}
              onValueChange={pack14Id =>
                onChange({ ...value, pack14Id: Number(pack14Id) })
              }
            />
            <Input
              value={value.pAck14Name || ''}
              onValueChange={pAck14Name => onChange({ ...value, pAck14Name })}
            />
            <Input
              isMoney
              value={value.pack14Price || 0}
              onValueChange={pack14Price => onChange({ ...value, pack14Price })}
              data-testid="packPriceInput14"
            />
          </Row>
          <Row>
            <Input
              value={value.pack15Id || ''}
              onValueChange={pack15Id =>
                onChange({ ...value, pack15Id: Number(pack15Id) })
              }
            />
            <Input
              value={value.pack15Name || ''}
              onValueChange={pack15Name => onChange({ ...value, pack15Name })}
            />
            <Input
              isMoney
              value={value.pack15Price || 0}
              onValueChange={pack15Price => onChange({ ...value, pack15Price })}
              data-testid="packPriceInput15"
            />
          </Row>
          <Row>
            <Input
              value={value.pack16Id || ''}
              onValueChange={pack16Id =>
                onChange({ ...value, pack16Id: Number(pack16Id) })
              }
            />
            <Input
              value={value.pack16Name || ''}
              onValueChange={pack16Name => onChange({ ...value, pack16Name })}
            />
            <Input
              isMoney
              value={value.pack16Price || 0}
              onValueChange={pack16Price => onChange({ ...value, pack16Price })}
              data-testid="packPriceInput16"
            />
          </Row>
          <Row>
            <Input
              value={value.pack17Id || ''}
              onValueChange={pack17Id =>
                onChange({ ...value, pack17Id: Number(pack17Id) })
              }
            />
            <Input
              value={value.pack17Name || ''}
              onValueChange={pack17Name => onChange({ ...value, pack17Name })}
            />
            <Input
              isMoney
              value={value.pack17Price || 0}
              onValueChange={pack17Price => onChange({ ...value, pack17Price })}
              data-testid="packPriceInput17"
            />
          </Row>
          <Row>
            <Input
              value={value.pack18Id || ''}
              onValueChange={pack18Id =>
                onChange({ ...value, pack18Id: Number(pack18Id) })
              }
            />
            <Input
              value={value.pack18Name || ''}
              onValueChange={pack18Name => onChange({ ...value, pack18Name })}
            />
            <Input
              isMoney
              value={value.pack18Price || 0}
              onValueChange={pack18Price => onChange({ ...value, pack18Price })}
              data-testid="packPriceInput18"
            />
          </Row>
          <Row>
            <Input
              value={value.pack19Id || ''}
              onValueChange={pack19Id =>
                onChange({ ...value, pack19Id: Number(pack19Id) })
              }
            />
            <Input
              value={value.pack19Name || ''}
              onValueChange={pack19Name => onChange({ ...value, pack19Name })}
            />
            <Input
              isMoney
              value={value.pack19Price || 0}
              onValueChange={pack19Price => onChange({ ...value, pack19Price })}
              data-testid="packPriceInput19"
            />
          </Row>
          <Row>
            <Input
              value={value.pack20Id || ''}
              onValueChange={pack20Id =>
                onChange({ ...value, pack20Id: Number(pack20Id) })
              }
            />
            <Input
              value={value.pack20Name || ''}
              onValueChange={pack20Name => onChange({ ...value, pack20Name })}
            />
            <Input
              isMoney
              value={value.pack20Price || 0}
              onValueChange={pack20Price => onChange({ ...value, pack20Price })}
              data-testid="packPriceInput20"
            />
          </Row>
          <Row>
            <Input
              value={value.pack21Id || ''}
              onValueChange={pack21Id =>
                onChange({ ...value, pack21Id: Number(pack21Id) })
              }
            />
            <Input
              value={value.pack21Name || ''}
              onValueChange={pack21Name => onChange({ ...value, pack21Name })}
            />
            <Input
              isMoney
              value={value.pack21Price || 0}
              onValueChange={pack21Price => onChange({ ...value, pack21Price })}
              data-testid="packPriceInput21"
            />
          </Row>
          <Row>
            <Input
              value={value.pack22Id || ''}
              onValueChange={pack22Id =>
                onChange({ ...value, pack22Id: Number(pack22Id) })
              }
            />
            <Input
              value={value.pack22Name || ''}
              onValueChange={pack22Name => onChange({ ...value, pack22Name })}
            />
            <Input
              isMoney
              value={value.pack22Price || 0}
              onValueChange={pack22Price => onChange({ ...value, pack22Price })}
              data-testid="packPriceInput22"
            />
          </Row>
          <Row>
            <Input
              value={value.pack23Id || ''}
              onValueChange={pack23Id =>
                onChange({ ...value, pack23Id: Number(pack23Id) })
              }
            />
            <Input
              value={value.pack23Name || ''}
              onValueChange={pack23Name => onChange({ ...value, pack23Name })}
            />
            <Input
              isMoney
              value={value.pack23Price || 0}
              onValueChange={pack23Price => onChange({ ...value, pack23Price })}
              data-testid="packPriceInput23"
            />
          </Row>
          <Row>
            <Input
              value={value.pack24Id || ''}
              onValueChange={pack24Id =>
                onChange({ ...value, pack24Id: Number(pack24Id) })
              }
            />
            <Input
              value={value.pack24Name || ''}
              onValueChange={pack24Name => onChange({ ...value, pack24Name })}
            />
            <Input
              isMoney
              value={value.pack24Price || 0}
              onValueChange={pack24Price => onChange({ ...value, pack24Price })}
              data-testid="packPriceInput24"
            />
          </Row>
          <Row>
            <Input
              value={value.pack25Id || ''}
              onValueChange={pack25Id =>
                onChange({ ...value, pack25Id: Number(pack25Id) })
              }
            />
            <Input
              value={value.pack25Name || ''}
              onValueChange={pack25Name => onChange({ ...value, pack25Name })}
            />
            <Input
              isMoney
              value={value.pack25Price || 0}
              data-testid="packPriceInput25"
              onValueChange={pack25Price => onChange({ ...value, pack25Price })}
            />
          </Row>
        </ProductColumn>
        <ProductColumn>
          <ColumnHeader>Cartons</ColumnHeader>
          <Row>
            <Input
              value={value.carton1Id || ''}
              onValueChange={carton1Id =>
                onChange({ ...value, carton1Id: Number(carton1Id) })
              }
            />
            <Input
              value={value.carton1Name || ''}
              onValueChange={carton1Name => onChange({ ...value, carton1Name })}
            />
            <Input
              isMoney
              value={value.carton1Price || 0}
              onValueChange={carton1Price =>
                onChange({ ...value, carton1Price })
              }
              data-testid="cartonPriceInput1"
            />
          </Row>
          <Row>
            <Input
              value={value.carton2Id || ''}
              onValueChange={carton2Id =>
                onChange({ ...value, carton2Id: Number(carton2Id) })
              }
            />
            <Input
              value={value.carton2Name || ''}
              onValueChange={carton2Name => onChange({ ...value, carton2Name })}
            />
            <Input
              isMoney
              value={value.carton2Price || 0}
              onValueChange={carton2Price =>
                onChange({ ...value, carton2Price })
              }
              data-testid="cartonPriceInput2"
            />
          </Row>
          <Row>
            <Input
              value={value.carton3Id || ''}
              onValueChange={carton3Id =>
                onChange({ ...value, carton3Id: Number(carton3Id) })
              }
            />
            <Input
              value={value.carton3Name || ''}
              onValueChange={carton3Name => onChange({ ...value, carton3Name })}
            />
            <Input
              isMoney
              value={value.carton3Price || 0}
              onValueChange={carton3Price =>
                onChange({ ...value, carton3Price })
              }
              data-testid="cartonPriceInput3"
            />
          </Row>
          <Row>
            <Input
              value={value.carton4Id || ''}
              onValueChange={carton4Id =>
                onChange({ ...value, carton4Id: Number(carton4Id) })
              }
            />
            <Input
              value={value.carton4Name || ''}
              onValueChange={carton4Name => onChange({ ...value, carton4Name })}
            />
            <Input
              isMoney
              value={value.carton4Price || 0}
              onValueChange={carton4Price =>
                onChange({ ...value, carton4Price })
              }
              data-testid="cartonPriceInput4"
            />
          </Row>
          <Row>
            <Input
              value={value.carton5Id || ''}
              onValueChange={carton5Id =>
                onChange({ ...value, carton5Id: Number(carton5Id) })
              }
            />
            <Input
              value={value.carton5Name || ''}
              onValueChange={carton5Name => onChange({ ...value, carton5Name })}
            />
            <Input
              isMoney
              value={value.carton5Price || 0}
              onValueChange={carton5Price =>
                onChange({ ...value, carton5Price })
              }
              data-testid="cartonPriceInput5"
            />
          </Row>
          <Row>
            <Input
              value={value.carton6Id || ''}
              onValueChange={carton6Id =>
                onChange({ ...value, carton6Id: Number(carton6Id) })
              }
            />
            <Input
              value={value.carton6Name || ''}
              onValueChange={carton6Name => onChange({ ...value, carton6Name })}
            />
            <Input
              isMoney
              value={value.carton6Price || 0}
              onValueChange={carton6Price =>
                onChange({ ...value, carton6Price })
              }
              data-testid="cartonPriceInput6"
            />
          </Row>
          <Row>
            <Input
              value={value.carton7Id || ''}
              onValueChange={carton7Id =>
                onChange({ ...value, carton7Id: Number(carton7Id) })
              }
            />
            <Input
              value={value.carton7Name || ''}
              onValueChange={carton7Name => onChange({ ...value, carton7Name })}
            />
            <Input
              isMoney
              value={value.carton7Price || 0}
              onValueChange={carton7Price =>
                onChange({ ...value, carton7Price })
              }
              data-testid="cartonPriceInput7"
            />
          </Row>
          <Row>
            <Input
              value={value.carton8Id || ''}
              onValueChange={carton8Id =>
                onChange({ ...value, carton8Id: Number(carton8Id) })
              }
            />
            <Input
              value={value.carton8Name || ''}
              onValueChange={carton8Name => onChange({ ...value, carton8Name })}
            />
            <Input
              isMoney
              value={value.carton8Price || 0}
              onValueChange={carton8Price =>
                onChange({ ...value, carton8Price })
              }
              data-testid="cartonPriceInput8"
            />
          </Row>
          <Row>
            <Input
              value={value.carton9Id || ''}
              onValueChange={carton9Id =>
                onChange({ ...value, carton9Id: Number(carton9Id) })
              }
            />
            <Input
              value={value.carton9Name || ''}
              onValueChange={carton9Name => onChange({ ...value, carton9Name })}
            />
            <Input
              isMoney
              value={value.carton9Price || 0}
              onValueChange={carton9Price =>
                onChange({ ...value, carton9Price })
              }
              data-testid="cartonPriceInput9"
            />
          </Row>
          <Row>
            <Input
              value={value.carton10Id || ''}
              onValueChange={carton10Id =>
                onChange({ ...value, carton10Id: Number(carton10Id) })
              }
            />
            <Input
              value={value.carton10Name || ''}
              onValueChange={carton10Name =>
                onChange({ ...value, carton10Name })
              }
            />
            <Input
              isMoney
              value={value.carton10Price || 0}
              onValueChange={carton10Price =>
                onChange({ ...value, carton10Price })
              }
              data-testid="cartonPriceInput10"
            />
          </Row>
          <Row>
            <Input
              value={value.carton11Id || ''}
              onValueChange={carton11Id =>
                onChange({ ...value, carton11Id: Number(carton11Id) })
              }
            />
            <Input
              value={value.carton11Name || ''}
              onValueChange={carton11Name =>
                onChange({ ...value, carton11Name })
              }
            />
            <Input
              isMoney
              value={value.carton11Price || 0}
              onValueChange={carton11Price =>
                onChange({ ...value, carton11Price })
              }
              data-testid="cartonPriceInput11"
            />
          </Row>
          <Row>
            <Input
              value={value.carton12Id || ''}
              onValueChange={carton12Id =>
                onChange({ ...value, carton12Id: Number(carton12Id) })
              }
            />
            <Input
              value={value.carton12Name || ''}
              onValueChange={carton12Name =>
                onChange({ ...value, carton12Name })
              }
            />
            <Input
              isMoney
              value={value.carton12Price || 0}
              onValueChange={carton12Price =>
                onChange({ ...value, carton12Price })
              }
              data-testid="cartonPriceInput12"
            />
          </Row>
          <Row>
            <Input
              value={value.carton13Id || ''}
              onValueChange={carton13Id =>
                onChange({ ...value, carton13Id: Number(carton13Id) })
              }
            />
            <Input
              value={value.carton13Name || ''}
              onValueChange={carton13Name =>
                onChange({ ...value, carton13Name })
              }
            />
            <Input
              isMoney
              value={value.carton13Price || 0}
              onValueChange={carton13Price =>
                onChange({ ...value, carton13Price })
              }
              data-testid="cartonPriceInput13"
            />
          </Row>
          <Row>
            <Input
              value={value.carton14Id || ''}
              onValueChange={carton14Id =>
                onChange({ ...value, carton14Id: Number(carton14Id) })
              }
            />
            <Input
              value={value.carton14Name || ''}
              onValueChange={carton14Name =>
                onChange({ ...value, carton14Name })
              }
            />
            <Input
              isMoney
              value={value.carton14Price || 0}
              onValueChange={carton14Price =>
                onChange({ ...value, carton14Price })
              }
              data-testid="cartonPriceInput14"
            />
          </Row>
          <Row>
            <Input
              value={value.carton15Id || ''}
              onValueChange={carton15Id =>
                onChange({ ...value, carton15Id: Number(carton15Id) })
              }
            />
            <Input
              value={value.carton15Name || ''}
              onValueChange={carton15Name =>
                onChange({ ...value, carton15Name })
              }
            />
            <Input
              isMoney
              value={value.carton15Price || 0}
              onValueChange={carton15Price =>
                onChange({ ...value, carton15Price })
              }
              data-testid="cartonPriceInput15"
            />
          </Row>
          <Row>
            <Input
              value={value.carton16Id || ''}
              onValueChange={carton16Id =>
                onChange({ ...value, carton16Id: Number(carton16Id) })
              }
            />
            <Input
              value={value.carton16Name || ''}
              onValueChange={carton16Name =>
                onChange({ ...value, carton16Name })
              }
            />
            <Input
              isMoney
              value={value.carton16Price || 0}
              onValueChange={carton16Price =>
                onChange({ ...value, carton16Price })
              }
              data-testid="cartonPriceInput16"
            />
          </Row>
          <Row>
            <Input
              value={value.carton17Id || ''}
              onValueChange={carton17Id =>
                onChange({ ...value, carton17Id: Number(carton17Id) })
              }
            />
            <Input
              value={value.carton17Name || ''}
              onValueChange={carton17Name =>
                onChange({ ...value, carton17Name })
              }
            />
            <Input
              isMoney
              value={value.carton17Price || 0}
              onValueChange={carton17Price =>
                onChange({ ...value, carton17Price })
              }
              data-testid="cartonPriceInput17"
            />
          </Row>
          <Row>
            <Input
              value={value.carton18Id || ''}
              onValueChange={carton18Id =>
                onChange({ ...value, carton18Id: Number(carton18Id) })
              }
            />
            <Input
              value={value.carton18Name || ''}
              onValueChange={carton18Name =>
                onChange({ ...value, carton18Name })
              }
            />
            <Input
              isMoney
              value={value.carton18Price || 0}
              onValueChange={carton18Price =>
                onChange({ ...value, carton18Price })
              }
              data-testid="cartonPriceInput18"
            />
          </Row>
          <Row>
            <Input
              value={value.carton19Id || ''}
              onValueChange={carton19Id =>
                onChange({ ...value, carton19Id: Number(carton19Id) })
              }
            />
            <Input
              value={value.carton19Name || ''}
              onValueChange={carton19Name =>
                onChange({ ...value, carton19Name })
              }
            />
            <Input
              isMoney
              value={value.carton19Price || 0}
              onValueChange={carton19Price =>
                onChange({ ...value, carton19Price })
              }
              data-testid="cartonPriceInput19"
            />
          </Row>
          <Row>
            <Input
              value={value.carton20Id || ''}
              onValueChange={carton20Id =>
                onChange({ ...value, carton20Id: Number(carton20Id) })
              }
            />
            <Input
              value={value.carton20Name || ''}
              onValueChange={carton20Name =>
                onChange({ ...value, carton20Name })
              }
            />
            <Input
              isMoney
              value={value.carton20Price || 0}
              onValueChange={carton20Price =>
                onChange({ ...value, carton20Price })
              }
              data-testid="cartonPriceInput20"
            />
          </Row>
          <Row>
            <Input
              value={value.carton21Id || ''}
              onValueChange={carton21Id =>
                onChange({ ...value, carton21Id: Number(carton21Id) })
              }
            />
            <Input
              value={value.carton21Name || ''}
              onValueChange={carton21Name =>
                onChange({ ...value, carton21Name })
              }
            />
            <Input
              isMoney
              value={value.carton21Price || 0}
              onValueChange={carton21Price =>
                onChange({ ...value, carton21Price })
              }
              data-testid="cartonPriceInput21"
            />
          </Row>
          <Row>
            <Input
              value={value.carton22Id || ''}
              onValueChange={carton22Id =>
                onChange({ ...value, carton22Id: Number(carton22Id) })
              }
            />
            <Input
              value={value.carton22Name || ''}
              onValueChange={carton22Name =>
                onChange({ ...value, carton22Name })
              }
            />
            <Input
              isMoney
              value={value.carton22Price || 0}
              onValueChange={carton22Price =>
                onChange({ ...value, carton22Price })
              }
              data-testid="cartonPriceInput22"
            />
          </Row>
          <Row>
            <Input
              value={value.carton23Id || ''}
              onValueChange={carton23Id =>
                onChange({ ...value, carton23Id: Number(carton23Id) })
              }
            />
            <Input
              value={value.carton23Name || ''}
              onValueChange={carton23Name =>
                onChange({ ...value, carton23Name })
              }
            />
            <Input
              isMoney
              value={value.carton23Price || 0}
              onValueChange={carton23Price =>
                onChange({ ...value, carton23Price })
              }
              data-testid="cartonPriceInput23"
            />
          </Row>
          <Row>
            <Input
              value={value.carton24Id || ''}
              onValueChange={carton24Id =>
                onChange({ ...value, carton24Id: Number(carton24Id) })
              }
            />
            <Input
              value={value.carton24Name || ''}
              onValueChange={carton24Name =>
                onChange({ ...value, carton24Name })
              }
            />
            <Input
              isMoney
              value={value.carton24Price || 0}
              onValueChange={carton24Price =>
                onChange({ ...value, carton24Price })
              }
              data-testid="cartonPriceInput24"
            />
          </Row>
        </ProductColumn>
      </Form>
      <Footer>
        <BlockButton onClick={handleSubmit}>Done</BlockButton>
      </Footer>
    </FormBox>
  );
};

export default styled(CigPriceSetupForm)`
  width: 750px;
  margin: auto;
  > ${Form} {
    flex-direction: row;
  }

  ${Input}, input {
    height: 25px;
    width: 100px;
  }
`;

const ProductColumn = styled(Column)`
  flex: 1;

  & + & {
    margin-left: 20px;
  }

  ${Input} {
    flex: 1;
    text-align: right;
    padding-right: 5px;
  }

  ${MoneyInput} {
    width: 100px;
    margin-left: 10px;
    > span {
      padding-left: 20px;
      width: 33px;
      color: #000;
    }
    > input {
      max-width: 65px;
      padding-right: 4px;
      text-align: left;
    }
  }

  > ${Row} + ${Row} {
    margin-top: 10px;
  }
`;

const ColumnHeader = styled('div')`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`;

const StoreIdLabel = styled('label')`
  margin-bottom: 20px;
  margin-left: 50px;
  ${Input} {
    margin-left: 10px;
    width: 110px;
  }
`;

const Footer = styled(Row)`
  margin-top: 20px;
  justify-content: flex-end;
`;
