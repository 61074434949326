import React, { FC } from 'react';
import styled from 'styled-components/macro';
import Column from './Column';
import { FaCheck } from 'react-icons/fa';

export interface WindowsCheckboxProps {
  checked: boolean | null;
  /**
   * Function called when the checkbox is checked.
   * null or false changes to true
   * true changes to false
   */
  onChange?: (nextValue: boolean) => any;
}

/**
 * A "Windows Styled" checkbox (instead of the native browser checkbox)
 * Allows 3 states: null, true, and false
 * @param param0
 */
const WindowsCheckbox: FC<WindowsCheckboxProps> = ({
  checked,
  onChange,
  ...rest
}) => {
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault();
    if (onChange) {
      onChange(!checked);
    }
  }

  return (
    <CheckMarkBox {...rest} nulled={checked === null} onClick={handleClick}>
      {checked && <StyledCheckMark />}
    </CheckMarkBox>
  );
};

export default styled(WindowsCheckbox)``;

const StyledCheckMark = styled(FaCheck)`
  padding-right: 4px;
`;

const CheckMarkBox = styled(Column)<{ nulled?: boolean }>`
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;

  /* Prevents clicking rapidly causing parts of the page to become highlighted*/
  user-select: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${props => (props.nulled ? 'black' : 'transparent')};
  }
`;
