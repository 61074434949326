import React from 'react';
import styled from 'styled-components/macro';
import Page, { PageComponent } from '../components/Page';
import EopPayoutsReport from '../components/EopPayoutsReport';
import withAuth from '../components/withAuth';

const PayoutReport: PageComponent = props => {
  return (
    <Page {...props}>
      <EopPayoutsReport
        date={new Date()}
        payouts={[
          {
            comments: '',
            payout: 222,
            id: 1,
            shiftDate: new Date(),
            shiftNumber: 111,
            storeName: 'Store 123',
          },
          {
            comments: '',
            payout: 322,
            id: 2,
            shiftDate: new Date(),
            shiftNumber: 111,
            storeName: 'Store 123',
          },
          {
            comments: '',
            payout: 422,
            id: 3,
            shiftDate: new Date(),
            shiftNumber: 111,
            storeName: 'Store 123',
          },
        ]}
      />
    </Page>
  );
};

export default styled(withAuth(PayoutReport))``;
