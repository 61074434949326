export default interface ShiftMaster {
  alcoholBeverages: number | null;
  atmAmount: number | null;
  atmCount: number | null;
  atmRefill: number | null;
  auditShift: number | null;
  auditSignoff: number | null;
  beginningBluInventory: number | null;
  beginningCash: number | null;
  beginningChange: number | null;
  beginningCheckCount: number | null;
  beginningPokerBank: number | null;
  beginningTill: number | null;
  beginningciginventory: number | null;
  bluPurchases: number | null;
  bluReturns: number | null;
  blupack: number | null;
  cashDeposit: number | null;
  cashDepositB: number | null;
  cashDepositC: number | null;
  cashDepositD: number | null;
  cashDrop: number | null;
  cashToSafe: number | null;
  cassetteDeposit: number | null;
  changeIn: number | null;
  changeOrder: number;
  changeOut: number | null;
  checksAdded: number | null;
  checksWritten: number | null;
  cigPurchases: number | null;
  cigReturns: number | null;
  cigReturnsNotes: string | null;
  cigaretteCoupons: number | null;
  cigaretteDiscount: number | null;
  cigaretteDiscountOverring: number | null;
  cigaretteOr: number | null;
  cigaretteOverrings: number | null;
  cigarillo: number | null;
  columnTotal: number | null;
  comments: string | null;
  creditCardSlipCount: number | null;
  creditCardSlips: number | null;
  decreaseBanks: number | null;
  depositBagA: string | null;
  depositBagB: string | null;
  depositBagC: string | null;
  depositBagD: string | null;
  discrepencyComments: string | null;
  discrepencyOver: number;
  discrepencyShort: number;
  ecarton: number | null;
  employee: string | null;
  employeeMeals: number | null;
  employeeSignoff: number | null;
  endingBluInventory: number | null;
  endingCash: number | null;
  endingChange: number | null;
  endingCheckCount: number | null;
  endingPokerBank: number | null;
  endingTill: number | null;
  endingciginventory: number | null;
  epack: number | null;
  espressoDiscount: number | null;
  firstName: string | null;
  food: number | null;
  foodDiscount: number | null;
  fundsNeeded: number | null;
  gcarton: number | null;
  giftIn: number;
  giftOut: number;
  gpack: number | null;
  guestRewardPayout: number;
  guestRewardPayoutComments: string | null;
  iDmailed: number | null;
  idComments: number | null;
  idWriteUp: number | null;
  idrm: number | null;
  increaseBanks: number | null;
  innerCompanyTransferNotes: string | null;
  innerCompanyTransferRegDiscount: number;
  innerCompanyTransferTo: number;
  innerCompanyTransferValue: number;
  innercompanytransfers: number | null;
  innercompanyxfer1: number | null;
  innercompanyxfer2: number | null;
  innercompanyxfer3: number | null;
  innercompanyxferto1: number | null;
  innercompanyxferto2: number | null;
  innercompanyxferto3: number | null;
  jamBillComments: string | null;
  jamBillsIn: number | null;
  jamBillsOut: number | null;
  lastName: string | null;
  lotteryCoupons: number | null;
  managerSignoff: number | null;
  mcarton: number | null;
  midShiftPull: number | null;
  mpack: number | null;
  nonAlcohol: number | null;
  nonPrizeChecks: number | null;
  numPayoutSlips: number;
  onLinePrizes: number | null;
  onLineSales: number | null;
  other: number | null;
  otherPayout: number | null;
  othercomments: string | null;
  overShort: number | null;
  overrings: number | null;
  payouts: number | null;
  payoutsComments: string | null;
  pcarton: number | null;
  period: number | null;
  ppack: number | null;
  promotion: number | null;
  pullTabPrizes: number | null;
  regularShiftTubeRefill: number | null;
  rocketBucks: number;
  rocketBucksComments: string | null;
  salesTax: number | null;
  scratchOffValidation: number | null;
  scratchTickets: number | null;
  shift: number;
  shiftDate: string;
  snusPack: number | null;
  storeId: number;
  tobaccoBlu: number | null;
  tobaccoCarton: number | null;
  tobaccoPack: number | null;
  totalDeposit: number | null;
  totalIn: number | null;
  totalOut: number | null;
  totalVpIn: number | null;
  transfercigs: number | null;
  validator: number | null;
  vlmCashIn: number | null;
  vpPaid: number;
  vpSales: number | null;
  vpValidation: number | null;
  winnerChecks: number | null;
}

export interface ShiftMasterDraft {
  alcoholBeverages: string | number | null;
  atmAmount: string | number | null;
  atmCount: string | number | null;
  atmRefill: string | number | null;
  auditShift: string | number | null;
  auditSignoff: string | number | null;
  beginningBluInventory: string | number | null;
  beginningCash: string | number | null;
  beginningChange: string | number | null;
  beginningCheckCount: string | number | null;
  beginningPokerBank: string | number | null;
  beginningTill: string | number | null;
  beginningciginventory: string | number | null;
  bluPurchases: string | number | null;
  bluReturns: string | number | null;
  blupack: string | number | null;
  cashDeposit: string | number | null;
  cashDepositB: string | number | null;
  cashDepositC: string | number | null;
  cashDepositD: string | number | null;
  cashDrop: string | number | null;
  cashToSafe: string | number | null;
  cassetteDeposit: string | number | null;
  changeIn: string | number | null;
  changeOrder: string | number;
  changeOut: string | number | null;
  checksAdded: string | number | null;
  checksWritten: string | number | null;
  cigPurchases: string | number | null;
  cigReturns: string | number | null;
  cigReturnsNotes: string | null;
  cigaretteCoupons: string | number | null;
  cigaretteDiscount: string | number | null;
  cigaretteDiscountOverring: string | number | null;
  cigaretteOr: string | number | null;
  cigaretteOverrings: string | number | null;
  cigarillo: string | number | null;
  columnTotal: string | number | null;
  comments: string | null;
  creditCardSlipCount: string | number | null;
  creditCardSlips: string | number | null;
  decreaseBanks: string | number | null;
  depositBagA: string | null;
  depositBagB: string | null;
  depositBagC: string | null;
  depositBagD: string | null;
  discrepencyComments: string | null;
  discrepencyOver: string | number;
  discrepencyShort: string | number;
  ecarton: string | number | null;
  employee: string | null;
  employeeMeals: string | number | null;
  employeeSignoff: string | number | null;
  endingBluInventory: string | number | null;
  endingCash: string | number | null;
  endingChange: string | number | null;
  endingCheckCount: string | number | null;
  endingPokerBank: string | number | null;
  endingTill: string | number | null;
  endingciginventory: string | number | null;
  epack: string | number | null;
  espressoDiscount: string | number | null;
  firstName: string | null;
  food: string | number | null;
  foodDiscount: string | number | null;
  fundsNeeded: string | number | null;
  gcarton: string | number | null;
  giftIn: string | number;
  giftOut: string | number;
  gpack: string | number | null;
  guestRewardPayout: string | number;
  guestRewardPayoutComments: string | null;
  iDmailed: string | number | null;
  idComments: string | number | null;
  idWriteUp: string | number | null;
  idrm: string | number | null;
  increaseBanks: string | number | null;
  innerCompanyTransferNotes: string | null;
  innerCompanyTransferRegDiscount: string | number;
  innerCompanyTransferTo: string | number;
  innerCompanyTransferValue: string | number;
  innercompanytransfers: string | number | null;
  innercompanyxfer1: string | number | null;
  innercompanyxfer2: string | number | null;
  innercompanyxfer3: string | number | null;
  innercompanyxferto1: string | number | null;
  innercompanyxferto2: string | number | null;
  innercompanyxferto3: string | number | null;
  jamBillComments: string | null;
  jamBillsIn: string | number | null;
  jamBillsOut: string | number | null;
  lastName: string | null;
  lotteryCoupons: string | number | null;
  managerSignoff: string | number | null;
  mcarton: string | number | null;
  midShiftPull: string | number | null;
  mpack: string | number | null;
  nonAlcohol: string | number | null;
  nonPrizeChecks: string | number | null;
  numPayoutSlips: string | number;
  onLinePrizes: string | number | null;
  onLineSales: string | number | null;
  other: string | number | null;
  otherPayout: string | number | null;
  othercomments: string | null;
  overShort: string | number | null;
  overrings: string | number | null;
  payouts: string | number | null;
  payoutsComments: string | null;
  pcarton: string | number | null;
  period: string | number | null;
  ppack: string | number | null;
  promotion: string | number | null;
  pullTabPrizes: string | number | null;
  regularShiftTubeRefill: string | number | null;
  rocketBucks: string | number;
  rocketBucksComments: string | null;
  salesTax: string | number | null;
  scratchOffValidation: string | number | null;
  scratchTickets: string | number | null;
  shift: string | number;
  shiftDate: string;
  snusPack: string | number | null;
  storeId: string | number;
  tobaccoBlu: string | number | null;
  tobaccoCarton: string | number | null;
  tobaccoPack: string | number | null;
  totalDeposit: string | number | null;
  totalIn: string | number | null;
  totalOut: string | number | null;
  totalVpIn: string | number | null;
  transfercigs: string | number | null;
  validator: string | number | null;
  vlmCashIn: string | number | null;
  vpPaid: string | number;
  vpSales: string | number | null;
  vpValidation: string | number | null;
  winnerChecks: string | number | null;
}

export function shiftMasterFromDraft(draft: ShiftMasterDraft): ShiftMaster {
  return {
    ...draft,
    alcoholBeverages:
      draft.alcoholBeverages === null ? null : +draft.alcoholBeverages,
    atmAmount: draft.atmAmount === null ? null : +draft.atmAmount,
    atmCount: draft.atmCount === null ? null : +draft.atmCount,
    atmRefill: draft.atmRefill === null ? null : +draft.atmRefill,
    auditShift: draft.auditShift === null ? null : +draft.auditShift,
    auditSignoff: draft.auditSignoff === null ? null : +draft.auditSignoff,
    beginningBluInventory:
      draft.beginningBluInventory === null
        ? null
        : +draft.beginningBluInventory,
    beginningCash: draft.beginningCash === null ? null : +draft.beginningCash,
    beginningChange:
      draft.beginningChange === null ? null : +draft.beginningChange,
    beginningCheckCount:
      draft.beginningCheckCount === null ? null : +draft.beginningCheckCount,
    beginningPokerBank:
      draft.beginningPokerBank === null ? null : +draft.beginningPokerBank,
    beginningTill: draft.beginningTill === null ? null : +draft.beginningTill,
    beginningciginventory:
      draft.beginningciginventory === null
        ? null
        : +draft.beginningciginventory,
    bluPurchases: draft.bluPurchases === null ? null : +draft.bluPurchases,
    bluReturns: draft.bluReturns === null ? null : +draft.bluReturns,
    blupack: draft.blupack === null ? null : +draft.blupack,
    cashDeposit: draft.cashDeposit === null ? null : +draft.cashDeposit,
    cashDepositB: draft.cashDepositB === null ? null : +draft.cashDepositB,
    cashDepositC: draft.cashDepositC === null ? null : +draft.cashDepositC,
    cashDepositD: draft.cashDepositD === null ? null : +draft.cashDepositD,
    cashDrop: draft.cashDrop === null ? null : +draft.cashDrop,
    cashToSafe: draft.cashToSafe === null ? null : +draft.cashToSafe,
    cassetteDeposit:
      draft.cassetteDeposit === null ? null : +draft.cassetteDeposit,
    changeIn: draft.changeIn === null ? null : +draft.changeIn,
    changeOrder: +draft.changeOrder,
    changeOut: draft.changeOut === null ? null : +draft.changeOut,
    checksAdded: draft.checksAdded === null ? null : +draft.checksAdded,
    checksWritten: draft.checksWritten === null ? null : +draft.checksWritten,
    cigPurchases: draft.cigPurchases === null ? null : +draft.cigPurchases,
    cigReturns: draft.cigReturns === null ? null : +draft.cigReturns,
    cigaretteCoupons:
      draft.cigaretteCoupons === null ? null : +draft.cigaretteCoupons,
    cigaretteDiscount:
      draft.cigaretteDiscount === null ? null : +draft.cigaretteDiscount,
    cigaretteDiscountOverring:
      draft.cigaretteDiscountOverring === null
        ? null
        : +draft.cigaretteDiscountOverring,
    cigaretteOr: draft.cigaretteOr === null ? null : +draft.cigaretteOr,
    cigaretteOverrings:
      draft.cigaretteOverrings === null ? null : +draft.cigaretteOverrings,
    cigarillo: draft.cigarillo === null ? null : +draft.cigarillo,
    columnTotal: draft.columnTotal === null ? null : +draft.columnTotal,
    creditCardSlipCount:
      draft.creditCardSlipCount === null ? null : +draft.creditCardSlipCount,
    creditCardSlips:
      draft.creditCardSlips === null ? null : +draft.creditCardSlips,
    decreaseBanks: draft.decreaseBanks === null ? null : +draft.decreaseBanks,
    discrepencyOver: +draft.discrepencyOver,
    discrepencyShort: +draft.discrepencyShort,
    ecarton: draft.ecarton === null ? null : +draft.ecarton,
    employeeMeals: draft.employeeMeals === null ? null : +draft.employeeMeals,
    employeeSignoff:
      draft.employeeSignoff === null ? null : +draft.employeeSignoff,
    endingBluInventory:
      draft.endingBluInventory === null ? null : +draft.endingBluInventory,
    endingCash: draft.endingCash === null ? null : +draft.endingCash,
    endingChange: draft.endingChange === null ? null : +draft.endingChange,
    endingCheckCount:
      draft.endingCheckCount === null ? null : +draft.endingCheckCount,
    endingPokerBank:
      draft.endingPokerBank === null ? null : +draft.endingPokerBank,
    endingTill: draft.endingTill === null ? null : +draft.endingTill,
    endingciginventory:
      draft.endingciginventory === null ? null : +draft.endingciginventory,
    epack: draft.epack === null ? null : +draft.epack,
    espressoDiscount:
      draft.espressoDiscount === null ? null : +draft.espressoDiscount,
    food: draft.food === null ? null : +draft.food,
    foodDiscount: draft.foodDiscount === null ? null : +draft.foodDiscount,
    fundsNeeded: draft.fundsNeeded === null ? null : +draft.fundsNeeded,
    gcarton: draft.gcarton === null ? null : +draft.gcarton,
    giftIn: +draft.giftIn,
    giftOut: +draft.giftOut,
    gpack: draft.gpack === null ? null : +draft.gpack,
    guestRewardPayout: +draft.guestRewardPayout,
    iDmailed: draft.iDmailed === null ? null : +draft.iDmailed,
    idComments: draft.idComments === null ? null : +draft.idComments,
    idWriteUp: draft.idWriteUp === null ? null : +draft.idWriteUp,
    idrm: draft.idrm === null ? null : +draft.idrm,
    increaseBanks: draft.increaseBanks === null ? null : +draft.increaseBanks,
    innerCompanyTransferRegDiscount: +draft.innerCompanyTransferRegDiscount,
    innerCompanyTransferTo: +draft.innerCompanyTransferTo,
    innerCompanyTransferValue: +draft.innerCompanyTransferValue,
    innercompanytransfers:
      draft.innercompanytransfers === null
        ? null
        : +draft.innercompanytransfers,
    innercompanyxfer1:
      draft.innercompanyxfer1 === null ? null : +draft.innercompanyxfer1,
    innercompanyxfer2:
      draft.innercompanyxfer2 === null ? null : +draft.innercompanyxfer2,
    innercompanyxfer3:
      draft.innercompanyxfer3 === null ? null : +draft.innercompanyxfer3,
    innercompanyxferto1:
      draft.innercompanyxferto1 === null ? null : +draft.innercompanyxferto1,
    innercompanyxferto2:
      draft.innercompanyxferto2 === null ? null : +draft.innercompanyxferto2,
    innercompanyxferto3:
      draft.innercompanyxferto3 === null ? null : +draft.innercompanyxferto3,
    jamBillsIn: draft.jamBillsIn === null ? null : +draft.jamBillsIn,
    jamBillsOut: draft.jamBillsOut === null ? null : +draft.jamBillsOut,
    lotteryCoupons:
      draft.lotteryCoupons === null ? null : +draft.lotteryCoupons,
    managerSignoff:
      draft.managerSignoff === null ? null : +draft.managerSignoff,
    mcarton: draft.mcarton === null ? null : +draft.mcarton,
    midShiftPull: draft.midShiftPull === null ? null : +draft.midShiftPull,
    mpack: draft.mpack === null ? null : +draft.mpack,
    nonAlcohol: draft.nonAlcohol === null ? null : +draft.nonAlcohol,
    nonPrizeChecks:
      draft.nonPrizeChecks === null ? null : +draft.nonPrizeChecks,
    numPayoutSlips: +draft.numPayoutSlips,
    onLinePrizes: draft.onLinePrizes === null ? null : +draft.onLinePrizes,
    onLineSales: draft.onLineSales === null ? null : +draft.onLineSales,
    other: draft.other === null ? null : +draft.other,
    otherPayout: draft.otherPayout === null ? null : +draft.otherPayout,
    overShort: draft.overShort === null ? null : +draft.overShort,
    overrings: draft.overrings === null ? null : +draft.overrings,
    payouts: draft.payouts === null ? null : +draft.payouts,
    pcarton: draft.pcarton === null ? null : +draft.pcarton,
    period: draft.period === null ? null : +draft.period,
    ppack: draft.ppack === null ? null : +draft.ppack,
    promotion: draft.promotion === null ? null : +draft.promotion,
    pullTabPrizes: draft.pullTabPrizes === null ? null : +draft.pullTabPrizes,
    regularShiftTubeRefill:
      draft.regularShiftTubeRefill === null
        ? null
        : +draft.regularShiftTubeRefill,
    rocketBucks: +draft.rocketBucks,
    salesTax: draft.salesTax === null ? null : +draft.salesTax,
    scratchOffValidation:
      draft.scratchOffValidation === null ? null : +draft.scratchOffValidation,
    scratchTickets:
      draft.scratchTickets === null ? null : +draft.scratchTickets,
    shift: +draft.shift,
    snusPack: draft.snusPack === null ? null : +draft.snusPack,
    storeId: +draft.storeId,
    tobaccoBlu: draft.tobaccoBlu === null ? null : +draft.tobaccoBlu,
    tobaccoCarton: draft.tobaccoCarton === null ? null : +draft.tobaccoCarton,
    tobaccoPack: draft.tobaccoPack === null ? null : +draft.tobaccoPack,
    totalDeposit: draft.totalDeposit === null ? null : +draft.totalDeposit,
    totalIn: draft.totalIn === null ? null : +draft.totalIn,
    totalOut: draft.totalOut === null ? null : +draft.totalOut,
    totalVpIn: draft.totalVpIn === null ? null : +draft.totalVpIn,
    transfercigs: draft.transfercigs === null ? null : +draft.transfercigs,
    validator: draft.validator === null ? null : +draft.validator,
    vlmCashIn: draft.vlmCashIn === null ? null : +draft.vlmCashIn,
    vpPaid: +draft.vpPaid,
    vpSales: draft.vpSales === null ? null : +draft.vpSales,
    vpValidation: draft.vpValidation === null ? null : +draft.vpValidation,
    winnerChecks: draft.winnerChecks === null ? null : +draft.winnerChecks,
  };
}
