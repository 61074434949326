const env = process.env;

export const LOGIN_DEFAULT_EMPLOYEE_ID =
  env.REACT_APP_LOGIN_DEFAULT_EMPLOYEE_ID || '';
export const LOGIN_DEFAULT_PASSWORD =
  env.REACT_APP_LOGIN_DEFAULT_PASSWORD || '';
export const LOGIN_DEFAULT_STORE_ID =
  env.REACT_APP_LOGIN_DEFAULT_STORE_ID || '';

export default env;
