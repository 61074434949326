import React, { FC, ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components/macro';
import Row from './Row';

interface Props extends ComponentPropsWithoutRef<'div'> {
  amount: string | number;
}

const MoneyText: FC<Props> = props => {
  const { amount, ...rest } = props;
  return (
    <Row {...rest}>
      <span>$</span>
      {(+amount).toFixed(2)}
    </Row>
  );
};

export default styled(MoneyText)`
  align-items: center;
  background-color: #ddd;
  padding-right: 5px;
  margin-left: 10px;

  span {
    width: 20px;
    text-align: center;
    font-size: 20px;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
