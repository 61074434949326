import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Form from './Form';
import InputRow from './InputRow';
import BlockButton from './BlockButton';
import FormBox from './FormBox';
import Input from './Input';
import today from '../utils/today';

interface ByBrandFormProps {
  onSubmit?: (inventoryDate: Date) => any;
}

const ByBrandForm: React.FC<ByBrandFormProps> = props => {
  const { onSubmit, ...rest } = props;
  const [inventoryDate, setInventoryDate] = useState(() =>
    today().slice(0, 10),
  );
  const title = 'ByBrand: Form';

  function handleSubmit() {
    if (onSubmit) {
      onSubmit(new Date(inventoryDate + 'T00:00:00.000Z'));
    }
  }

  return (
    <FormBox {...rest} title={title}>
      <Form onSubmit={handleSubmit} data-testid="form">
        <InputRow>
          <label>Inventory Date:</label>
          <Input
            type="date"
            value={inventoryDate}
            onValueChange={setInventoryDate}
            data-testid="inventoryDateInput"
          />
        </InputRow>
        <BlockButton>
          By Brand
          <br />
          Inventory Form
        </BlockButton>
      </Form>
    </FormBox>
  );
};

export default styled(ByBrandForm)`
  width: 350px;
  margin: auto;

  ${BlockButton} {
    max-width: 170px;
    min-width: unset;
    margin: 20px 0 30px;
    align-self: flex-start;
  }

  ${InputRow} {
    justify-content: flex-start;
    margin-top: 20px;
  }

  label {
    margin-right: 20px;
  }
`;
