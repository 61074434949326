import React from 'react';
import styled from 'styled-components/macro';
import NumberInput from './NumberInput';
import Row from './Row';

export interface MoneyInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: number) => any;
  'data-testid'?: string; // Pass test id to the input rather than the wrapper element
  backgroundColor?: string | undefined;
}

const MoneyInput: React.FC<MoneyInputProps> = props => {
  const {
    value,
    onChange,
    onValueChange,
    placeholder,
    readOnly,
    disabled,
    className,
    style,
    backgroundColor,
    ...rest
  } = props;

  return (
    <Root
      {...rest}
      className={className}
      style={{ backgroundColor, ...style }}
      disabled={disabled || readOnly}
      data-testid={undefined}
    >
      <NumberInput
        value={value}
        onChange={onChange}
        onValueChange={onValueChange}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        style={{
          backgroundColor,
          borderColor: backgroundColor,
        }}
        step="0.01"
        min="0.0"
        data-testid={props['data-testid']}
      />
      <span>$</span>
    </Root>
  );
};

export default styled(MoneyInput)``;

const Root = styled(Row)<{ disabled?: boolean }>`
  align-items: center;
  height: 40px;
  background-color: ${props => (props.disabled ? '#ddd' : 'white')};
  border-top: 2px solid #777;
  border-left: 2px solid #777;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;

  span {
    order: 1;
    width: 20px;
    text-align: center;
    font-size: 20px;
    flex-shrink: 0;
    color: rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ${NumberInput} {
    order: 2;
    padding-left: 0;
    /* 
    * Allows the top and bottom borders to "merge" 
    * to make the transition between the span and this input seamless 
    */
    height: inherit;
    flex: 1;
    max-width: calc(100% - 20px);
    background-color: inherit;
    border-left: none;
    border-right: none;
  }

  input:focus + span {
    color: black;
  }
`;
