import { AxiosInstance } from 'axios';
import CanAuditOldShifts from '../models/CanAuditOldShifts';

export async function getOldAuditRows(
  axios: AxiosInstance,
): Promise<CanAuditOldShifts[]> {
  const response = await axios.get('/auditable');
  return response.data;
}

export async function createOldAuditRow(
  axios: AxiosInstance,
  row: CanAuditOldShifts,
): Promise<CanAuditOldShifts> {
  const response = await axios.post('/auditable', row);
  return response.data;
}

export async function updateOldAuditRow(
  axios: AxiosInstance,
  auditableId: number,
  row: CanAuditOldShifts,
): Promise<void> {
  await axios.put(`/auditable/${auditableId}`, row);
}

export async function deleteOldAuditRow(
  axios: AxiosInstance,
  auditableId: number,
): Promise<void> {
  await axios.delete(`/auditable/${auditableId}`);
}
