import User from '../models/User';
import { AxiosInstance } from 'axios';

export async function login(
  axios: AxiosInstance,
  props: {
    storeId: number;
    employeeId: number;
    password: string;
  },
): Promise<User | null> {
  const { employeeId, password } = props;
  return await axios.post(`/store/${props.storeId}/login`, {
    employeeId,
    password,
  });
}

export async function getUser(axios: AxiosInstance): Promise<User | null> {
  const { data } = await axios.get('/user');
  return data;
}

export async function createUser(
  axios: AxiosInstance,
  user: User,
): Promise<void> {
  await axios.post('/user', user);
}

export async function logout(axios: AxiosInstance): Promise<void> {
  await axios.post('/logout');
}
