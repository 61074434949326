import React from 'react';
import styled from 'styled-components/macro';
import { navigate } from '@reach/router';
import Page, { PageComponent } from '../components/Page';
import CheckForErrorsForm from '../components/CheckForErrorsForm';
import withAuth from '../components/withAuth';

const AuditErrorCheck: PageComponent = props => {
  const routeTo = (route: string) => () => navigate(route);

  return (
    <Page {...props}>
      <CheckForErrorsForm
        onRunClick={routeTo('/error-check')}
        onPrintClick={routeTo('/error-check/print')}
        onReturnClick={routeTo('/admin')}
      />
    </Page>
  );
};

export default styled(withAuth(AuditErrorCheck))`
  justify-content: center;
  align-items: center;
`;
