import { navigate } from '@reach/router';
import React from 'react';
import styled from 'styled-components/macro';
import { PageComponent } from '../components/Page';
import TotalDollarsPlayedForm from '../components/TotalDollarsPlayedForm';
import withAuth from '../components/withAuth';

const TotalDollarsPlayedPage: PageComponent = props => {
  return <TotalDollarsPlayedForm {...props} onClose={() => navigate('/')} />;
};

export default styled(withAuth(TotalDollarsPlayedPage))``;
