import { css } from 'styled-components/macro';
import Omit from './Omit';

export interface HideableProps {
  hide?: boolean;
  takeSpaceWhenHidden?: boolean;
}

/**
 * Hide via 'display: none'
 * @param hide
 */
export function hideByDisplayNone({
  hide,
}: Omit<HideableProps, 'takeSpaceWhenHidden'>) {
  return hide
    ? css`
        display: none;
      `
    : css``;
}

/**
 * Hide via 'opacity: 0' and disabling user interaction.
 * @param hide
 */
export function hideByInvisible({
  hide,
}: Omit<HideableProps, 'takeSpaceWhenHidden'>) {
  return hide
    ? css`
        opacity: 0;
        pointer-events: none;
        user-select: none;
      `
    : css``;
}

export function hideable({ hide, takeSpaceWhenHidden }: HideableProps) {
  return takeSpaceWhenHidden
    ? hideByInvisible({ hide })
    : hideByDisplayNone({ hide });
}
