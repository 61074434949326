import React, { FC, useCallback } from 'react';
import styled from 'styled-components/macro';
import noop from '../utils/noop';
import BlockButton from './BlockButton';
import NumberInput from './NumberInput';
import Row from './Row';

/**
 * The method to use to increment/decrement the pages.
 * 'right-increase' causes the page to move "forward" when the right side buttons are clicked, left is -1, right is +1
 * 'left-increase' causes the page to move "forward" when the left side buttons are clicked, left is +1, right is -1
 */
type PaginationStrategy = 'right-increase' | 'left-increase';

interface Props {
  page: number;
  pageCount: number;
  onChange?: (newPage: number) => any;
  paginationStrategy?: PaginationStrategy;
}

const PaginationRow: FC<Props> = props => {
  const {
    page,
    pageCount,
    onChange = noop,
    paginationStrategy = 'right-increase',
    ...rest
  } = props;

  const handleChange = useCallback(
    (pageIndex: number) => {
      pageIndex = pageIndex || 0;
      pageIndex = Math.min(pageCount - 1, pageIndex);
      pageIndex = Math.max(0, pageIndex);
      onChange(pageIndex);
    },
    [onChange, pageCount],
  );

  const handleStartOfLeftClicked = useCallback(() => {
    const index = paginationStrategy === 'right-increase' ? 0 : pageCount - 1;
    handleChange(index);
  }, [handleChange, paginationStrategy, pageCount]);

  const handleLeftClicked = useCallback(() => {
    const indexModifier = paginationStrategy === 'right-increase' ? -1 : 1;
    handleChange(page + indexModifier);
  }, [handleChange, paginationStrategy, page]);

  const handleRightClicked = useCallback(() => {
    const indexModifier = paginationStrategy === 'right-increase' ? 1 : -1;
    handleChange(page + indexModifier);
  }, [handleChange, paginationStrategy, page]);

  const handleStartOfRightClicked = useCallback(() => {
    const index = paginationStrategy === 'right-increase' ? pageCount - 1 : 0;
    handleChange(index);
  }, [handleChange, paginationStrategy, pageCount]);

  return (
    <Row {...rest}>
      <Label>Record:</Label>
      <BlockButton tabIndex={-1} onClick={handleStartOfLeftClicked}>
        {'|<'}
      </BlockButton>
      <BlockButton tabIndex={-1} onClick={handleLeftClicked}>
        {'<'}
      </BlockButton>
      <NumberInput
        value={page + 1}
        onChange={event =>
          handleChange(parseInt(event.currentTarget.value, 10) - 1)
        }
        min={0}
        max={pageCount}
        tabIndex={-1}
      />
      <BlockButton tabIndex={-1} onClick={handleRightClicked}>
        {'>'}
      </BlockButton>
      <BlockButton tabIndex={-1} onClick={handleStartOfRightClicked}>
        {'>|'}
      </BlockButton>
      <RecordCountLabel>of {pageCount} (Filtered)</RecordCountLabel>
    </Row>
  );
};

export default styled(PaginationRow)`
  align-items: center;

  > ${BlockButton} {
    width: 50px;
    padding: 0;
    min-width: 0;
  }
`;

const Label = styled('div')`
  margin-right: 5px;
`;

const RecordCountLabel = styled('div')`
  margin-left: 5px;
`;
